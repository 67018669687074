import { countryCodeandLanguage } from '../data/countryLanguageMap';

const defaultLanguageCode = 'en';

export const getLanguageCodeByCountry = (countryCode) => {
  if (!countryCode) {
    return defaultLanguageCode;
  }
  const mapping = countryCodeandLanguage.find(
    (item) =>
      item?.code?.toUpperCase() === countryCode?.toUpperCase(),
  );
  return mapping ? mapping.language : defaultLanguageCode;
};

export const constructOpenPathPrefix = (
  onboardingURL,
  langCode,
  address,
  providerId,
  userCountry,
) => {
  const countryCode = address?.countryCode ?? userCountry;
  return `${onboardingURL}lang=${langCode}-${countryCode}&clinicid=${providerId}`;
};
