const excludedAccessoryStatuses = ['lost/stolen', 'return received'];
const excludedImplantStatuses = ['inactive', 'explanted'];
const excludedProcessorStatuses = ['inactive'];

export const filterPatientDevices = (deviceList) => {
  return deviceList?.devices?.filter((item) => {
    const productType = item?.productType?.toLowerCase();
    const status = item?.status?.toLowerCase();

    return !(
      (productType === 'accessory' &&
        excludedAccessoryStatuses.includes(status)) ||
      (productType === 'implant' &&
        excludedImplantStatuses.includes(status)) ||
      (productType === 'sound processor' &&
        excludedProcessorStatuses.includes(status))
    );
  });
};
/* Remove this later */
