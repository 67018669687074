import React from 'react';
import { Text } from '@cochlear-design-system/foundation';
import { formatDate } from '../../../utils/dateTimeFns';

const renderUnderAge = (message) => {
  return (
    <div className="mt-3">
      <p className="ccl__text">{message}</p>
    </div>
  );
};

const renderDeceased = (message) => {
  return (
    <div className="mt-3">
      <p className="ccl__text">{message}</p>
    </div>
  );
};

export const DetailsViewPersonal = ({ values, labels }) => {
  const getName = (firstName, middleName, lastName) => {
    let name = firstName || '--';
    if (middleName?.length > 0) {
      name = `${name} ${middleName}`;
    }
    return `${name} ${lastName || '--'}`;
  };

  const getDob = (dateOfBirth, day, month, year) => {
    if (dateOfBirth?.length > 0 || (day && month && year)) {
      return formatDate(`${year}-${month}-${day}`);
    }
    return '--';
  };

  const name = getName(
    values?.firstName,
    values?.middleName,
    values?.lastName,
  );
  const dob = getDob(
    values?.dateOfBirth,
    values?.day,
    values?.month,
    values?.year,
  );

  const renderData = () => {
    return (
      <>
        <div className="mt-3 ccl__flex">
          <Text
            content={labels['labels.common.name']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={name} />
        </div>
        <div className="mt-2 ccl__flex">
          <Text
            content={labels['labels.common.dateOfBirth']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={dob} />
        </div>
      </>
    );
  };

  const render = () => {
    if (values?.isDeceased) {
      return renderDeceased(labels['labels.editPatient.deceased']);
    }
    return renderData();
  };

  return render();
};

export const DetailsViewContact = ({ values, labels }) => {
  const mobile = values?.mobile || '--';
  const phone = values?.phone || '--';
  const email = values?.email || '--';

  const renderData = () => {
    return (
      <>
        <div className="mt-3 ccl__flex">
          <Text
            content={labels['labels.common.mobile']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={mobile} />
        </div>
        <div className="mt-2 ccl__flex">
          <Text
            content={labels['labels.common.phone']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={phone} />
        </div>
        <div className="mt-2 ccl__flex">
          <Text
            content={labels['labels.common.contactEmail']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={email} />
        </div>
      </>
    );
  };

  const render = () => {
    if (values?.isDeceased) {
      return renderDeceased(labels['labels.editPatient.deceased']);
    }
    if (values?.isUnderAge) {
      return renderUnderAge(
        labels['labels.editPatient.addressDetails.underage'],
      );
    }
    return renderData();
  };

  return render();
};

export const DetailsViewAddress = ({ values, labels }) => {
  const addressFormatted = (address) => {
    if (address) {
      return (
        [
          address.street,
          address.city,
          address.state,
          address.postcode,
          address.country,
        ]
          .filter(Boolean)
          .join(', ') || '--'
      );
    }
    return '--';
  };

  const renderData = () => {
    return (
      <>
        <div className="mt-3 ccl__flex">
          <Text
            content={
              labels['labels.editPatient.addressDetails.shipping']
            }
            type="body-text-bold"
            className="label-spacing"
          />
        </div>
        <div className="mt-2 ccl__flex ccl_address_list_item">
          <Text
            content={addressFormatted(values?.address)}
            type="body-text"
            className="label-spacing"
          />
        </div>
        <div className="mt-3 ccl__flex">
          <Text
            content={
              labels['labels.editPatient.addressDetails.billing']
            }
            type="body-text-bold"
            className="label-spacing"
          />
        </div>
        <div className="mt-2 ccl__flex ccl_address_list_item">
          <Text
            content={addressFormatted(values?.billingAddress)}
            type="body-text"
            className="label-spacing"
          />
        </div>
      </>
    );
  };

  const render = () => {
    if (values?.isDeceased) {
      return renderDeceased(labels['labels.editPatient.deceased']);
    }
    if (values?.isUnderAge) {
      return renderUnderAge(
        labels['labels.editPatient.addressDetails.underage'],
      );
    }
    return renderData();
  };

  return render();
};
