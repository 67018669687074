/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-param-reassign */
import {
  Modal,
  Spinner,
  Text,
  Title,
  TextArea,
  Button,
  Alert,
} from '@cochlear-design-system/foundation';
import { Feature } from 'flagged';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MainPatientWrapper } from '../../ui/MainPatientWrapper/MainPatientWrapper.js';
import { Error as ErrorComponent } from '../../ui/Error/Error.js';
import { getFindClinicModalConfig } from '../../cdsConfigs/findClinicModalConfig';
import error from '../../actions/errorAction';
import { routes } from '../../config';
import cookieService from '../../services/cookieService';
import featureFlagService from '../../services/featureFlagService';
import patientService from '../../services/patientService';
import providerService from '../../services/providerService';
import { setIdpParam } from '../../utils/authprovider';
import { getAge } from '../../utils/dateTimeFns';
import { getSelectedRecipientCId } from '../../utils/mcpcid';
import getQueryString from '../../utils/queryStrings';
import { replaceTokens } from '../../utils/stringFns';
import AccountInviteModal from '../AccountInviteModal';
import UpdateAccessoryModal from '../UpdateAccessoryModal';
import createProfessionalCasesService from '../../services/createCases/createProfessionalCasesService.js';
import DisabledFeature from '../DisabledFeature';
import {
  errorModeConfig,
  formatExpandedData,
  formatPatientCarersData,
  formatPatientClinicData,
  formatPatientDeviceData,
  genericEmptyListElementsConfig,
  genericErrorElementsConfig,
  getGenericEmptyListData,
  getGenericErrorData,
  getCarerReadElementsConfig,
  panelConfigMapper,
  patientData,
  getDeviceRegInProgressLabel,
} from './dataMapper';
import {
  getConfig,
  getPatientDeceasedConfig,
  getSummaryConfig,
} from './patientRecordConfig';
import {
  attachRemoteCheckMessageListener,
  insertRemoteCheckScript,
  setupRemoteCheck,
} from './remoteCheck';
import { getLabelsForFields } from '../../utils/labelHelper';
import { FindClinicModal } from '../../ui/FindClinicModal/FindClinicModal';
import { getMergedAddress } from '../../utils/addressHelper';

let equipmentDataBackup = {};
let currentEquipmentData = {};

const getUpdatedPanelConfig = (existingConfig, viewId, cb) => {
  const updatedConfig = { ...existingConfig };
  const viewIndex = updatedConfig.views.findIndex(
    ({ id }) => id === viewId,
  );

  updatedConfig.views[viewIndex] = cb(updatedConfig.views[viewIndex]);

  return updatedConfig;
};

const injectViewErrorConfig = (view) => {
  const updatedView = {
    ...view,
    isLoading: false,
  };

  updatedView.read.fieldLayout = 'horizontal';
  updatedView.read.elements = genericErrorElementsConfig;

  return updatedView;
};

const PatientRecord = ({ rendering, country, lang }) => {
  const labels = getLabelsForFields(rendering.fields);
  const history = useHistory();
  const config = getConfig();

  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notificationStatus.notifications,
  );
  const uDetails = useSelector((state) => state.userDetails);
  const { providerId } = providerService.getProviderDetails();
  const { isDistributor, name: providerName } =
    providerService.getCurrentProvider();
  const patientId = getQueryString('patientId');

  const location = useLocation();
  const locationState = useState(location.state)[0];
  const isDeceasedState = locationState?.isDeceased || false;

  const [patientDetails, setPatientDetails] = useState({});
  const [rawPatientDetails, setRawPatientDetails] = useState({});
  const [carersData, setCarersData] = useState();
  const [rawCarersDetails, setRawCarersDetails] = useState();
  const [associatedClinicData, setAssociatedClinicData] = useState(
    {},
  );
  const [equipmentData, setEquipmentData] = useState({});
  const [rcIframeKey, setRcIframeKey] = useState(0);
  const [showUpdateAccessoryModal, setShowUpdateAccessoryModal] =
    useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState();

  const handleEditAccount = (carerId = null) => {
    history.push({
      pathname: `/${country}/${lang}/${routes.dpx.editPatient}`,
      search: `?patientId=${patientId}${
        carerId ? `&carerId=${carerId}` : ''
      }`,
    });
  };

  const disableIframes =
    localStorage.getItem('disableIframes') === 'true';

  // associating clinic
  const [
    showSelectSurgeryCentreModal,
    setShowSelectSurgeryCentreModal,
  ] = useState(false);

  const userCountry = country.toUpperCase();

  const [selectedClinic, setSelectedClinic] = useState(undefined);

  const handleCreateCarer = () => {
    history.push({
      pathname: `/${country}/${lang}/${routes.dpx.addCarer}`,
      search: `?patientId=${patientId}`,
    });
  };

  const handleAddAssociatedClinic = () => {
    setShowSelectSurgeryCentreModal(true);
  };

  const handleSearchClinic = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const clinics = await providerService.searchClinics(
      countryCode,
      searchParam.clinic,
    );
    if (clinics.providers && clinics.providers.length > 0) {
      clinics.providers.forEach((provider) => {
        provider.address = getMergedAddress(provider);
      });
    }
    return clinics.providers;
  };
  const handleSelectClinic = (action, params) => {
    const selectedClinicData = {
      clinicId: params.data.id,
      name: params.data.name,
      address: params.data.address,
      isClinicAssociated: false,
      organisationType: params.data.organisationType,
    };
    const isClinicAssociated = associatedClinicData?.values.findIndex(
      (clinic) =>
        clinic.clinicId.value === selectedClinicData?.clinicId,
    );
    setSelectedClinic({
      ...selectedClinicData,
      isClinicAssociated: isClinicAssociated !== -1,
    });
  };

  const clinicSelectorConfig = getFindClinicModalConfig(
    userCountry,
    labels,
  );
  clinicSelectorConfig.searchButton.onClick = handleSearchClinic;
  clinicSelectorConfig.tableConfig.events.onRowClick =
    handleSelectClinic;

  const [
    showAuthorizationAssociateClinic,
    setShowAuthorizationAssociateClinic,
  ] = useState(false);

  const addSelectedAssociatedClinic = async () => {
    const { clinicId, organisationType } = selectedClinic;
    // organisationType is mapped to role when associating clinic to a patient.
    setSelectedClinic(undefined);
    const { success } = await patientService.associateWithClinic(
      patientId,
      providerId,
      organisationType,
      clinicId,
    );
    const alertType = success ? 'success' : 'error';
    const alertMessage = success
      ? `${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.success']}`
      : `${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.error']}`;
    setHeadingDetails({
      ...headingDetails,
      alertConfig: {
        ...headingDetails.alertConfig,
        type: alertType,
        visible: true,
        title: success
          ? ''
          : `${labels['labels.patientRecord.views.associatedClinicsView.read.errorTitle']}`,
        message: alertMessage,
        onClose: () => {},
      },
    });

    await refreshAssociatedClinicData();
  };

  const [panelConfig, setPanelConfig] = useState({
    ...panelConfigMapper(
      config,
      uDetails,
      handleEditAccount,
      handleCreateCarer,
      handleAddAssociatedClinic,
    ),
    routerLocation: useLocation,
  });
  const [pageLevelError, setPageLevelError] = useState(false);
  const selectedRecipientCId = getSelectedRecipientCId(patientId);
  const [createServiceRequestUrl, setCreateServiceRequestUrl] =
    useState({});
  const partyId = providerService.getCurrentPartyId();
  const [syncedToSF, setSyncedToSF] = useState();
  const [registrationTid, setRegistrationTid] = useState();
  const [hasLoginFromMCP, setHasLoginFromMCP] = useState(false);
  const [localeFromMCP, setLocaleFromMCP] = useState();
  const [ageThresholdFromMCP, setAgeThresholdFromMCP] = useState();

  const arrayTokens = [
    {
      key: `##___CLINICPARTYID___##`,
      value: partyId,
    },
    {
      key: `##___RECIPIENTCOCHLEARID___##`,
      value: patientId,
    },
  ];
  const [deviceId, setDeviceId] = useState();
  const title =
    labels[
      'labels.patientRecord.tabs.equipments.dataTable.options.createServiceRequest'
    ];
  const [showCreateSRModal, setShowCreateSRModal] = useState(false);
  const [createSRFrameLoaded, showCreateSRFrameLoaded] =
    useState(false);
  const displayModalCreateSR = (Id) => {
    setDeviceId(Id);
    setShowCreateSRModal(true);
    showCreateSRFrameLoaded(false);
  };
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteData, setInviteData] = useState({
    type: '',
    cochlearId: '',
    email: '',
    preferredLanguage: '',
    countryCode: '',
    resend: false,
  });
  const [inviteSendingInProgress, setInviteSendingInProgress] =
    useState(false);

  const featureFlags = featureFlagService.getFeatureFlags();

  const [showReportIssueModal, setShowReportIssueModal] =
    useState(false);

  // ReportIssue

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const [selectedExpandedData, setSelectedExpandedData] =
    useState(undefined);
  const [professionalCase, setProfessionalCase] = useState(undefined);
  const { firstName, lastName, username, professionalId } = uDetails;
  const initialValues = {
    description: '',
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .max(
        1000,
        labels[
          'labels.patientRecord.tabs.equipments.reportIssueModal.messageLimitError'
        ],
      )
      .required(
        labels[
          'labels.patientRecord.tabs.equipments.reportIssueModal.messageRequiredError'
        ],
      ),
  });

  const getOrganisation = () => {
    if (isDistributor) {
      return {
        distributor: {
          cochlearId: providerId,
        },
      };
    }
    return {
      provider: {
        cochlearId: providerId,
      },
    };
  };

  const initPayloadData = {
    professionalId,
    caseType: 'Customer Interaction',
    subject: 'DPX Contact us',
    webReason: 'Report an implant issue',
    countryCode: uDetails.countryCode,
    organisation: getOrganisation(),
    email: username,
    metadata: {
      modifiedBy: username,
      channel: 'DPX',
    },
  };

  const getEarSide = () => {
    if (selectedDevice?.icon) {
      return selectedDevice.earLeft ? 'Left' : 'Right';
    }
    return 'Unknown';
  };

  const getSerialNo = () => {
    const { model } = selectedDevice ?? {};
    const { info } = model ?? '';
    const [, , serialNo] = info ? info.split(' ') : '';
    return serialNo;
  };

  const getExtraContent = () => {
    return `
  Report an Implant issue
  Patient Details
  CochlearId: ${patientId}
  Name: ${getFullName()}
  Age: ${age}
  Device Details
  Device Type: ${selectedDevice?.device?.type}
  Serial/lot No: ${getSerialNo()}
  Ear side: ${getEarSide()}
  Surgery Date: ${selectedExpandedData?.expandedDataR[0]?.value ?? ''}
  Surgeon: ${selectedExpandedData?.expandedDataL[0]?.value ?? ''}
  Surgery Center: ${
    selectedExpandedData?.expandedDataL[1]?.value ?? ''
  }
  `;
  };

  const createProfessionalCasePayload = (submittedValues) => {
    const { description, ...rest } = submittedValues;
    const { webReason, ...initPayloadDataRest } = initPayloadData;
    return {
      ...rest,
      description: [getExtraContent(), description].join('\n'),
      webReason: 'Device or app support',
      ...initPayloadDataRest,
    };
  };

  const {
    isValid,
    dirty,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit: async ({ description, ...rest }) => {
      const payload = createProfessionalCasePayload({
        description,
        ...rest,
      });
      const caseResult =
        await createProfessionalCasesService.createProfessionalCases(
          providerId,
          professionalId,
          payload,
        );

      setIsSubmitted(true);
      setProfessionalCase(caseResult);
      resetForm();
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const tryAgain = () => {
    setIsSubmitted(false);
  };

  const loadReportIssueModal = async (rowData) => {
    resetForm();
    setIsSubmitted(false);
    setSelectedExpandedData(undefined);
    setSelectedDevice(rowData);
    setShowReportIssueModal(true);
    await fetchExpandedData(rowData);
  };

  let loadedIds = [];

  const getExpandedData = async (assetId) => {
    const expandedData = await patientService.getDeviceExpandedData(
      providerId,
      patientId,
      assetId,
    );
    const formattedExpandedData = formatExpandedData(
      expandedData,
      labels,
    );
    const copiedData = [...equipmentDataBackup];
    const copiedCurrentData = [...currentEquipmentData];

    const index = copiedData.findIndex(
      (equipment) => equipment.assetId === assetId,
    );

    const currentDataIndex = copiedCurrentData.findIndex(
      (equipment) => equipment.assetId === assetId,
    );

    if (index !== -1) {
      const merged = {
        ...copiedData[index],
        ...formattedExpandedData,
      };
      setSelectedExpandedData(formattedExpandedData);
      if (!loadedIds.includes(assetId)) {
        loadedIds.push(assetId);
      }
      copiedData.splice(index, 1, merged);
    }
    equipmentDataBackup = [...copiedData];

    if (currentDataIndex !== -1) {
      const merged = {
        ...copiedCurrentData[currentDataIndex],
        ...formattedExpandedData,
      };
      copiedCurrentData.splice(currentDataIndex, 1, merged);
    }

    currentEquipmentData = [...copiedCurrentData];

    setEquipmentData(copiedCurrentData);
  };

  const fetchExpandedData = async (rData) => {
    const assetID = rData.assetId;
    if (!loadedIds.includes(assetID)) {
      await getExpandedData(assetID);
    } else {
      const currentExpanded = equipmentDataBackup.filter(
        (equipment) => equipment.assetId === assetID,
      );
      const { expandedDataL, expandedDataR } = currentExpanded[0];
      setSelectedExpandedData({ expandedDataL, expandedDataR });
    }
    return null;
  };

  const [srUrl, setSrUrl] = useState('');
  const [show, setShow] = useState(false);
  const [srLoaded, setSrLoaded] = useState(false);
  const [srListLoaded, setSrListLoaded] = useState(false);
  const [mcpError, setMcpError] = useState(false);

  const selectedSrIframe = useRef();
  const createSrIframe = useRef();

  // eslint-disable-next-line camelcase
  const displayModalViewSR = (serviceRequestUrl) => {
    setSrUrl(serviceRequestUrl);
    setShow(true);
    setSrLoaded(false);
  };

  const hasExistingDevices = () => {
    const personas = rawPatientDetails?.data?.personas ?? null;

    if (
      !personas ||
      personas.includes('Pending') ||
      personas.includes('Candidate')
    ) {
      return false;
    }
    return true;
  };

  const addEquipementCallback = () => {
    if (
      rawPatientDetails &&
      rawPatientDetails.data &&
      rawPatientDetails.data.id
    ) {
      history.push({
        pathname: `/${country}/${lang}/${routes.dpx.deviceRegistration}`,
        state: {
          patientName: `${rawPatientDetails?.data?.firstName} ${rawPatientDetails?.data?.lastName}`,
          dob: rawPatientDetails?.data?.dateOfBirth,
          cochlearId: rawPatientDetails.data.id,
          hasExistingDevice: hasExistingDevices(),
        },
      });
    }
  };

  const filterSoundProcessor = () => {
    resetPreviousExpandedSections();
    currentEquipmentData = equipmentDataBackup.filter(
      (item) => item.device.type === 'Sound Processor',
    );
    setEquipmentData(currentEquipmentData);
  };

  const filterImplants = () => {
    resetPreviousExpandedSections();
    currentEquipmentData = equipmentDataBackup.filter(
      (item) => item.device.type === 'Implant',
    );
    setEquipmentData(currentEquipmentData);
  };

  const filterAccessories = () => {
    resetPreviousExpandedSections();
    currentEquipmentData = equipmentDataBackup.filter(
      (item) =>
        item.device.type === 'Accessory' ||
        item.device.type === 'Remote Assistant',
    );
    setEquipmentData(currentEquipmentData);
  };

  const resetFilters = () => {
    resetPreviousExpandedSections();
    currentEquipmentData = [...equipmentDataBackup];
    setEquipmentData(equipmentDataBackup);
  };

  const resetPreviousExpandedSections = () => {
    Array.from(
      document.getElementsByClassName('ccl__icon--rotate--90'),
    ).forEach((element) => element.click());
  };

  const updateEarSide = async (earSide, assetToUpdateId) => {
    setRhsConfig({ ...rhsConfig, isLoading: true });
    const respose = await patientService.updateEarSide(
      earSide,
      assetToUpdateId,
      professionalId,
      patientId,
      providerId,
    );
    await refreshEquipmentEarSide();
    if (respose) {
      const tempHeading = headingDetails;
      tempHeading.alertConfig = {
        visible: true,
        type: 'success',
        message: `${labels['labels.patientRecord.views.notification.success.earSide']}`,
      };
      setHeadingDetails({
        ...tempHeading,
      });
    } else {
      const tempHeading = headingDetails;
      tempHeading.alertConfig = {
        visible: true,
        type: 'error',
        message: `${labels['labels.patientRecord.views.notification.error.earSide']}`,
      };
      setHeadingDetails({
        ...tempHeading,
      });
    }
  };

  const updateAccessory = async (success) => {
    window.scrollTo(0, 0);
    const tempHeading = headingDetails;
    tempHeading.alertConfig = {
      ...tempHeading.alertConfig,
      dismissible: false,
      visible: true,
    };
    if (success) {
      tempHeading.alertConfig = {
        ...tempHeading.alertConfig,
        type: 'success',
        message:
          labels['labels.patientRecord.updateAccessoryModal.success'],
      };
      await refreshEmptyEquipment();
    } else {
      const failureMessageTemplate =
        labels['labels.patientRecord.updateAccessoryModal.failure'];
      const fullName = `${rawPatientDetails?.data?.firstName} ${rawPatientDetails?.data?.lastName}`;
      const failureMessage = failureMessageTemplate.replace(
        '{{Name}}',
        fullName,
      );
      tempHeading.alertConfig = {
        ...tempHeading.alertConfig,
        type: 'error',
        message: failureMessage,
      };
    }
    setHeadingDetails({
      ...tempHeading,
    });
  };

  const toggleUpdateAccessoryModal = (value, accessoryInfo) => {
    setShowUpdateAccessoryModal(value);
    setSelectedAccessory(accessoryInfo);
  };

  const summaryConfig = getSummaryConfig(
    labels,
    selectedRecipientCId,
    patientId,
    displayModalCreateSR,
    displayModalViewSR,
    fetchExpandedData,
    addEquipementCallback,
    filterSoundProcessor,
    filterAccessories,
    filterImplants,
    resetFilters,
    loadReportIssueModal,
    updateEarSide,
    toggleUpdateAccessoryModal,
  );

  if ('tableConfig' in summaryConfig) {
    // eslint-disable-next-line no-param-reassign
    summaryConfig.tableConfig.handleExpand = fetchExpandedData;

    summaryConfig.tableConfig.tableConfigRight.handleExpand =
      fetchExpandedData;
    summaryConfig.tableConfig.tableConfigLeft.handleExpand =
      fetchExpandedData;
    summaryConfig.tableConfig.tableConfigUnknown.handleExpand =
      fetchExpandedData;
  }

  const [rhsConfig, setRhsConfig] = useState(summaryConfig);

  // patient details API call
  const patientDetailsCall = async (customHeaders) => {
    const rawPatient = await patientService.getPatient(
      providerId,
      patientId,
      customHeaders,
    );
    if (rawPatient === null || rawPatient.data.errors) {
      // dispatch page level error
      dispatch(
        error(
          rawPatient?.status === 404
            ? summaryConfig.notFoundErrorConfig
            : true,
        ),
      );
      setPageLevelError(true);
    }
    return rawPatient;
  };

  const fetchPatientDetails = async (customHeaders) => {
    let rawPatient = await patientDetailsCall(customHeaders);

    if (!rawPatient) {
      return;
    }

    const { data } = rawPatient;

    // patient details could be empty if it's retrieved from cache
    if (
      (!data?.firstName || !(data?.personas ?? null)) &&
      !customHeaders
    ) {
      // first name or personas is empty, so we need to fetch the patient details again from backend
      // don't call it if customHeaders are passed, as it's already fetched from backend
      rawPatient = await patientDetailsCall(cacheControlHeader);
    }

    const formattedPatientDetails = patientData(
      rawPatient.data,
      rawPatient.errors,
      labels,
    );
    setRawPatientDetails(rawPatient);
    setPatientDetails(formattedPatientDetails);

    const updatedPanelConfig = getUpdatedPanelConfig(
      panelConfig,
      'patientDetails',
      (view) => {
        const updatedView = { ...view };

        if (rawPatient.data.isUnderAge) {
          // Hide these fields if patient is under age : Phone, Contact Email, accountDetails
          updatedView.read.elements = view.read.elements.filter(
            (x) =>
              x.id !== 'phone' &&
              x.id !== 'email' &&
              x.id !== 'accountDetails' &&
              x.id !== 'address',
          );
        }

        updatedView.isLoading = false;
        return updatedView;
      },
    );

    setPanelConfig(updatedPanelConfig);
  };

  // patient Carer API call
  const fetchPatientCarers = async (customHeaders) => {
    const rawCarersList = await patientService.getPatientCarers(
      providerId,
      patientId,
      customHeaders,
    );
    setRawCarersDetails(rawCarersList);

    if (rawCarersList === null) {
      setCarersData(
        getGenericErrorData(labels, 'associatedCarersView'),
      );
      setPanelConfig(
        getUpdatedPanelConfig(
          panelConfig,
          'associatedCarersView',
          injectViewErrorConfig,
        ),
      );
      return;
    }

    const isEmpty = !rawCarersList.length;

    const formattedPatientCarersData = isEmpty
      ? getGenericEmptyListData(labels, 'associatedCarersView')
      : formatPatientCarersData(rawCarersList, labels);

    setCarersData(formattedPatientCarersData);

    const updatedPanelConfig = getUpdatedPanelConfig(
      panelConfig,
      'associatedCarersView',
      (view) => {
        const updatedView = {
          ...view,
          isLoading: false,
        };

        if (isEmpty) {
          updatedView.read.elements = genericEmptyListElementsConfig;
        } else {
          updatedView.read.elements =
            getCarerReadElementsConfig(config);
        }

        return updatedView;
      },
    );

    setPanelConfig(updatedPanelConfig);
  };

  // patient Clinic API call
  const fetchPatientClinics = async (customHeaders) => {
    const rawProvidersList =
      await patientService.getPatientAssociatedClinics(
        providerId,
        patientId,
        customHeaders,
      );

    if (rawProvidersList === null) {
      setAssociatedClinicData(
        getGenericErrorData(labels, 'associatedClinicsView'),
      );
      setPanelConfig(
        getUpdatedPanelConfig(
          panelConfig,
          'associatedClinicsView',
          injectViewErrorConfig,
        ),
      );
      return;
    }

    const isEmpty = !rawProvidersList.length;

    const formattedClinicData = isEmpty
      ? getGenericEmptyListData(labels, 'associatedClinicsView')
      : formatPatientClinicData(rawProvidersList);

    setAssociatedClinicData(formattedClinicData);

    const updatedPanelConfig = getUpdatedPanelConfig(
      panelConfig,
      'associatedClinicsView',
      (view) => {
        const updatedView = {
          ...view,
          isLoading: false,
        };

        if (isEmpty) {
          updatedView.read.elements = genericEmptyListElementsConfig;
        }

        return updatedView;
      },
    );

    setPanelConfig(updatedPanelConfig);
  };

  // patient devices API call
  const fetchEquipment = async (customHeaders) => {
    try {
      const rawEquipmentList = await patientService.getPatientDevices(
        providerId,
        uDetails.professionalId,
        patientId,
        customHeaders,
      );

      const formattedEquipmentData = formatPatientDeviceData(
        rawEquipmentList,
        labels,
      );
      // Check if equipment data is empty
      if (!rawEquipmentList) {
        const srConfig = { ...rhsConfig.serviceRequestConfig };
        srConfig.notApplicable = true;
        setRhsConfig({
          ...rhsConfig,
          isLoading: false,
          hasError: true,
          serviceRequestConfig: srConfig,
        });
      }
      // Check if equipment data is empty
      else if (formattedEquipmentData.data.length === 0) {
        const tempRhsConfig = rhsConfig;
        const srConfig = { ...tempRhsConfig.serviceRequestConfig };
        srConfig.notApplicable = true;
        tempRhsConfig.refreshButton = {
          variant: 'tinted',
          icon: 'refresh',
          onClick: refreshEmptyEquipment,
          analytics: 'refresh_devices_view_patient',
        };
        setRhsConfig({
          ...tempRhsConfig,
          isLoading: false,
          isEmpty: true,
          serviceRequestConfig: srConfig,
        });
      } else {
        equipmentDataBackup = [...formattedEquipmentData.data];
        currentEquipmentData = [...formattedEquipmentData.data];
        setEquipmentData(formattedEquipmentData.data);
        if (formattedEquipmentData.contextMenuMap) {
          const tableConfigTemp = { ...rhsConfig.tableConfig };
          tableConfigTemp.columns[4].format.collapsed.map =
            formattedEquipmentData.contextMenuMap;

          tableConfigTemp.tableConfigRight.columns[4].format.collapsed.map =
            formattedEquipmentData.contextMenuMap;
          tableConfigTemp.tableConfigLeft.columns[4].format.collapsed.map =
            formattedEquipmentData.contextMenuMap;
          tableConfigTemp.tableConfigUnknown.columns[4].format.collapsed.map =
            formattedEquipmentData.contextMenuMap;

          setRhsConfig({
            ...rhsConfig,
            tableConfig: tableConfigTemp,
          });
        }
        // set isLoading to false if API call is successful
        setRhsConfig({
          ...rhsConfig,
          isLoading: false,
          isEmpty: false,
        });
      }
    } catch (e) {
      setRhsConfig({
        ...rhsConfig,
        isLoading: false,
        hasError: true,
      });
    }
  };

  const sendUar = async () => {
    const uar = await patientService.sendUar(
      providerId,
      patientId,
      inviteData.cochlearId,
      inviteData.email,
      inviteData.type,
      inviteData.preferredLanguage,
      inviteData.countryCode,
      inviteData.resend,
    );
    return uar;
  };

  const panelData = {
    adaptiveForms: {
      patientDetailsView: patientDetails,
      carersData,
      associatedClinicData,
    },
    errorModes: errorModeConfig,
  };

  const age = getAge(patientDetails?.dateOfBirth?.value);
  const getDobString = () => {
    let dobString;
    if (age === '' || Number.isNaN(age)) {
      dobString = '';
    } else if (age < 1) {
      dobString = `(0)`;
    } else {
      dobString = `(${age})`;
    }
    return dobString;
  };

  const getFullName = () => {
    return [
      patientDetails?.firstName?.value || '',
      patientDetails?.lastName?.value || '',
    ]
      .join(' ')
      .trim();
  };

  const deviceRegInProgress = cookieService.getCookie(
    'deviceRegInProgress',
  );
  const deviceRegNotificationShown = useMemo(() => {
    return cookieService.getCookie('notificationShown') === 'true';
  }, []);

  const deviceRegInProgressLabel = getDeviceRegInProgressLabel(
    deviceRegInProgress,
  );
  const cacheControlHeader = {
    'cache-control': 'no-cache',
  };

  const refreshPatientDetails = async () => {
    const updatedPanelConfig = getUpdatedPanelConfig(
      panelConfig,
      'patientDetails',
      (view) => {
        const updatedView = { ...view };
        updatedView.isLoading = true;
        return updatedView;
      },
    );

    setPanelConfig(updatedPanelConfig);
    fetchPatientDetails(cacheControlHeader);
  };

  const refreshCarerData = async () => {
    const associatedCarersView = getUpdatedPanelConfig(
      panelConfig,
      'associatedCarersView',
      (view) => {
        const updatedView = {
          ...view,
          isLoading: true,
        };
        return updatedView;
      },
    );

    setPanelConfig(associatedCarersView);

    fetchPatientCarers(cacheControlHeader);
  };

  const refreshPatientData = async () => {
    setRawPatientDetails({});
    setMcpError(false);
    setRcIframeKey((prev) => prev + 1);
    await refreshPatientDetails();

    await refreshCarerData();

    const associatedClinicsView = getUpdatedPanelConfig(
      panelConfig,
      'associatedClinicsView',
      (view) => {
        const updatedView = {
          ...view,
          isLoading: true,
        };
        return updatedView;
      },
    );

    setPanelConfig(associatedClinicsView);

    fetchPatientClinics(cacheControlHeader);
  };

  const refreshAssociatedClinicData = async () => {
    const associatedClinicView = getUpdatedPanelConfig(
      panelConfig,
      'associatedClinicsView',
      (view) => {
        const updatedView = {
          ...view,
          isLoading: true,
        };
        return updatedView;
      },
    );
    setPanelConfig(associatedClinicView);
    fetchPatientClinics(cacheControlHeader);
  };

  const patientTitle = `${getFullName()} ${getDobString()}`;
  const defaultHeadingDetails = {
    title: {
      content: patientTitle,
    },
    alertConfig: {
      visible: deviceRegNotificationShown,
      type: 'success',
      title: '',
      message: `${labels[deviceRegInProgressLabel]}\n${labels['labels.common.alert.deviceRequestSubmitted']}`,
      onClose: () => {
        cookieService.removeCookie('deviceRegInProgress');
      },
    },
    buttonConfig: {
      variant: 'tinted',
      icon: 'refresh',
      onClick: refreshPatientData,
      size: 'small',
      analytics: 'refresh_view_patient',
    },
  };
  const [headingDetails, setHeadingDetails] = useState(
    defaultHeadingDetails,
  );

  const onInviteLinkClicked = (id, type) => {
    let email = '';
    // TODO: not sure, if this is needed or not
    const countryCode = 'AU';
    let resend = false;

    if (type === 'pt') {
      email = patientDetails?.email?.value;
      resend = patientDetails?.accountDetails?.value !== 'Not set up';
    } else {
      const carer = carersData?.values?.find(
        (cr) => cr.accountId.value === id,
      );
      email = carer?.email?.value;
      resend = carer?.accountDetails?.value !== 'Not set up';
    }
    setInviteData({
      ...inviteData,
      email,
      type,
      cochlearId: id,
      preferredLanguage: '',
      countryCode,
      resend,
    });
    setShowInviteModal(true);
    setHeadingDetails({
      ...headingDetails,
      title: {
        ...headingDetails.title,
        content: patientTitle,
      },
      alertConfig: {
        ...headingDetails.alertConfig,
        visible: false,
      },
    });
  };

  const onInviteModalClose = async (send) => {
    if (send) {
      setInviteSendingInProgress(true);
      const success = await sendUar();
      const alertType = success ? 'success' : 'error';
      const alertMessage = success
        ? `${labels['labels.patientRecord.heading.alert.title.invite.success']}`
        : `${labels['labels.patientRecord.heading.alert.title.invite.error']}`;
      setHeadingDetails({
        ...headingDetails,
        alertConfig: {
          ...headingDetails.alertConfig,
          type: alertType,
          visible: true,
          title: '',
          message: alertMessage,
          onClose: () => {},
        },
      });
    }
    setShowInviteModal(false);
    setInviteSendingInProgress(false);

    // if not cancelled and this is a create, refresh data
    if (send && !inviteData.resend) {
      if (inviteData.type === 'pt') {
        // if request was for a patient, refresh patient data
        refreshPatientDetails();
      } else {
        // else refresh carer data
        refreshCarerData();
      }
    }
  };

  useEffect(() => {
    dispatch(error(false));
  }, []);

  useEffect(() => {
    if (selectedClinic) {
      setShowAuthorizationAssociateClinic(true);
    } else {
      setShowAuthorizationAssociateClinic(false);
    }
    if (!showAuthorizationAssociateClinic) {
      const element = document.querySelector('body');
      element.style = {};
    }
  }, [selectedClinic, showAuthorizationAssociateClinic]);

  useEffect(() => {
    if (cookieService.getCookie('notificationShown') === 'true') {
      const handleBackButton = () => {
        window.location.reload();
      };
      window.addEventListener('popstate', handleBackButton);
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      cookieService.setCookie('notificationShown', 'false');
    }, 1000);
  }, []);

  // API calls
  useEffect(() => {
    fetchPatientDetails();
    if (!isDeceasedState) {
      fetchPatientCarers();
      fetchPatientClinics();
      fetchEquipment();
    }
  }, []);

  useEffect(() => {
    if (!document || !patientDetails?.lastName) return true;

    setHeadingDetails({
      ...headingDetails,
      title: {
        ...headingDetails.title,
        content: patientTitle,
      },
    });

    const globalPatientListener = (e) => {
      const targetId = e.target.id;
      if (targetId.indexOf('pt_invite_link_') === 0) {
        e.preventDefault();
        onInviteLinkClicked(e.target.dataset.id, 'pt');
      }
    };

    document.addEventListener('click', globalPatientListener);
    return () =>
      document.removeEventListener('click', globalPatientListener);
  }, [patientDetails]);

  useEffect(() => {
    if (!document || !carersData || !patientDetails) return true;

    const globalCarerListener = (e) => {
      const targetId = e.target.id;
      if (targetId.indexOf('cr_invite_link_') === 0) {
        e.preventDefault();
        onInviteLinkClicked(e.target.dataset.id, 'cr');
      } else if (targetId.indexOf('cr_view_') === 0) {
        e.preventDefault();
        handleEditAccount(e.target.dataset.id);
      }
    };

    document.addEventListener('click', globalCarerListener);
    return () =>
      document.removeEventListener('click', globalCarerListener);
  }, [carersData, patientDetails]);

  useEffect(() => {
    if (
      syncedToSF !== undefined &&
      registrationTid &&
      featureFlags.remoteCheck &&
      Object.keys(rawPatientDetails).length !== 0 &&
      rawCarersDetails &&
      !rawPatientDetails.data?.isDeceased &&
      hasLoginFromMCP !== undefined &&
      ageThresholdFromMCP &&
      localeFromMCP
    ) {
      setupRemoteCheck(
        rawPatientDetails,
        rawCarersDetails,
        providerId,
        providerName,
        lang,
        localeFromMCP,
        syncedToSF,
        registrationTid,
        getAge,
        hasLoginFromMCP,
        ageThresholdFromMCP,
      );
      insertRemoteCheckScript();
    }
  }, [
    syncedToSF,
    registrationTid,
    rawPatientDetails,
    rawCarersDetails,
    ageThresholdFromMCP,
    localeFromMCP,
    hasLoginFromMCP,
  ]);

  useEffect(() => {
    // eslint-disable-next-line func-names
    window.addEventListener('message', function (e) {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('modalEvent' in e.data) {
          switch (e.data.modalEvent.target) {
            case 'serviceRequest':
              if (e.data.modalEvent.type === 'open') {
                displayModalViewSR(e.data.modalEvent.payload.url);
              }
              break;

            case 'createServiceRequest':
              if (e.data.modalEvent.type === 'close') {
                setShowCreateSRModal(false);
              }
              break;
            default:
              break;
          }
        }
        if ('pageEvent' in e.data) {
          const mcpPageHeigth = e.data.pageEvent.payload.height;
          switch (e.data.pageEvent.type) {
            case 'valuesPassed': {
              const {
                syncedToSF: syncedSF,
                registrationTid: regTid,
                hasLogin,
                userLocale,
                ageThreshold,
              } = e.data.pageEvent.payload;
              if (syncedSF !== undefined) setSyncedToSF(syncedSF);
              if (regTid) setRegistrationTid(regTid);
              if (hasLogin) setHasLoginFromMCP(hasLogin);
              if (userLocale) setLocaleFromMCP(userLocale);
              if (ageThreshold) setAgeThresholdFromMCP(ageThreshold);
              break;
            }
            case 'pageLoaded':
              if (e.data.pageEvent.target === 'serviceRequestList') {
                setSrListLoaded(true);
              }
              if (
                e.data.pageEvent.target === 'selectedServiceRequest'
              ) {
                if (selectedSrIframe && selectedSrIframe.current) {
                  selectedSrIframe.current.style.visibility =
                    'visible';
                  selectedSrIframe.current.style.height =
                    mcpPageHeigth === 0
                      ? '1800px'
                      : `${mcpPageHeigth + 100}px`;
                  setSrLoaded(true);
                }
              }
              if (
                e.data.pageEvent.target === 'createServiceRequest'
              ) {
                if (createSrIframe && createSrIframe.current) {
                  createSrIframe.current.style.visibility = 'visible';
                  createSrIframe.current.style.height =
                    mcpPageHeigth === 0
                      ? '300px'
                      : `${mcpPageHeigth}px`;
                  showCreateSRFrameLoaded(true);
                }
              }
              break;
            case 'serviceError':
              setMcpError(true);
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    attachRemoteCheckMessageListener();
  }, []);

  const refreshEquipment = async () => {
    // need to reset loadedIds, because equipment will be refreshed
    loadedIds = [];
    setRhsConfig({ ...rhsConfig, isLoading: true });
    fetchEquipment(cacheControlHeader);
  };

  const refreshEquipmentEarSide = async () => {
    // need to reset loadedIds, because equipment will be refreshed
    loadedIds = [];

    await fetchEquipment(cacheControlHeader);
    fetchPatientDetails();
  };

  const refreshEmptyEquipment = async () => {
    setRhsConfig({ ...rhsConfig, isLoading: true, isEmpty: true });
    fetchEquipment(cacheControlHeader);
  };

  useEffect(() => {
    const tempRhsConfig = rhsConfig;
    tempRhsConfig.taskBarConfig.refreshButton = {
      variant: 'tinted',
      icon: 'refresh',
      onClick: refreshEquipment,
      analytics: 'refresh_devices_view_patient',
    };

    setRhsConfig(tempRhsConfig);
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object' && 'pageEvent' in e.data) {
        const { type, target } = e.data.pageEvent;
        if (type === 'srCreated' && target === 'equipment') {
          refreshEquipment();
        }
      }
    });
  }, []);

  useEffect(() => {
    setCreateServiceRequestUrl(
      setIdpParam(
        replaceTokens(routes.mcp.createServiceRequest, arrayTokens) +
          deviceId,
      ),
    );
  }, [deviceId]);

  useEffect(() => {
    if (srListLoaded) {
      const newRhsConfig = { ...rhsConfig };
      newRhsConfig.serviceRequestConfig.isLoading = false;
      setRhsConfig(newRhsConfig);
    }
  }, [srListLoaded]);

  useEffect(() => {
    const newRhsConfig = { ...rhsConfig };
    if (mcpError) {
      newRhsConfig.serviceRequestConfig.hasError = true;
    } else {
      newRhsConfig.serviceRequestConfig.hasError = false;
    }
    setRhsConfig(newRhsConfig);

    if (mcpError) {
      // auto reload if mcp has error, probably due to out of sync
      // between oracle and salesforce.
      const reloadTimeoutId = setTimeout(() => {
        const cfg = { ...rhsConfig };
        cfg.serviceRequestConfig.hasError = false;
        setMcpError(false);
        setRhsConfig(cfg);
      }, 30000);

      return () => clearTimeout(reloadTimeoutId);
    }

    return () => {};
  }, [mcpError]);

  useEffect(() => {
    if (Object.keys(rawPatientDetails).length !== 0) {
      const tempRhsConfig = rhsConfig;
      tempRhsConfig.taskBarConfig.button.onClick =
        addEquipementCallback;
      tempRhsConfig.emptyEquipment.button.onClick =
        addEquipementCallback;

      if (
        rawPatientDetails.data?.personas?.length > 0 &&
        rawPatientDetails.data?.personas.indexOf('Recipient') === -1
      ) {
        tempRhsConfig.serviceRequestConfig.notApplicable = true;
        tempRhsConfig.serviceRequestConfig.button = {
          onClick: addEquipementCallback,
          analytics: 'add_device_service_requests_view_patient',
        };
      } else {
        tempRhsConfig.serviceRequestConfig.notApplicable = false;
        tempRhsConfig.serviceRequestConfig.hasError = mcpError;
        delete tempRhsConfig.serviceRequestConfig.button;
      }

      setRhsConfig(tempRhsConfig);
    }
  }, [rawPatientDetails]);

  const redirectToContactUs = () => {
    // TODO: use routes? ${routes.dpx.contactUs}
    window.location.href = `https://www.cochlear.com/${country}/${lang}/connect/contact-us/`;
  };

  useEffect(() => {
    if (patientDetails && rawPatientDetails) {
      if (rawPatientDetails.data?.isDeceased) {
        setHeadingDetails(
          getPatientDeceasedConfig(
            headingDetails,
            patientTitle,
            labels,
            redirectToContactUs,
          ),
        );
      } else {
        setHeadingDetails({
          ...headingDetails,
          title: {
            ...headingDetails.title,
            content: patientTitle,
          },
        });
      }
    }
  }, [patientDetails, rawPatientDetails]);

  const filterSoundProcessorsSummary = (data) => {
    // keep only 2 recent SP
    let soundProcessorCount = 0;
    const filteredData = data.filter((item) => {
      if (item.device.type === 'Sound Processor') {
        soundProcessorCount += 1;
        return soundProcessorCount <= 2;
      }
      return true;
    });

    filteredData.sort((a, b) =>
      a.device.type.localeCompare(b.device.type),
    );

    return filteredData;
  };

  const filterUnknownDataSummary = (data) => {
    // keep only Implant or SP if ear side is unknown
    const filteredData = data.filter(
      (item) =>
        item.device.type === 'Sound Processor' ||
        item.device.type === 'Implant',
    );
    return filterSoundProcessorsSummary(filteredData);
  };

  useEffect(() => {
    const tempHeading = headingDetails;

    const isDeviceSuccess = notifications.some(
      (item) =>
        item.details.entity === 'Device' &&
        item.details.status === 'processed' &&
        new Date() - new Date(item.lastUpdated) < 60000,
    );
    if (isDeviceSuccess) {
      tempHeading.alertConfig = {
        visible: false,
      };
      setHeadingDetails({
        ...tempHeading,
      });
      refreshEquipment();
      // patient persona should be changed from Pending to Recipient as a result of the device registration
      refreshPatientData();
    }
  }, [notifications]);

  return (
    !pageLevelError && (
      <Feature name="featureEnabler/viewPatient">
        {(isEnabled) =>
          isEnabled ? (
            <>
              <FindClinicModal
                {...clinicSelectorConfig}
                show={showSelectSurgeryCentreModal}
                onHide={() => setShowSelectSurgeryCentreModal(false)}
                labels={labels}
              />
              <Modal
                show={showAuthorizationAssociateClinic}
                onHide={() => setSelectedClinic(undefined)}
                size="xl"
              >
                <Modal.Header
                  closeButton
                  data-analytics="cancel_header_associate_clinic_modal"
                >
                  <Modal.Title>
                    {`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.title']}`}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {!selectedClinic?.isClinicAssociated && (
                    <>
                      <Text
                        content={`${
                          labels[
                            'labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.patientName'
                          ]
                        }: ${getFullName()}`}
                        type="body-text-bold"
                      />
                      <Text
                        content={`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.additionalClinic']}: ${selectedClinic?.name}, ${selectedClinic?.address}`}
                        type="body-text-bold"
                      />
                    </>
                  )}

                  <div
                    style={{
                      paddingTop: '2rem',
                      paddingBottom: '1rem',
                    }}
                  >
                    {!selectedClinic?.isClinicAssociated && (
                      <Alert heading="" show>
                        {`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.agreeToTitle']}`}
                      </Alert>
                    )}
                    {selectedClinic?.isClinicAssociated && (
                      <Alert heading="" show>
                        {`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.clinicAssociatedTitle']}`}
                      </Alert>
                    )}
                  </div>
                  {!selectedClinic?.isClinicAssociated && (
                    <>
                      <Text
                        content={`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.agreeToBody']}`}
                        type="small-body-text"
                      />
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {!selectedClinic?.isClinicAssociated && (
                    <>
                      <Button
                        onClick={() => {
                          setSelectedClinic(undefined);
                          setShowSelectSurgeryCentreModal(true);
                        }}
                        text={`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.cancel']}`}
                        variant="tertiary"
                        data-analytics="cancel_associate_clinic_modal"
                      />
                      <Button
                        onClick={async () => {
                          await addSelectedAssociatedClinic();
                        }}
                        text={`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.agree']}`}
                        data-analytics="agree_associate_clinic_modal"
                      />
                    </>
                  )}
                  {selectedClinic?.isClinicAssociated && (
                    <Button
                      onClick={async () => {
                        setSelectedClinic(undefined);
                      }}
                      text={`${labels['labels.patientRecord.views.associatedClinicsView.associatingClinic.authorizationAssociateClinicModal.close']}`}
                      data-analytics="close_associate_clinic_modal"
                    />
                  )}
                </Modal.Footer>
              </Modal>
              {!disableIframes && (
                <iframe
                  src={setIdpParam(
                    `${routes.mcp.recipientAdditionalData}${patientId}`,
                  )}
                  title="mcpAdditional"
                  style={{
                    display: 'none',
                    position: 'absolute',
                    visibility: 'hidden',
                    zIndex: '-2147483647',
                  }}
                />
              )}
              <Feature name="featureEnabler/serviceRequest">
                <Modal
                  footer=""
                  show={show}
                  title=""
                  onHide={() => setShow(false)}
                  size="xl"
                >
                  <Modal.Header
                    closeButton
                    data-analytics="close_view_sr_modal"
                  >
                    <Modal.Title>&nbsp;</Modal.Title>
                  </Modal.Header>
                  <Title
                    content={
                      labels[
                        'labels.patientRecord.tabs.serviceRequests.view.title'
                      ]
                    }
                    size="heading-2"
                    tag="h2"
                    style={{
                      marginLeft: '2rem',
                    }}
                  />

                  {!srLoaded && (
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        marginTop: '3rem',
                      }}
                    >
                      <>
                        <Spinner animation="border" />
                        <Text
                          content={
                            labels[
                              'labels.patientRecord.tabs.serviceRequests.view.loading'
                            ]
                          }
                        />
                      </>
                    </div>
                  )}
                  <iframe
                    src={setIdpParam(
                      `${routes.mcp.domain}${srUrl}&dpx=true`,
                    )}
                    title="View SR"
                    width="100%"
                    style={{ marginTop: '0', visibility: 'hidden' }}
                    ref={selectedSrIframe}
                  />
                </Modal>
              </Feature>
              <Modal
                title={title}
                fullscreen
                show={showCreateSRModal}
                onHide={() => setShowCreateSRModal(false)}
              >
                <Modal.Header
                  closeButton
                  data-analytics="close_create_sr_modal"
                >
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {!createSRFrameLoaded && (
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        marginTop: '3rem',
                      }}
                    >
                      <>
                        <Spinner animation="border" />
                        <Text
                          content={
                            labels[
                              'labels.patientRecord.tabs.equipments.dataTable.options.loadingCreateSR'
                            ]
                          }
                        />
                      </>
                    </div>
                  )}
                  <iframe
                    src={createServiceRequestUrl}
                    id="modaliFrame-createSR"
                    title={title}
                    width="100%"
                    style={{ visibility: 'hidden' }}
                    ref={createSrIframe}
                  />
                </Modal.Body>
              </Modal>

              <Modal
                show={showReportIssueModal}
                onHide={() => setShowReportIssueModal(false)}
                size="xl"
                centered
                backdrop="static"
                keyboard={false}
                variant="primary"
              >
                <Modal.Header
                  closeButton
                  data-analytics="cancel_report_issue_modal"
                >
                  <Modal.Title>
                    {
                      labels[
                        'labels.patientRecord.tabs.equipments.reportIssueModal.title'
                      ]
                    }
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isSubmitted && professionalCase?.success && (
                    <Alert
                      heading={
                        labels[
                          'labels.patientRecord.tabs.equipments.reportIssueModal.messageHasBeenSent'
                        ]
                      }
                      show
                      variant="success"
                    >
                      <Text
                        content={
                          labels[
                            'labels.patientRecord.tabs.equipments.reportIssueModal.messageSentSuccessOne'
                          ]
                        }
                        type="body-text"
                      />
                      <br />
                      <Text
                        content={labels[
                          'labels.patientRecord.tabs.equipments.reportIssueModal.messageSentSuccessTwo'
                        ].replace(
                          '[CASE_NUMBER]',
                          `${professionalCase.data.id}`,
                        )}
                        type="body-text"
                      />
                    </Alert>
                  )}
                  {isSubmitted && !professionalCase && (
                    <ErrorComponent
                      config={{
                        buttons: [
                          {
                            action: 'link-dpx-ri',
                            icon: '',
                            link: '',
                            name: 'dpxri',
                            text: `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.tryAgain']}`,
                            useCallback: true,
                            variant: 'brand-primary',
                          },
                        ],
                        codeLabel: `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.errorMessageCodeLabel']}`,
                        handleCallback: () => {
                          tryAgain();
                        },
                        imgAlt: 'labels.errorMessage.imgAlt',
                        imgSrc:
                          'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
                        imgWidth: 116,
                        text: 'labels.errorMessage.text',
                        title: 'labels.errorMessage.title',
                      }}
                      data={{}}
                      labels={{
                        'labels.errorMessage.codeLabel': `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.errorMessageCodeLabel']}`,
                        'labels.errorMessage.imgAlt': `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.errorMessageImgAlt']}`,
                        'labels.errorMessage.text': `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.errorMessageBody']}`,
                        'labels.errorMessage.title': `${labels['labels.patientRecord.tabs.equipments.reportIssueModal.errorMessageTitle']}`,
                      }}
                    />
                  )}
                  {!isSubmitted && (
                    <>
                      <div className="report-issue-group">
                        <div className="ccl__columns">
                          <div className="ccl__column">
                            <Text
                              content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.reasonForContacting']}`}
                              type="body-text-bold"
                            />
                          </div>
                          <div className="ccl__column">
                            <Text
                              content={`${initPayloadData.webReason}`}
                              type="small-body-text"
                            />
                          </div>
                          <div className="ccl__column" />
                        </div>
                      </div>

                      <div className="report-issue-group">
                        <div className="ccl__columns report-issue-section-title">
                          <div className="ccl__column">
                            <Text
                              content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.patientDetails']}`}
                              type="body-text-bold"
                            />
                          </div>
                        </div>
                        <div className="ccl__columns">
                          <div className="ccl__column">
                            <Text
                              content={`${
                                labels[
                                  'labels.patientRecord.tabs.equipments.reportIssueModal.name'
                                ]
                              }: ${getFullName()}`}
                              type="small-body-text"
                            />
                          </div>
                          <div className="ccl__column">
                            <Text
                              content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.age']}: ${age}`}
                              type="small-body-text"
                            />
                          </div>
                          <div className="ccl__column" />
                        </div>
                      </div>

                      <div className="report-issue-group">
                        <div className="ccl__columns report-issue-section-title">
                          <div className="ccl__column">
                            <Text
                              content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.deviceDetails']}`}
                              type="body-text-bold"
                            />
                          </div>
                        </div>
                        <div className="ccl__columns report-issue-section-row">
                          <div className="ccl__column">
                            <Text
                              content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.deviceType']}: ${selectedDevice?.device?.type}`}
                              type="small-body-text"
                            />
                          </div>
                          <div className="ccl__column">
                            <Text
                              content={`${
                                labels[
                                  'labels.patientRecord.tabs.equipments.reportIssueModal.serialNo'
                                ]
                              }: ${getSerialNo()}`}
                              type="small-body-text"
                            />
                          </div>
                          <div className="ccl__column">
                            <Text
                              content={`${
                                labels[
                                  'labels.patientRecord.tabs.equipments.reportIssueModal.earSide'
                                ]
                              }: ${getEarSide()}`}
                              type="small-body-text"
                            />
                          </div>
                        </div>
                        {!selectedExpandedData && (
                          <>
                            <div className="ccl__columns report-issue-section-row ">
                              <div
                                className="ccl__column"
                                style={{ textAlign: 'center' }}
                              >
                                <Spinner animation="border" />
                                <Text
                                  content={`${labels['labels.patientRecord.tabs.equipments.reportIssueModal.loadingSurgeryDetails']}`}
                                  type="small-body-text"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {selectedExpandedData && (
                          <>
                            <div className="ccl__columns report-issue-section-row">
                              <div className="ccl__column">
                                <Text
                                  content={`${
                                    labels[
                                      'labels.patientRecord.tabs.equipments.reportIssueModal.surgeryDate'
                                    ]
                                  }: ${
                                    selectedExpandedData
                                      ?.expandedDataR[0]?.value ?? ''
                                  }`}
                                  type="small-body-text"
                                />
                              </div>
                              <div className="ccl__column">
                                <Text
                                  content={`${
                                    labels[
                                      'labels.patientRecord.tabs.equipments.reportIssueModal.surgeon'
                                    ]
                                  }: ${
                                    selectedExpandedData
                                      ?.expandedDataL[0]?.value ?? ''
                                  }`}
                                  type="small-body-text"
                                />
                              </div>
                              <div className="ccl__column" />
                            </div>

                            <div className="ccl__columns">
                              <div className="ccl__column">
                                <Text
                                  content={`${
                                    labels[
                                      'labels.patientRecord.tabs.equipments.reportIssueModal.surgeryCenter'
                                    ]
                                  }: ${
                                    selectedExpandedData
                                      ?.expandedDataL[1]?.value ?? ''
                                  }`}
                                  type="small-body-text"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="report-issue-form-wrapper">
                        <form
                          onSubmit={handleSubmit}
                          onChange={handleChange}
                        >
                          <div className="report-issue-form-description-wrapper">
                            <TextArea
                              isFluid
                              label={
                                labels[
                                  'labels.patientRecord.tabs.equipments.reportIssueModal.message'
                                ]
                              }
                              name="description"
                              stateManaged={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.description &&
                                !!errors.description
                              }
                              errorMsg={errors.description}
                            />
                          </div>
                        </form>
                        <div className="report-issue-from-wrapper">
                          <Text
                            content={
                              labels[
                                'labels.patientRecord.tabs.equipments.reportIssueModal.from'
                              ]
                            }
                            type="body-text-bold"
                          />
                          <Text
                            content={`${firstName} ${lastName}`}
                            type="small-body-text"
                          />
                          <Text
                            content={`${username}`}
                            type="small-body-text"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer className="report-issue-modal-footer">
                  {isSubmitted && professionalCase && (
                    <>
                      <Button
                        onClick={() => {
                          setShowReportIssueModal(false);
                        }}
                        text={
                          labels[
                            'labels.patientRecord.tabs.equipments.reportIssueModal.close'
                          ]
                        }
                        variant="secondary"
                        data-analytics="close_customer_case"
                      />
                    </>
                  )}
                  {!isSubmitted && (
                    <>
                      <Button
                        onClick={handleSubmit}
                        text={
                          labels[
                            'labels.patientRecord.tabs.equipments.reportIssueModal.sendEnquiry'
                          ]
                        }
                        disabled={!dirty || !isValid || isSubmitting}
                        data-analytics="send_customer_case"
                      />
                      <Button
                        onClick={() => {
                          setShowReportIssueModal(false);
                        }}
                        text={
                          labels[
                            'labels.patientRecord.tabs.equipments.reportIssueModal.cancel'
                          ]
                        }
                        variant="tertiary"
                        data-analytics="cancel_customer_case"
                      />
                    </>
                  )}
                </Modal.Footer>
              </Modal>

              <AccountInviteModal
                rendering={rendering}
                providerId={providerId}
                inviteData={inviteData}
                setInviteData={setInviteData}
                showInviteModal={showInviteModal}
                onInviteModalClose={onInviteModalClose}
                inviteSendingInProgress={inviteSendingInProgress}
                setInviteSendingInProgress={
                  setInviteSendingInProgress
                }
                loggedInCountryCode={country}
              />
              <UpdateAccessoryModal
                rendering={rendering}
                showUpdateAccessoryModal={showUpdateAccessoryModal}
                selectedAccessory={selectedAccessory}
                setShowUpdateAccessoryModal={
                  setShowUpdateAccessoryModal
                }
                country={country}
                uDetails={uDetails}
                providerId={providerId}
                patientId={patientId}
                updateAccessory={updateAccessory}
              />

              <MainPatientWrapper
                panelDetails={{
                  config: panelConfig,
                  data: panelData,
                  labels,
                }}
                headingDetails={headingDetails}
                summaryDetails={{
                  config: rhsConfig,
                  data: equipmentData,
                  labels,
                  leftData: filterSoundProcessorsSummary(
                    Object.values(equipmentDataBackup).filter(
                      (data) => data.earLeft && data.icon,
                    ),
                  ),
                  rightData: filterSoundProcessorsSummary(
                    Object.values(equipmentDataBackup).filter(
                      (data) => !data.earLeft && data.icon,
                    ),
                  ),
                  unknownData: filterUnknownDataSummary(
                    Object.values(equipmentDataBackup).filter(
                      (data) => data.isUnknownEarSIde,
                    ),
                  ),
                  rcIframeKey,
                }}
              />
            </>
          ) : (
            <DisabledFeature
              title={labels['labels.common.patientRecord']}
              message={
                labels['labels.common.featureFlag.disabledText']
              }
            />
          )
        }
      </Feature>
    )
  );
};

export default PatientRecord;
