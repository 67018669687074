import { routes, cochlearLearningCentreURL } from '../../config.js';

/* eslint-disable dot-notation */
export const getHeaderConfig = (
  providerCountryCode,
  providerRegion,
  labels,
) => {
  let cspCamTrainingUrl = routes.mcp.cspCamTraining;

  if (providerRegion === 'CAM') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-cam',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'en');
  } else if (providerCountryCode === 'IT') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'it');
  } else if (providerCountryCode === 'DE') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'de');
  } else if (providerCountryCode === 'FR') {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-emea',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'fr');
  } else {
    cspCamTrainingUrl = cspCamTrainingUrl.replace(
      '[REGION]',
      'mcp-cam',
    );
    cspCamTrainingUrl = cspCamTrainingUrl.replace('[LANG]', 'en');
  }

  return {
    topNavMenus: [
      {
        name: 'Dashboard',
        items: [
          {
            name: 'home',
            labelKey: 'dashboard',
            url: 'home',
            type: 'internal',
          },
        ],
      },
      {
        name: 'Create patient',
        items: [
          {
            name: 'create-patient',
            labelKey: 'createPatient',
            url: 'create-patient-landing',
            type: 'internal',
          },
        ],
      },
      {
        name: 'Clinic admin',
        items: [
          {
            name: 'clinic-admin',
            labelKey: 'clinicAdmin',
            url: '',
            type: 'internal',
          },
          {
            name: 'clinician-management',
            labelKey: 'clinicianManagement',
            url: 'clinician-management',
            type: 'internal',
          },
          {
            name: 'clinic-equipment',
            labelKey: 'clinicEquipment',
            url: 'clinic-equipment',
            type: 'internal',
          },
        ],
      },
      {
        name: 'Remote Check',
        items: [
          {
            name: 'remote-check',
            labelKey: 'remoteCheck',
            url: '{{##___REMOTE_CHECK_URL___##}}',
            type: 'internal',
          },
        ],
      },
      {
        name: 'Orders',
        items: [
          {
            name: 'order-forms',
            labelKey: 'orders',
            url: '',
            type: 'internal',
          },
          {
            name: 'order-forms-link',
            labelKey: 'orderForms',
            url: '{{##___ORDER_FORMS_URL___##}}',
            type: 'external',
          },
        ],
      },
      {
        name: 'Resources and Training',
        items: [
          {
            name: 'resources-training',
            labelKey: 'resourcesTraining',
            url: '',
            type: 'internal',
          },
          {
            name: 'cochlear-learning-centre',
            labelKey: 'cochlearLearningCentre',
            url: cochlearLearningCentreURL,
            type: 'external',
          },
          {
            name: 'csp-support-toolkit',
            labelKey: 'cspSupportToolKit',
            url: cspCamTrainingUrl,
            type: 'external',
          },
          {
            name: 'csp-virtual-software',
            labelKey: 'cspVirtualSoftware',
            url: routes.mcp.cspVirtualSoftware,
            type: 'external',
          },
          {
            name: 'cpn-resources',
            labelKey: 'cpnResources',
            url: 'cpn-resource',
            type: 'internal',
          },
          {
            name: 'resources',
            labelKey: 'resources',
            url: 'resources',
            type: 'internal',
          },
        ],
      },
      {
        name: 'Help centre',
        items: [
          {
            name: 'help-centre',
            labelKey: 'helpCentre',
            url: '',
            type: 'internal',
          },
          {
            name: 'contact-us',
            labelKey: 'contactUs',
            url: '#showContactUsModal',
            type: 'internal',
          },
          {
            name: 'return-to-legacy-portal',
            labelKey: 'returnOldPortal',
            url: '#showReturnOldPortalModal',
            type: 'internal',
          },
        ],
      },
    ],
    links: {
      targetItem: {
        children: [
          {
            name: 'myAccount',
            url: {
              value: 'my-account',
            },
            type: {
              linkType: 'internal',
              url: '/us/en/dpx/my-account',
            },
            icon: {
              value: '',
            },
          },
          {
            name: 'logout',
            url: {
              value: '/authorize?action=logout',
            },
            type: {
              linkType: 'external',
              url: '/authorize?action=logout',
            },
            icon: {
              value: '',
            },
          },
        ],
      },
    },
    caseTypes: [
      {
        label: labels['portalIssue'],
        value: labels['portalIssue'],
      },
      {
        label: labels['deviceAppSupport'],
        value: labels['deviceAppSupport'],
      },
      {
        label: labels['orderStatus'],
        value: labels['orderStatus'],
      },
      {
        label: labels['accountLoginSupport'],
        value: labels['accountLoginSupport'],
      },
      {
        label: labels['surgicalSupport'],
        value: labels['surgicalSupport'],
      },
      {
        label: labels['researchSupport'],
        value: labels['researchSupport'],
      },
      {
        label: labels['remoteCheck'],
        value: labels['remoteCheck'],
      },
      {
        label: labels['somethingElse'],
        value: labels['somethingElse'],
      },
      {
        label: labels['returnOldPortal'],
        value: labels['returnOldPortal'],
      },
    ],
    channel: 'DPX',
  };
};
