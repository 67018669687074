/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { PhoneInput } from '@cochlear-design-system/foundation';

// eslint-disable-next-line no-unused-vars
const ContactDetails = ({
  labels,
  phoneNumber,
  setPhoneNumber,
  country,
  setIsValid,
  optional,
}) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hadFocus, setHadFocus] = useState(false);

  const formatErrorMessage = (value) => {
    const fullNumber = `${value?.callingCode}${value?.number}`;
    const minLength = 7;
    const maxLength = value?.callingCode === '1' ? 12 : 15;
    const minLengthMessage = labels[
      'labels.common.mobile.validation.minLength'
    ].replace('~MINDIGITS~', minLength);
    const maxLengthMessage = labels[
      'labels.common.mobile.validation.maxLength'
    ].replace('~MAXDIGITS~', maxLength);

    if (fullNumber?.length < minLength) {
      return minLengthMessage;
    }

    if (fullNumber?.length > maxLength) {
      return maxLengthMessage;
    }

    if (!optional && !value?.number) {
      return labels['labels.common.errors.mobile.required'];
    }

    return '';
  };

  const isValidPhoneNumber = (value) => {
    if (optional && !value?.number) {
      setError(false);
      return true;
    }
    const phoneRegex =
      value.callingCode === '1'
        ? /^\+?[1]\d{6,11}$/
        : /^\+?[1-9]\d{6,14}$/;
    const isValid = phoneRegex.test(
      `${value?.callingCode}${value?.number}`,
    );
    setError(!isValid);
    setErrorMessage(formatErrorMessage(value));
    return isValid;
  };

  useEffect(() => {
    if (optional) {
      setIsValid(isValidPhoneNumber(phoneNumber));
    }
  }, []);

  return (
    <>
      <div className="mt-7">
        <div className="ccl-e-detail-action-bar">
          <div className="ccl-e-detail-action-bar__title">
            <span>
              {labels['labels.common.contactDetails.title']}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <PhoneInput
          country={country.toUpperCase()}
          hintForCode={
            labels['labels.common.contactDetails.mobile.hintForCode']
          }
          hint={labels['labels.common.contactDetails.mobile.hint']}
          label={labels['labels.common.contactDetails.mobile']}
          name="mobileNumber"
          optionalText=""
          value={phoneNumber.number}
          onBlur={() => {
            setHadFocus(true);
            setIsValid(isValidPhoneNumber(phoneNumber));
          }}
          onChange={(e, value) => {
            setPhoneNumber(value);
            if (hadFocus) {
              setIsValid(isValidPhoneNumber(value));
            }
          }}
          error={error}
          errorMsg={errorMessage}
        />
      </div>
    </>
  );
};

export default ContactDetails;
