import { getFindClinicModalConfig } from '../../cdsConfigs/findClinicModalConfig';

export const config = (
  theme = '',
  isError = false,
  activeStep = 0,
  activeScenario = 'default-clinic',
  onFormDataChangedHandle,
  handleSearchClinic,
  removeClinics,
  handleSelectClinic,
  onRegistrationSubmitHandle,
  surgeonList,
  deviceType,
  redirectToDeviceRegistration,
  deviceRegistrationReviewWrapperRef,
  defaultCountry,
  dateInputFormat,
  datePlaceholder,
  labels,
  isLotControlled,
) => {
  const clinicSelectorConfig = getFindClinicModalConfig(
    defaultCountry,
    labels,
  );
  clinicSelectorConfig.searchButton.onClick = handleSearchClinic;
  clinicSelectorConfig.tableConfig.events.onRowClick =
    handleSelectClinic;
  clinicSelectorConfig.cinicField.minLength = 3;
  clinicSelectorConfig.countryField.disabled = true;
  clinicSelectorConfig.results.maxCount = 20;
  clinicSelectorConfig.countryField.disabled = false;
  return {
    formType: 'wizard',
    activeStep,
    activeScenario,
    maxSteps: 2,
    handleQueryParams: () => {},
    isLoading: false,
    errorFormView: '',
    isError: false,
    errorMode: '',
    onFormDataChangedHandle,
    theme,

    tabsData: {
      accountId: '',
      userId: 1,
      labels: {
        promptText: '',
        promptTextAbbrv: '',
        type: '',
      },
      tabs: [
        {
          firstName: '',
          lastName: '',
          tabIndex: 0,
          type: '',
          userId: '',
        },
      ],
    },

    steps: [
      {
        continue: {
          labels: {
            defaultText: 'labels.deviceRegReview.register',
            inProcessText: 'labels.deviceRegReview.register',
            ProccessedText: 'labels.deviceRegReview.register',
          },
          updateState: (isValid) => {
            if (activeScenario === 'add-clinic') {
              return 'disabled';
            }
            if (isValid) {
              return '';
            }
            return 'disabled';
          },

          onProcess: async () => {
            await onRegistrationSubmitHandle();
          },
          onAfterProcess: () => {},
          analytics: 'submit_review_register_device',
        },

        cancel: {
          labels: {
            defaultText: 'labels.deviceRegReview.cancel',
            inProcessText: 'labels.deviceRegReview.cancel',
            ProccessedText: 'labels.deviceRegReview.cancel',
          },

          onProcess: () => {
            deviceRegistrationReviewWrapperRef.current.setModalState(
              true,
            );
          },
          updateState: () => {},
          analytics: 'cancel_review_register_device',
        },
        conditions: [
          {
            name: 'default-clinic',
            views: [
              {
                country: 'US',
                language: 'en',
                mask: 'dd mm yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.content',
                fallbackTitle: 'labels.deviceRegReview.content',
                isError,
                type: 'Form',
                mode: 'readWrite',
                showGlobalEdit: true,
                data: 'personal',
                removeViewSwitch: true,
                editLabel: 'labels.deviceRegReview.device.edit',
                onEditHandle: () => {
                  redirectToDeviceRegistration();
                },
                onFormDataChangedHandle,
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  analytics: 'edit_review_register_device',
                  fields: [],

                  elements: [
                    {
                      id: 'productFamily',
                      label:
                        'labels.deviceRegReview.device.productFamily',
                      value: '{{productFamily}}',
                    },
                    {
                      id: 'deviceType',
                      label:
                        'labels.deviceRegReview.device.deviceType',
                      value: '{{deviceTypeLabel}}',
                    },
                    {
                      id: 'model',
                      label: 'labels.deviceRegReview.device.model',
                      value: '{{model}}',
                    },
                    {
                      id: 'description',
                      label:
                        'labels.deviceRegReview.device.description',
                      value: '{{description}}',
                    },
                    {
                      id: 'serialNumber',
                      label:
                        'labels.deviceRegReview.device.serialNumber',
                      value: '{{serialNumber}}',
                      hidden: isLotControlled,
                    },
                    {
                      id: 'lotNumber',
                      label:
                        'labels.deviceRegReview.device.lotNumber',
                      value: '{{lotNumber}}',
                      hidden: !isLotControlled,
                    },
                  ],
                },
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  mask: 'dd MM yyyy',
                  useLiveValidation: false,
                  showInitialErrors: false,
                  elements: [],
                  analytics: 'edit_review_register_device',
                },
              },
              {
                table: {
                  country: 'US',
                  language: 'en',
                  formats: {
                    date: 'mmm dd yyyy',
                    currency: 'USD',
                  },
                  highlight: false,
                  handleSortDataChange: () => {}, // callback function
                  columns: [
                    {
                      display:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.fittingClinic'
                          : 'labels.deviceRegReview.surgerycentre',
                      field: 'name',
                      format: {
                        type: 'string',
                      },
                      sortable: false,
                    },
                    {
                      default: 'asc',
                      display: 'labels.orders.columnHeaders.address',
                      field: 'address',
                      format: {
                        type: 'string',
                      },
                      sortable: false,
                      syncSort: 'orderNumberOrderDate',
                    },
                    {
                      display: '',
                      field: 'id',
                      format: {
                        additionalConfig: {
                          className: 'remove',
                          conditional: 'isRemovable',
                          onClick: (row) => {
                            removeClinics(row);
                          },
                          text: 'labels.orders.columnHeaders.remove',
                        },
                        type: 'button',
                      },
                      sortable: false,
                    },
                  ],
                },
                addButton: {
                  disabled: true,
                  text:
                    deviceType === 'SPEECH_PROCESSOR'
                      ? labels[
                          'labels.deviceRegReview.associatedClinic.fittingCentre.title'
                        ]
                      : labels[
                          'labels.deviceRegReview.associatedClinic.surgeryCentre.title'
                        ],
                },
                data: 'clincsPopupData',
                id: 'associatedCarersView',
                introText: '',
                mode: 'n/a',
                popup: {
                  ...clinicSelectorConfig,
                },
                title:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fittingClinic'
                    : 'labels.deviceRegReview.surgerycentre',
                type: 'associatedClinic',
              },
              {
                country: 'GB',
                language: 'en',
                mask: 'dd MM yyyy',
                id: 'surgeryView',
                title:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fitting'
                    : 'labels.deviceRegReview.surgery',
                fallbackTitle:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fitting'
                    : 'labels.deviceRegReview.surgery',
                isError,
                type: 'Form',
                formType: 'wizard',
                mode: 'editOnly',
                data: 'personal',
                onFormDataChangedHandle,
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  submissionUrl: 'patients/me',
                  submissionModel: 'personal',
                  mask: 'dd MM yyyy',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  // THESE ARE THE ELEMENTS THAT SHOW ON THE FIRST PAGE
                  elements: [
                    {
                      additionalConfig: {
                        dateFormat: dateInputFormat,
                        isSpecial: true,
                        maxDate: new Date(),
                        minDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - 365,
                          ),
                        ),
                        onChangeRaw: (e) => {
                          e.preventDefault();
                        },
                      },
                      apifield: 'datePicker',
                      hidden: false,
                      hint: 'labels.deviceRegReview.device.date.hint',
                      id: 'date of birth',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.fittingDate'
                          : 'labels.deviceRegReview.device.surgeryDate',
                      type: 'datepicker',
                      placeholder: datePlaceholder,
                      optionalText:
                        'labels.deviceRegReview.device.date.optional',
                      validators: [
                        {
                          apifield: 'datePicker',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.deviceRegReview.validationPlaceholder',
                        },
                      ],
                    },
                    {
                      additionalConfig: {
                        options: [
                          {
                            id: 1,
                            label:
                              'labels.af.personal.edit.earSide.right',
                            value: 'Right',
                          },
                          {
                            id: 2,
                            label:
                              'labels.af.personal.edit.earSide.left',
                            value: 'Left',
                          },
                        ],
                      },
                      apifield: 'earSide',
                      hidden: false,
                      hint: '',
                      id: 'earSide',
                      label: 'labels.af.personal.edit.earSide',
                      type: 'radiogroup',
                      validators: [
                        {
                          apifield: 'earSide',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.deviceRegReview.validationPlaceholder',
                        },
                      ],
                    },
                    {
                      apifield: 'surgeon',
                      dataList: surgeonList,
                      format: '',
                      hidden: false,
                      id: 'surgeon',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.audiologist'
                          : 'labels.deviceRegReview.device.surgeon',
                      promptText: 'select',
                      type: 'selectinput',
                      validators: [
                        {
                          apifield: 'surgeon',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.deviceRegReview.validationPlaceholder',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            name: 'add-clinic',
            views: [
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.content',
                fallbackTitle: 'labels.deviceRegReview.content',
                isError,
                type: 'Form',
                mode: 'readWrite',
                data: 'personal',
                editLabel: 'labels.deviceRegReview.device.edit',
                onFormDataChangedHandle,
                showGlobalEdit: true,
                removeViewSwitch: true,
                onEditHandle: () => {
                  redirectToDeviceRegistration();
                },
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'productFamily',
                      label:
                        'labels.deviceRegReview.device.productFamily',
                      value: '{{productFamily}}',
                    },
                    {
                      id: 'deviceType',
                      label:
                        'labels.deviceRegReview.device.deviceType',
                      value: '{{deviceTypeLabel}}',
                    },
                    {
                      id: 'model',
                      label: 'labels.deviceRegReview.device.model',
                      value: '{{model}}',
                    },
                    {
                      id: 'description',
                      label:
                        'labels.deviceRegReview.device.description',
                      value: '{{description}}',
                    },
                    {
                      id: 'serialNumber',
                      label:
                        'labels.deviceRegReview.device.serialNumber',
                      value: '{{serialNumber}}',
                      hidden: isLotControlled,
                    },
                    {
                      id: 'lotNumber',
                      label:
                        'labels.deviceRegReview.device.lotNumber',
                      value: '{{lotNumber}}',
                      hidden: !isLotControlled,
                    },
                  ],
                },
              },
              {
                table: {
                  country: 'US',
                  language: 'en',
                  formats: {
                    date: 'mmm dd yyyy',
                    currency: 'USD',
                  },
                  highlight: false,
                  handleSortDataChange: () => {}, // callback function
                  columns: [
                    {
                      display:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.fittingClinic'
                          : 'labels.deviceRegReview.surgerycentre',
                      field: 'name',
                      format: {
                        type: 'string',
                      },
                      sortable: false,
                    },
                    {
                      default: 'asc',
                      display: 'labels.orders.columnHeaders.address',
                      field: 'address',
                      format: {
                        type: 'string',
                      },
                      sortable: false,
                      syncSort: 'orderNumberOrderDate',
                    },
                    {
                      display: '',
                      field: 'id',
                      format: {
                        additionalConfig: {
                          className: 'remove',
                          conditional: 'isRemovable',
                          onClick: (row) => {
                            removeClinics(row);
                          },
                          text: 'labels.orders.columnHeaders.remove',
                        },
                        type: 'button',
                      },
                      sortable: false,
                    },
                  ],
                },
                addButton: {
                  disabled: false,
                  text:
                    deviceType === 'SPEECH_PROCESSOR'
                      ? labels[
                          'labels.deviceRegReview.associatedClinic.fittingCentre.title'
                        ]
                      : labels[
                          'labels.deviceRegReview.associatedClinic.surgeryCentre.title'
                        ],
                },
                alert: {
                  dismissible: false,
                  heading: '',
                  onClose: function noRefCheck() {},
                  test:
                    deviceType === 'SPEECH_PROCESSOR'
                      ? labels[
                          'labels.deviceRegReview.associatedClinic.fittingCentre.notification'
                        ]
                      : labels[
                          'labels.deviceRegReview.associatedClinic.surgeryCentre.notification'
                        ],
                  variant: 'brand',
                },
                data: 'clincsPopupData',
                id: 'associatedCarersView',
                introText: '',
                mode: 'n/a',
                popup: {
                  ...clinicSelectorConfig,
                },
                title:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fittingClinic'
                    : 'labels.deviceRegReview.surgerycentre',
                type: 'associatedClinic',
              },
              {
                country: 'US',
                language: 'en',
                mask: 'mmmm dd yyyy',
                id: 'personalView',
                title:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fitting'
                    : 'labels.deviceRegReview.surgery',
                fallbackTitle:
                  deviceType === 'SPEECH_PROCESSOR'
                    ? 'labels.deviceRegReview.fitting'
                    : 'labels.deviceRegReview.surgery',
                isError,
                type: 'Form',
                formType: 'wizard',
                mode: 'editOnly',
                data: 'personal',
                onFormDataChangedHandle,
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  submissionUrl: 'patients/me',
                  submissionModel: 'personal',
                  mask: 'mmmm dd yyyy',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  // THESE ARE THE ELEMENTS THAT SHOW ON THE FIRST PAGE
                  elements: [
                    {
                      additionalConfig: {
                        disabled: true,
                        dateFormat: 'MM/dd/yyyy',
                        isSpecial: true,
                        maxDate: new Date(),
                        minDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - 365,
                          ),
                        ),
                      },
                      placeholder: datePlaceholder,
                      apifield: 'datePicker',
                      format: 'dd/MM/yyyy',
                      hidden: false,
                      hint: 'labels.deviceRegReview.device.date.hint',
                      id: 'date of birth',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.fittingDate'
                          : 'labels.deviceRegReview.device.surgeryDate',
                      type: 'datepicker',
                      optionalText:
                        'labels.deviceRegReview.device.date.optional',
                      validators: [
                        {
                          apifield: 'datePicker',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.af.personal.edit.firstname.validation.required',
                        },
                      ],
                    },
                    {
                      additionalConfig: {
                        options: [
                          {
                            id: 1,
                            label:
                              'labels.af.personal.edit.earSide.right',
                            value: 'Right',
                          },
                          {
                            id: 2,
                            label:
                              'labels.af.personal.edit.earSide.left',
                            value: 'Left',
                          },
                        ],
                        disabled: true,
                      },
                      apifield: 'earSide',
                      hidden: false,
                      hint: '',
                      id: 'earSide',
                      label: 'labels.af.personal.edit.earSide',
                      type: 'radiogroup',
                      validators: [
                        {
                          apifield: 'earSide',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.af.personal.edit.firstname.validation.required',
                        },
                      ],
                    },
                    {
                      apifield: 'surgeon',
                      dataList: surgeonList,
                      format: '',
                      hidden: false,
                      id: 'surgeon',
                      label:
                        deviceType === 'SPEECH_PROCESSOR'
                          ? 'labels.deviceRegReview.device.audiologist'
                          : 'labels.deviceRegReview.device.surgeon',
                      promptText: 'select',
                      type: 'selectinput',
                      disabled: true,
                      validators: [
                        {
                          apifield: 'surgeon',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.af.personal.edit.firstname.validation.required',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            name: 'accessory-registration',
            views: [
              {
                country: 'US',
                language: 'en',
                mask: 'dd mm yyyy',
                id: 'personalView',
                title: 'labels.deviceRegReview.accessoryContent',
                fallbackTitle:
                  'labels.deviceRegReview.accessoryContent',
                isError,
                type: 'Form',
                mode: 'readWrite',
                showGlobalEdit: true,
                data: 'personal',
                removeViewSwitch: true,
                editLabel: 'labels.deviceRegReview.device.edit',
                onEditHandle: () => {
                  redirectToDeviceRegistration();
                },
                onFormDataChangedHandle,
                read: {
                  name: 'read',
                  clkErrorLabel: 'labels.af.personal.read.error.clk',
                  errorLabel: 'labels.af.personal.read.error.label',
                  fieldLayout: 'horizontal',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  fields: [],

                  elements: [
                    {
                      id: 'description',
                      label:
                        'labels.deviceRegReview.device.description',
                      value: '{{description}}',
                    },
                    {
                      id: 'productFamily',
                      label:
                        'labels.deviceRegReview.device.partNumber',
                      value: '{{partNumber}}',
                    },
                    {
                      id: 'serialNumber',
                      label:
                        'labels.deviceRegReview.device.serialNumber',
                      value: '{{serialNumber}}',
                      hidden: isLotControlled,
                    },
                    {
                      id: 'lotNumber',
                      label:
                        'labels.deviceRegReview.device.lotNumber',
                      value: '{{lotNumber}}',
                      hidden: !isLotControlled,
                    },
                  ],
                },
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  mask: 'dd MM yyyy',
                  useLiveValidation: false,
                  showInitialErrors: false,
                  elements: [],
                },
              },
              {
                country: 'GB',
                language: 'en',
                mask: 'dd MM yyyy',
                id: 'surgeryView',
                title: 'labels.deviceRegReview.accessoryDetails',
                fallbackTitle:
                  'labels.deviceRegReview.accessoryDetails',
                isError,
                type: 'Form',
                formType: 'wizard',
                mode: 'editOnly',
                data: 'personal',
                onFormDataChangedHandle,
                edit: {
                  saveLabel: 'labels.af.buttons.buttonBar.save',
                  cancelLabel: 'labels.af.buttons.buttonBar.cancel',
                  savingLabel: 'labels.af.buttons.buttonBar.saving',
                  savedLabel: 'labels.af.buttons.buttonBar.saved',
                  edit: 'labels.af.actionBar.buttons.edit',
                  cancel: 'labels.af.actionBar.buttons.cancel',
                  title: 'labels.af.title.personal',
                  fallbackTitle: 'labels.af.title.personal2',
                  submissionUrl: 'patients/me',
                  submissionModel: 'personal',
                  mask: 'dd MM yyyy',
                  showInitialErrors: false,
                  useLiveValidation: false,
                  // THESE ARE THE ELEMENTS THAT SHOW ON THE FIRST PAGE
                  elements: [
                    {
                      additionalConfig: {
                        dateFormat: 'dd/MM/yyyy',
                        isSpecial: true,
                        maxDate: new Date(),
                        minDate: new Date(
                          new Date().setDate(
                            new Date().getDate() - 365,
                          ),
                        ),
                        onChangeRaw: (e) => {
                          e.preventDefault();
                        },
                      },
                      apifield: 'datePicker',
                      hidden: false,
                      hint: 'labels.deviceRegReview.device.date.hint',
                      id: 'date of birth',
                      label:
                        'labels.deviceRegReview.device.purchaseDate',
                      type: 'datepicker',
                      placeholder: datePlaceholder,
                      optionalText:
                        'labels.deviceRegReview.device.date.optional',
                      validators: [
                        {
                          apifield: 'datePicker',
                          type: 'predefined',
                          key: 'required',
                          mask: '',
                          message:
                            'labels.af.personal.edit.firstname.validation.required',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],

    errorView: {
      codeLabel: 'Error code',
      errorResponse: '',
      mode: '',
      data: {
        submit: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: '',
          text: '',
          buttons: [
            {
              name: 'tryAgain',
              variant: 'brand-primary',
              text: '',
              icon: '',
              link: '',
              action: 'retry-submit',
              useCallback: true,
            },
            {
              name: 'backToHome',
              variant: 'secondary',
              text: '',
              icon: 'chevron-right',
              link: 'https://node.sit.cms.cochlear.cloud/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
        read: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: '',
          text: '',
          buttons: [
            {
              name: 'backToHome',
              variant: 'secondary',
              text: '',
              icon: 'chevron-right',
              link: '/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
        readWrite: {
          image: {
            src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
            alt: 'Bug.svg',
            width: '120',
          },
          title: '',
          text: '',
          buttons: [
            {
              name: 'backToHome',
              variant: 'secondary',
              text: '',
              icon: 'chevron-right',
              link: '/us/en/home',
              action: 'link-cochlear-home',
              useCallback: false,
            },
          ],
        },
      },
    },
    token: '',
    popOverPrompt: {
      showPopOverPrompt: false,
      popOverPromptText: '',
      storage: {
        check: 'exists',
        method: 'session',
        name: 'carerPrompt',
        value: 1,
      },
    },
    popupArgs: {},
  };
};
/* ignore jslint end */

export const modalConfig = (labels) => ({
  modaltitle: labels['labels.deviceRegReview.modalConfig.modaltitle'],
  size: 'xl',
  yes: labels['labels.deviceRegReview.modalConfig.yes'],
  no: labels['labels.deviceRegReview.modalConfig.no'],
  yesanalytics: 'yes_exit_register_device',
  noanalytics: 'no_exit_register_device',
  modalText: labels['labels.deviceRegReview.modalConfig.modalText'],
  onSuccess: () => {},
  onClose: () => {},
});

export const personalData = (
  selectedDevice,
  earSide,
  selectedSurgeon,
  surgeryDate,
  clinicsPopupData = { name: '', address: '' },
) => {
  return {
    datePicker: {
      value: surgeryDate,
      permission: 'rw',
    },
    description: {
      value: selectedDevice.description,
      permission: 'rw',
    },
    surgeryDate: {
      value: selectedDevice.surgeryDate,
      permission: 'rw',
    },
    earSide: {
      value: earSide,
      permission: 'rw',
    },
    surgeon: {
      value: selectedSurgeon,
      permission: 'rw',
    },
    surgeryCentre: {
      value: selectedDevice.surgeryCentre,
      permission: 'rw',
    },
    productId: {
      value: selectedDevice.id,
      permission: 'rw',
    },
    productFamily: {
      value: selectedDevice.subFamily,
      permission: 'rw',
    },
    productName: {
      value: selectedDevice.productName,
      permission: 'rw',
    },
    model: {
      value: selectedDevice.partNumber,
      permission: 'rw',
    },
    deviceType: {
      value: selectedDevice.type,
      permission: 'rw',
    },
    deviceTypeLabel: {
      value: selectedDevice.deviceTypeLabel,
      permission: 'rw',
    },

    serialNumber: {
      value: selectedDevice.serialNumber,
      permission: 'rw',
    },
    lotNumber: {
      value: selectedDevice.lotNumber,
      permission: 'rw',
    },
    clinicName: {
      value: clinicsPopupData.name,
      permission: 'rw',
    },
    clinicAddress: {
      value: clinicsPopupData.address,
      permission: 'rw',
    },
    patientName: {
      value: selectedDevice.patientName,
      permission: 'rw',
    },
    patientDob: {
      value: selectedDevice.dob,
      permission: 'rw',
    },
    patientCochlearId: {
      value: selectedDevice.cochlearId,
      permission: 'rw',
    },
    surgeonName: {
      value: selectedSurgeon,
      permission: 'rw',
    },
    partNumber: {
      value: selectedDevice.partNumber,
      permission: 'rw',
    },
    isBackupOrConsignment: {
      value: selectedDevice.isBackupOrConsignment,
      permission: 'rw',
    },
  };
};
