import {
  defaultBrowserLanguage,
  supportedLanguages,
  supportedLanguagesLoginFlow,
} from '../config';

const loginFlowPages = [
  'select-clinic',
  'authorize',
  'initialize',
  'error',
];

const fallbackLanguages = { 'en-CA': 'en-US' };

const longTextLanguages = ['de', 'fr', 'it', 'nl'];
const shortTextLanguages = ['zh', 'ja', 'ko'];

export const getDefaultLanguage = () => {
  const currentPage = window.location.pathname
    .split('/')
    .filter(Boolean)
    .pop();

  const validLanguages = loginFlowPages.includes(currentPage)
    ? supportedLanguagesLoginFlow
    : supportedLanguages;

  const chosenLanguage =
    navigator?.language ||
    localStorage.getItem('browserLang') ||
    defaultBrowserLanguage;

  let appLanguage = 'en-GB'; // global fallback language

  // check if chosen language directly matches a valid supported language
  if (validLanguages.includes(chosenLanguage)) return chosenLanguage;

  // check if it needs to fallback to a specific language variation. i.e, en-CA to en-US
  if (chosenLanguage in fallbackLanguages)
    return fallbackLanguages[chosenLanguage];

  // find the default language variation to fallback to. i.e de* to de-DE
  const languageCode = chosenLanguage.split('-')[0].toLowerCase();
  validLanguages.forEach((lang) => {
    if (lang.toLowerCase().startsWith(languageCode))
      appLanguage = lang;
  });

  return appLanguage;
};

export const getBrowserLanguageInfo = () => {
  const browserLanguage = navigator?.language ?? 'en-GB';
  const browserLanguageCode = browserLanguage.slice(0, 2);
  return {
    fullLanguage: browserLanguage,
    languageCode: browserLanguageCode,
  };
};

export const getLanguageCategoryClass = (languageCode) => {
  let category = '';

  if (longTextLanguages.includes(languageCode)) {
    category = 'text-long';
  } else if (shortTextLanguages.includes(languageCode)) {
    category = 'text-short';
  } else {
    category = 'text-medium'; // Default category if not found
  }

  return `${category} ${languageCode}`;
};
