import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from '@cochlear-design-system/foundation';
import AsyncNotificationWidget from '../AsyncNotificationWidget';
import providerService from '../../services/providerService';
import { getLabelsForFields } from '../../utils/labelHelper';
import {
  DPX_OPTOUT_COOKIE,
  SHARED_MENU_COOKIE,
  routes,
} from '../../config';
import cookieService from '../../services/cookieService';
import Redirect from '../Redirect';

const MainContentBlock = ({ rendering }) => {
  const labels = getLabelsForFields(rendering.fields);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [error, setError] = useState(query.get('error'));
  const [message, setMessage] = useState();
  const currentProvider = providerService.getCurrentProvider();
  const flagDisabled = currentProvider.redirectFlag === 'DISABLED';

  useEffect(() => {
    if (error === 'provider') {
      cookieService.setCookie(
        DPX_OPTOUT_COOKIE,
        true,
        Infinity,
        true,
      );
      cookieService.setCookie(SHARED_MENU_COOKIE, false, 0, true);
      setMessage(labels['labels.common.mcpFeaturesError']);
    }
  }, [error]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin !== routes.mcp.domain) return;
      if (typeof e.data === 'object') {
        if ('pageEvent' in e.data) {
          switch (e.data.pageEvent.type) {
            case 'providerError':
              if (e.data.pageEvent.target === 'dashboard') {
                providerService.updateProviderDetails(
                  'hasProviderError',
                  true,
                );
                setError('provider');
              }
              break;
            default:
              break;
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setError('');
    }, 30000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className="ccl__container dpx__main-content">
      {error && message && (
        <Alert
          actions={[]}
          dismissible
          heading=""
          onClose={() => {
            setError('');
          }}
          timestamp=""
        >
          {message}
        </Alert>
      )}
      {error === 'provider' && !flagDisabled && (
        <Redirect to={routes.mcp.home} />
      )}
      <AsyncNotificationWidget rendering={rendering} />
    </div>
  );
};

export default MainContentBlock;
