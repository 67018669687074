/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFeature } from 'flagged';
import { useLocation, useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import { DeviceRegistrationReviewWrapper } from '../../ui/DeviceRegistrationReviewWrapper/DeviceRegistrationReviewWrapper.js';
import { routes } from '../../config';
import {
  config as adaptiveFormConfig,
  modalConfig as modalConfiguration,
  personalData,
} from './deviceRegistrationReviewConfig';
import { getDeviceType, mapRegistrationFormData } from './functions';
import providerService from '../../services/providerService';
import userService from '../../services/userService';
import fetchProviderService from '../../services/deviceRegistration/fetchProviderService';
import fetchProfessionalsService from '../../services/deviceRegistration/fetchProfessionalsService';
import deviceRegistrationService from '../../services/deviceRegistration/deviceRegistrationService';
import cookieService from '../../services/cookieService';
import headerCancelModal from '../../actions/headerCancelModalAction';
import notificationStatus from '../../actions/notificationStatusAction';
import { NOTIFICATION_IS_QUEUED } from '../../actions/types';
import { getLabelsForFields } from '../../utils/labelHelper';
import {
  formatDate,
  getDateInputFormat,
} from '../../utils/dateTimeFns';
import { logger } from '../../logger';
import { getMergedAddress } from '../../utils/addressHelper';

const log = logger();

const DeviceRegistrationReview = ({ rendering, country, lang }) => {
  const history = useHistory();
  // const [searchTerm] = useState([]);
  const currentProvider = useState(
    providerService.getCurrentProvider(),
  )[0];
  const userCountry = currentProvider?.address?.countryCode || 'US';

  const uDetails = userService.getUserDetails();
  const deviceRegistrationReviewWrapperRef = useRef();
  const dispatch = useDispatch();
  const modalTrigger = useSelector(
    (payload) => payload.headerCancelModal,
  );
  const datePickerFormatter = 'dd mmm yyyy';
  const labels = getLabelsForFields(rendering.fields);
  const modalConfig = modalConfiguration(labels);
  const providerCountry = currentProvider.address?.countryCode;
  const { format: dateInputFormat, placeholder: datePlaceholder } =
    getDateInputFormat(providerCountry, true);

  useEffect(() => {
    if (modalTrigger) {
      deviceRegistrationReviewWrapperRef.current.setModalState(true);
      dispatch(headerCancelModal(false));
    }
  }, [modalTrigger]);

  const getLoggedInClinicInfo = (currProvider) => {
    return {
      id: currProvider.id,
      name: currProvider.name,
      address: getMergedAddress(currProvider),
      isRemovable: true,
      providerId: currProvider.id,
    };
  };
  const currentClinic = getLoggedInClinicInfo(currentProvider);
  const [clinicsPopUpData, setClinicsPopUpData] = useState([
    currentClinic,
  ]);
  const location = useLocation();
  const state = useState(location.state)[0];
  const deviceType = getDeviceType(state.selectedDevice.type);
  const { isLotControlled } = state.selectedDevice;
  let updatePersonalData = useState(
    personalData(
      state.selectedDevice,
      '',
      '',
      '',
      getLoggedInClinicInfo(currentProvider),
    ),
  )[0];
  // keeping this list in state causes an extra rerender that breaks the clinic section
  // const [surgeonList, setSurgeonList] = useState([]);
  let surgeonList = [];
  let isSubmitting = false;
  let surgeryCenterUpdated = false;

  const redirectToPatientRecordNoMessage = () => {
    window.location.href = `${routes.dpx.patientDetails}?patientId=${state.selectedDevice.cochlearId}`;
  };
  modalConfig.onSuccess = redirectToPatientRecordNoMessage;

  const redirectToDeviceRegistration = () => {
    const tempState = {
      patientName: `${state.selectedDevice.patientName}`,
      dob: state.selectedDevice.dob,
      cochlearId: state.selectedDevice.cochlearId,
      hasExistingDevice: state.selectedDevice.hasExistingDevice,
    };
    history.push({
      pathname: `${routes.dpx.deviceRegistration}`,
      state: { ...state, ...tempState },
    });
  };

  const searchClinics = async (searchParam) => {
    const countryCode = searchParam.country || userCountry;
    const searchResultList =
      await fetchProviderService.searchForProvider(
        searchParam,
        countryCode,
      );
    return searchResultList;
  };

  const selectClinic = async (action, params) => {
    const tempSelectedClinicData = [];
    tempSelectedClinicData.push({
      id: params.data.id,
      name: params.data.name,
      address: params.data.address,
      providerId: params.data.providerId,
      isRemovable: true,
    });
    setClinicsPopUpData(tempSelectedClinicData);
    cookieService.setCookie(
      'selectedClinic',
      JSON.stringify(tempSelectedClinicData[0]),
    );

    // eslint-disable-next-line
    updateDataTable(tempSelectedClinicData, '0', 'default-clinic');
    surgeryCenterUpdated = true;
  };

  const removeclinics = (removeItems) => {
    const index = clinicsPopUpData.indexOf(
      clinicsPopUpData.filter((x) => x.id === removeItems.id)[0],
    );
    // note: this will NOT trigger the useEffect hook, since no new array is created
    const tempSelectedClinicData = clinicsPopUpData;
    tempSelectedClinicData.splice(index, 1);
    setClinicsPopUpData(tempSelectedClinicData);

    // eslint-disable-next-line
    updateDataTable(tempSelectedClinicData, '0', 'add-clinic');
  };

  async function loadSurgeons(selectedProviderId) {
    let formattedResultSet = [];
    const surgeonListResult =
      await fetchProfessionalsService.getProfessionalList(
        currentProvider.id,
        deviceType,
        selectedProviderId,
      );
    if (
      surgeonListResult &&
      surgeonListResult.professionals &&
      surgeonListResult.professionals.length > 0
    ) {
      formattedResultSet = surgeonListResult.professionals.map(
        (surgeon) => {
          return {
            label: `${surgeon.firstName} ${surgeon.lastName}`,
            value: `${surgeon.id}`,
          };
        },
      );
    }
    formattedResultSet = formattedResultSet.sort(
      (professionalCompare1, professionalCompare2) =>
        professionalCompare1.label?.localeCompare(
          professionalCompare2.label,
        ),
    );
    return formattedResultSet;
  }

  const handleSuccessfulRegistration = async (selectedClinic) => {
    let formattedResultSet = [];

    updatePersonalData.datePickerFormatted = {
      value: formatDate(
        dateFormat(
          new Date(updatePersonalData.datePicker.value),
          'yyyy-mm-dd',
        ),
      ),
    };

    updatePersonalData.datePicker.value = dateFormat(
      new Date(updatePersonalData.datePicker.value),
      datePickerFormatter,
    );

    if (updatePersonalData.patientDob.value !== '--') {
      updatePersonalData.patientDob.value = formatDate(
        new Date(updatePersonalData.patientDob.value),
      );
    }
    if (
      deviceType.toUpperCase() === 'IMPLANT' ||
      deviceType.toUpperCase() === 'SPEECH_PROCESSOR'
    ) {
      if (surgeonList?.length === 0) {
        formattedResultSet = await loadSurgeons(
          selectedClinic[0]?.providerId,
        );
      } else {
        formattedResultSet = surgeonList;
      }

      updatePersonalData.surgeon.value = formattedResultSet.filter(
        (surgeon) => {
          return surgeon.value === updatePersonalData.surgeon.value;
        },
      )[0]?.label;
    }
    history.replace({
      pathname: `/${country}/${lang}/${routes.dpx.deviceRegistrationSuccess}`,
      state: { ...updatePersonalData, ...state },
    });
  };

  const handleFailedRegistration = async (selectedClinic) => {
    // eslint-disable-next-line
    const tempConfig = { ...config.config };
    if (
      deviceType.toUpperCase() === 'IMPLANT' ||
      deviceType.toUpperCase() === 'SPEECH_PROCESSOR'
    ) {
      let formattedResultSet = [];
      if (surgeonList?.length === 0) {
        formattedResultSet = await loadSurgeons(
          selectedClinic[0]?.providerId,
          deviceType,
        );
      } else {
        formattedResultSet = surgeonList;
      }
      tempConfig.steps[0].conditions[0].views[2].edit.elements[2].dataList =
        formattedResultSet;
    }
    // eslint-disable-next-line
    setConfig({
      labels,
      config: { ...tempConfig, routerLocation: () => {} },
      data: {
        adaptiveForms: {
          personal: updatePersonalData,
          clincsPopupData: selectedClinic,
        },
      },
      modalConfig,
    });
  };

  const onRegistrationSubmitHandle = async () => {
    if (!isSubmitting) {
      isSubmitting = true;
      let selectedClinic;
      try {
        const loggedInClinic = getLoggedInClinicInfo(currentProvider);
        if (
          updatePersonalData.clinicName.value === '' &&
          updatePersonalData.clinicAddress.value === ''
        ) {
          updatePersonalData.clinicName.value = loggedInClinic.name;
          updatePersonalData.clinicAddress.value =
            loggedInClinic.address;
        }

        if (
          surgeryCenterUpdated &&
          cookieService.getCookie('selectedClinic') !== undefined
        ) {
          selectedClinic = [
            JSON.parse(cookieService.getCookie('selectedClinic')),
          ];
          updatePersonalData.clinicName.value =
            selectedClinic[0]?.name;
          updatePersonalData.clinicAddress.value =
            selectedClinic[0]?.address;
        } else {
          selectedClinic = clinicsPopUpData;
        }

        if (typeof selectedClinic === 'undefined') {
          selectedClinic = clinicsPopUpData;
        }

        const submitRegistrationPayload = mapRegistrationFormData(
          selectedClinic[0]?.providerId,
          updatePersonalData,
          state.selectedDevice,
          uDetails,
          userCountry,
          deviceType,
        );
        const submitDeviceRegistration =
          await deviceRegistrationService.postDeviceRegistration(
            currentProvider.id,
            submitRegistrationPayload,
          );
        if (submitDeviceRegistration !== null) {
          // please add following code after calling api successfully.
          // it is required in order to trigger notification api call after form submitted.
          dispatch(notificationStatus(NOTIFICATION_IS_QUEUED));
          await handleSuccessfulRegistration(selectedClinic);
        } else {
          await handleFailedRegistration(selectedClinic);
        }
      } catch (error) {
        log.error('Error in device reg submission: ', error);
        await handleFailedRegistration(selectedClinic);
      } finally {
        isSubmitting = false;
      }
    }
  };

  let defaultScenario;
  if (
    deviceType === 'REMOTE_ASSISTANT' ||
    deviceType === 'ACCESSORY'
  ) {
    defaultScenario = 'accessory-registration';
  } else {
    defaultScenario = clinicsPopUpData?.length
      ? 'default-clinic'
      : 'add-clinic';
  }
  const defaultConfig = {
    labels,
    config: {
      ...adaptiveFormConfig(
        '',
        false,
        0,
        defaultScenario,
        // eslint-disable-next-line
        onFormDataChangedHandle,
        searchClinics,
        removeclinics,
        selectClinic,
        onRegistrationSubmitHandle,
        surgeonList,
        deviceType,
        redirectToDeviceRegistration,
        deviceRegistrationReviewWrapperRef,
        userCountry,
        dateInputFormat,
        datePlaceholder,
        labels,
        isLotControlled,
      ),
      routerLocation: () => {},
    },
    data: {
      adaptiveForms: {
        personal: updatePersonalData,
        clincsPopupData: clinicsPopUpData,
      },
    },
    modalConfig,
  };
  const [config, setConfig] = useState(defaultConfig);

  function onFormDataChangedHandle(elements) {
    updatePersonalData = personalData(
      state.selectedDevice,
      elements.earSide,
      elements.surgeon,
      elements.datePicker,
      clinicsPopUpData[0],
    );
  }

  // note: this hook will only trigger, if clinicsPopUpData is replaced with a new array
  // in case of removing a clinic, the original array is not change, only its data removed
  // hence this hook will not run
  useEffect(() => {
    const updateSurgeons = async () => {
      const surgeons = await loadSurgeons(clinicsPopUpData[0].id);
      surgeonList = surgeons;

      const tempConfig = {
        ...config.config,
        routerLocation: () => {},
      };
      tempConfig.steps[0].conditions[0].views[2].edit.elements[2].dataList =
        surgeons;
      tempConfig.activeScenario = defaultScenario;

      setConfig({
        labels,
        config: tempConfig,
        data: {
          adaptiveForms: {
            personal: updatePersonalData,
            clincsPopupData: clinicsPopUpData,
          },
        },
        modalConfig,
      });
    };

    if (clinicsPopUpData?.length > 0) {
      updateSurgeons();
    }
  }, [clinicsPopUpData]);

  async function updateDataTable(
    clinicsPopupData,
    activeStep,
    activeScenario,
  ) {
    defaultScenario = activeScenario;

    const personalInfo = personalData(
      state.selectedDevice,
      '',
      '',
      '',
      getLoggedInClinicInfo(currentProvider),
    );
    surgeonList = [];
    const adaptiveFormConfigUpdated = adaptiveFormConfig(
      '',
      false,
      activeStep,
      activeScenario,
      onFormDataChangedHandle,
      searchClinics,
      removeclinics,
      selectClinic,
      onRegistrationSubmitHandle,
      surgeonList,
      deviceType,
      redirectToDeviceRegistration,
      deviceRegistrationReviewWrapperRef,
      userCountry,
      dateInputFormat,
      datePlaceholder,
      labels,
      isLotControlled,
    );
    if (activeScenario === 'add-clinic') {
      setConfig({
        labels,
        config: {
          ...adaptiveFormConfigUpdated,
          routerLocation: () => {},
        },
        data: {
          adaptiveForms: {
            personal: personalInfo,
            clincsPopupData: clinicsPopupData,
          },
        },
        modalConfig,
      });
    } else {
      const tempConfig = { ...config.config };
      tempConfig.steps[0].conditions[0].views[2].edit.elements[2].dataList =
        [];
      setConfig({
        labels,
        config: { ...tempConfig, routerLocation: () => {} },
        data: {
          adaptiveForms: {
            personal: personalInfo,
            clincsPopupData: clinicsPopupData,
          },
        },
        modalConfig,
      });
    }
  }
  return (
    config && (
      <div>
        <div id="main-content">
          <DeviceRegistrationReviewWrapper
            {...config}
            ref={deviceRegistrationReviewWrapperRef}
          />
        </div>
      </div>
    )
  );
};

export default withFeature('featureEnabler/deviceRegistration')(
  DeviceRegistrationReview,
);
