import axios from 'axios';
import dateFormat from 'dateformat';
import { providerListEndpoint } from '../config';
import getHeaders from '../utils/getHeaders';
import store from '../store';
import createPatientAPIErrorModal from '../actions/createPatientAPIErrorAction';
import { logger } from '../logger';

const log = logger();

const getPatient = async (providerId, patientId, customHeaders) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}/${providerId}/patients/${patientId}`,
      getHeaders(customHeaders),
    );
    return response.data;
  } catch (err) {
    log.error('Error in getPatient', err.response);
    return err.response ? err.response : null;
  }
};

const getPatientCarers = async (
  providerId,
  patientId,
  customHeaders,
) => {
  try {
    const { data } = await axios.get(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/carers`,
      getHeaders(customHeaders),
    );
    return data;
  } catch (err) {
    log.error('Error in getPatientCarers', err.response);
    return null;
  }
};

const getPatientAssociatedClinics = async (
  providerId,
  patientId,
  customHeaders,
) => {
  try {
    const {
      data: { providers },
    } = await axios.get(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/associated-clinics`,
      getHeaders(customHeaders),
    );
    return providers;
  } catch (err) {
    log.error('Error in getPatientAssociatedClinics', err.response);
    return null;
  }
};

const getPatientDevices = async (
  providerId,
  professionalId,
  patientId,
  customHeaders,
) => {
  try {
    const { data } = await axios.get(
      // `${providerListEndpoint}/${providerId}/patients/${patientId}/devices`,
      `${providerListEndpoint}/${providerId}/professional/${professionalId}/patients/${patientId}/devices`,
      getHeaders(customHeaders),
    );
    return data ?? [];
  } catch (err) {
    log.error('Error in getPatientDevices', err.response);
    return null;
  }
};

const getDeviceExpandedData = async (
  providerId,
  patientId,
  assetId,
) => {
  try {
    const { data } = await axios.get(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/devices/${assetId}`,
      getHeaders(),
    );
    return data ?? [];
  } catch (err) {
    log.error('Error in getDeviceExpandedData', err.response);
    return null;
  }
};

const associateWithClinic = async (
  patientId,
  providerId,
  organisationType,
  associatingClinicId = undefined,
  isCached = false,
) => {
  const requestBody = {
    patient: {
      cochlearId: patientId,
    },
    organisation: [
      {
        cochlearId: associatingClinicId ?? providerId,
        organisationType,
      },
    ],
  };
  const endpoint = isCached
    ? `${providerListEndpoint}/${providerId}/patients/orgrelationshipic`
    : `${providerListEndpoint}/${providerId}/patients/orgrelationship`;
  try {
    const response = await axios.post(
      endpoint,
      requestBody,
      getHeaders(),
    );
    store.dispatch(createPatientAPIErrorModal(false));
    return response.data;
  } catch (err) {
    if (err.response?.status === 403) {
      return { status: 403 };
    }
    store.dispatch(createPatientAPIErrorModal(true));

    log.error('Error in associateWithClinic', err.response);
    return null;
  }
};

const associateWithClinicCached = async (
  patientId,
  providerId,
  organisationType,
) => {
  return associateWithClinic(
    patientId,
    providerId,
    organisationType,
    undefined,
    true,
  );
};

const sendUar = async (
  providerId,
  patientId,
  cochlearId,
  email,
  type,
  preferredLanguage,
  countryCode,
  resendInvite,
) => {
  const requestBody = {
    // TODO: determine, if country needs to be added or not, for now language is mocked to include country
    // preferredLanguage: `${preferredLanguage}-${countryCode}`,
    preferredLanguage: `${preferredLanguage}`,
    resendInvite,
  };
  if (type === 'pt') {
    requestBody.patientCochlearId = cochlearId;
    if (!resendInvite) {
      requestBody.patientEmail = email;
    }
  } else if (type === 'cr') {
    requestBody.patientCochlearId = patientId;
    requestBody.carerCochlearId = cochlearId;
    if (!resendInvite) {
      requestBody.carerEmail = email;
    }
  }
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/uar`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in sendUar', err.response);
    return null;
  }
};

const markPatientAsDeceased = async (providerId, patientId) => {
  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/deceased`,
      {},
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in markPatientAsDeceased', err.response);
    return null;
  }
};

const upliftPatient = async (
  providerId,
  patientId,
  organisationId,
  organisationType,
  firstName,
  lastName,
  persona = 'Candidate',
  role = 'CURRENT_CENTRE',
) => {
  const requestBody = {
    persona,
    organisationId,
    organisationType,
    role,
    firstName,
    lastName,
  };

  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/uplift`,
      requestBody,
      getHeaders(),
    );
    store.dispatch(createPatientAPIErrorModal(false));
    return response.data;
  } catch (err) {
    store.dispatch(createPatientAPIErrorModal(true));

    log.error('Error in upliftPatient', err.response);
    return null;
  }
};

const checkExistingRecord = async (requestBody, providerId) => {
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/duplicate-detection`,
      requestBody,
      getHeaders(),
    );
    store.dispatch(createPatientAPIErrorModal(false));
    return response.data;
  } catch (err) {
    store.dispatch(createPatientAPIErrorModal(true));

    log.error('Error in checkExistingRecord', err.response);
    return null;
  }
};

const createPatient = async (
  provider,
  professionalId,
  countryCode,
  values,
  clinicData,
) => {
  try {
    const providerId = provider?.id;
    store.dispatch(createPatientAPIErrorModal(false));
    const date = new Date(
      +values.year,
      +values.month - 1,
      +values.day,
    );
    const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');

    const requestBody = {
      patient: {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth,
        email: values.email,
        mobile: values.phoneNumber,
        primaryCountryCode: countryCode,
        language: values.preferredLanguage,
      },
      professional: {
        cochlearId: professionalId,
      },
    };
    if (values.middleName) {
      requestBody.patient.middleName = values.middleName;
    }
    // if (clinicData.length > 0) {
    //   requestBody.moreOrganisations = [clinicData[0].clinicId];
    // }

    if (clinicData?.length > 0) {
      requestBody.moreOrganisations = clinicData.map((clinic) => {
        return {
          cochlearId: clinic.clinicId,
          organisationType: clinic.isDistributor
            ? 'Distributor'
            : 'Provider',
        };
      });
    }

    requestBody.organisation = {
      cochlearId: providerId,
      organisationType: provider.isDistributor
        ? 'Distributor'
        : 'Provider',
    };

    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    store.dispatch(createPatientAPIErrorModal(true));

    log.error('Error in createPatient', err.response);
    return null;
  }
};

const createPatientCarer = async (
  provider,
  professionalId,
  countryCode,
  values,
  clinicData,
  matchingCarerSelected,
  matchingPatientSelected,
  ismatchingRecipientSelected,
  ismatchingCarerSelected,
) => {
  try {
    const providerId = provider?.id;
    store.dispatch(createPatientAPIErrorModal(false));
    const requestBody = {};
    if (ismatchingRecipientSelected) {
      requestBody.patient = {
        cochlearId: matchingPatientSelected.cochlearId,
      };
    } else {
      const date = new Date(
        +values.year,
        +values.month - 1,
        +values.day,
      );
      const dateOfBirth = dateFormat(date, 'yyyy-mm-dd');
      requestBody.patient = {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth,
        primaryCountryCode: countryCode,
        language: values.preferredLanguage,
      };
    }
    if (values.middleName && !ismatchingRecipientSelected) {
      requestBody.patient.middleName = values.middleName;
    }

    if (ismatchingCarerSelected) {
      requestBody.carer = {
        cochlearId: matchingCarerSelected.cochlearId,
      };
    } else {
      requestBody.carer = {
        firstName: values.firstNameCarer,
        lastName: values.lastNameCarer,
        email: values.email,
        mobile: values.phoneNumber,
        primaryCountryCode: countryCode,
        language: values.preferredLanguage,
      };
    }
    if (values.middleNameCarer && !ismatchingCarerSelected) {
      requestBody.carer.middleName = values.middleNameCarer;
    }
    if (
      values.yearCarer &&
      values.monthCarer &&
      values.dayCarer &&
      !ismatchingCarerSelected
    ) {
      const dateCarer = new Date(
        +values.yearCarer,
        +values.monthCarer - 1,
        +values.dayCarer,
      );
      const dateOfBirthCarer = dateFormat(dateCarer, 'yyyy-mm-dd');
      requestBody.carer.dateOfBirth = dateOfBirthCarer;
    }

    // if (clinicData?.length > 0) {
    //   requestBody.moreOrganisations = [clinicData[0].clinicId];
    // }

    if (clinicData?.length > 0) {
      requestBody.moreOrganisations = clinicData.map((clinic) => {
        return {
          cochlearId: clinic.clinicId,
          organisationType: clinic.isDistributor
            ? 'Distributor'
            : 'Provider',
        };
      });
    }

    requestBody.organisation = {
      cochlearId: providerId,
      organisationType: provider.isDistributor
        ? 'Distributor'
        : 'Provider',
    };

    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    store.dispatch(createPatientAPIErrorModal(true));

    log.error('Error in createPatientCarer', err.response);
    return null;
  }
};

const checkAccountStatus = async (requestBody, providerId) => {
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/check-account-status`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in checkAccountStatus', err.response);
    return null;
  }
};

const updatePatient = async (requestBody, providerId, patientId) => {
  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in updatePatient', err.response);
    throw err;
  }
};

const checkUpliftStatus = async (
  providerId,
  patientId,
  requestId,
  cancelToken,
) => {
  const requestBody = {
    requestId,
  };

  const confObj = getHeaders();
  confObj.cancelToken = cancelToken;
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/uplift/status`,
      requestBody,
      confObj,
    );
    store.dispatch(createPatientAPIErrorModal(false));
    return response.data;
  } catch (err) {
    log.error('Error in checkUpliftStatus', err.response);
    return null;
  }
};

const pollUpliftStatus = async (
  professionalProviderId,
  patientId,
  requestId,
  cancelToken,
) => {
  const maxAttempts = 30; // Maximum number of attempts (3 minutes with ~6-second intervals). no-cache may take around 5 sec
  let attempt = 0;
  let status = '';
  /* eslint-disable no-await-in-loop */
  while (attempt < maxAttempts) {
    const response = await checkUpliftStatus(
      professionalProviderId,
      patientId,
      requestId,
      cancelToken,
    );

    if (
      response === null ||
      response?.upliftStatus?.status !== 'processed' ||
      response?.candidateOrgAssociationStatus?.status !== 'processed'
    ) {
      await new Promise((resolve) => setTimeout(resolve, 5000)); // additional 5 sec wait on top of api call which takes approx 1 sec
      attempt += 1;
    } else {
      status = 'processed';
      break;
    }
  }
  return status;
};

const addCarer = async (providerId, patientId, carerId) => {
  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/carers/${carerId}`,
      {},
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in addCarer', err.response);
    throw err;
  }
};

const updateEarSide = async (
  earSide,
  deviceId,
  professionalId,
  patientId,
  providerId,
  changeOrigin = 'SFHC',
  channel = 'DPX',
) => {
  const requestBody = {
    asset: {
      earSide,
      metadata: {
        modifiedBy: professionalId,
        changeOrigin,
        channel,
      },
    },
  };

  try {
    const response = await axios.patch(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/devices/${deviceId}`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in updateEarSide', err.response);
    return null;
  }
};

const updateAccessory = async (
  payload,
  assetId,
  providerId,
  patientId,
) => {
  const requestBody = payload;

  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/${patientId}/update-accessory/${assetId}`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error in update accessory', err.response);
    return null;
  }
};

const patientService = {
  getPatient,
  getPatientCarers,
  getPatientAssociatedClinics,
  getPatientDevices,
  getDeviceExpandedData,
  associateWithClinic,
  associateWithClinicCached,
  sendUar,
  markPatientAsDeceased,
  upliftPatient,
  checkExistingRecord,
  createPatient,
  createPatientCarer,
  checkAccountStatus,
  updatePatient,
  checkUpliftStatus,
  pollUpliftStatus,
  addCarer,
  updateEarSide,
  updateAccessory,
};

export default patientService;
