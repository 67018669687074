import { deviceType } from '../config';

/* eslint-disable no-else-return */
export const getLabelsForFields = (fields) => {
  if (!fields) return {};

  const { content, ...specific } = fields;

  // content objects
  const contentLabels = content
    ? content
        .map((item) => {
          return getLabelsForFields(item.fields);
        })
        .reduce((r, c) => Object.assign(r, c), {})
    : [];

  // specific labels
  const specificLabels = Object.fromEntries(
    Object.entries(specific).map(([key, value]) => {
      return [key, value.value];
    }),
  );

  return { ...contentLabels, ...specificLabels };
};

export const getTranslatedProductType = (productType, labels) => {
  if (
    productType.toLowerCase() === deviceType.SoundProcessor ||
    productType.toLowerCase() === deviceType.SpeechProcessor
  ) {
    return labels['labels.common.deviceType.soundProcessor'];
  }
  if (productType.toLowerCase() === deviceType.Implant) {
    return labels['labels.common.deviceType.implant'];
  }
  if (productType.toLowerCase() === deviceType.Accessory) {
    return labels['labels.common.deviceType.accessory'];
  }
  return productType;
};
