/* eslint-disable no-irregular-whitespace */
import {
  clinicCountries,
  clinicCountriesFull,
} from '../data/clinicCountries';

export const getFindClinicModalConfig = (defaultCountry, labels) => ({
  countryField: {
    isVisible: true,
    label: labels['labels.findClinicModal.countryField.label'],
    promptText:
      labels['labels.findClinicModal.countryField.promptText'],
    name: labels['labels.findClinicModal.countryField.name'],
    flagIcon: true,
    defaultValue: defaultCountry,
    dataList: clinicCountriesFull, // used by the cds adaptive form associatedClinicPopup which is used by device registtration
    countryList: clinicCountries, // used by new FindClinicModal
  },
  cinicField: {
    // although it's a typo (cinicField) it is still used by the cds adaptive form associatedClinicPopup which is used by device registration
    hint: labels['labels.findClinicModal.clinicField.hint'],
    label: labels['labels.findClinicModal.clinicField.label'],
    maxItems: 6,
    recentSearches: [],
    recentSearchesLabel:
      labels[
        'labels.findClinicModal.clinicField.recentSearchesLabel'
      ],
    validationMask: /^[\p{L}\p{N}\d'\- *%.　]+$/gu,
    validations: [
      {
        message: labels['labels.findClinicModal.noSpecialCharacters'],
        type: 'specialCharacters',
      },
    ],
  },
  clinicField: {
    // used by new FindClinicModal
    minLength: 3,
    hint: labels['labels.findClinicModal.clinicField.hint'],
    label: labels['labels.findClinicModal.clinicField.label'],
    maxItems: 6,
    recentSearches: [],
    recentSearchesLabel:
      labels[
        'labels.findClinicModal.clinicField.recentSearchesLabel'
      ],
    validationMask: /^[\p{L}\p{N}\d'\- *%.　]+$/gu,
    validations: [
      {
        message: labels['labels.findClinicModal.noSpecialCharacters'],
        type: 'specialCharacters',
      },
    ],
  },
  searchButton: {
    defaultLabel:
      labels['labels.findClinicModal.searchButton.defaultLabel'],
    processingLabel:
      labels['labels.findClinicModal.searchButton.processingLabel'],
    validations: [
      {
        type: 'specialCharacters',
        message: labels['labels.findClinicModal.noSpecialCharacters'],
      },
    ],
  },
  results: {
    maxCount: 20,
  },
  loadingView: {
    label: labels['labels.findClinicModal.loadingView.label'],
  },
  errorView: {
    title: labels['labels.findClinicModal.errorView.title'],
    message: labels['labels.findClinicModal.errorView.message'],
  },
  modalConfig: {
    modaltitle: labels['labels.findClinicModal.title'],
    size: 'xl',
  },
  noResultsView: {
    title: labels['labels.findClinicModal.noResultsView.title'],
    message: labels['labels.findClinicModal.noResultsView.message'],
  },
  ContactCustomerServiceView: {
    // needed for adaptive form
    title: labels['labels.findClinicModal.ccsView.title'],
    message: labels['labels.findClinicModal.ccsView.message'],
  },
  contactCustomerServiceView: {
    title: labels['labels.findClinicModal.ccsView.title'],
    message: labels['labels.findClinicModal.ccsView.message'],
  },
  maxResultsView: {
    title: labels['labels.findClinicModal.maxRestulsView.title'],
    message: labels['labels.findClinicModal.maxRestulsView.message'],
  },
  scrollable: true,
  tableConfig: {
    clickableRow: true,
    columns: [
      {
        display:
          'labels.findClinicModal.clinicsTable.columnHeaders.name',
        field: 'name',
        format: {
          type: 'string',
        },
        sortable: false,
      },
      {
        default: 'asc',
        display:
          'labels.findClinicModal.clinicsTable.columnHeaders.address',
        field: 'address',
        format: {
          type: 'string',
        },
        sortable: false,
        syncSort: 'orderNumberOrderDate',
      },
    ],
    events: {
      onLoadMore: () => {},
      onSort: () => {},
      closeModal: () => {
        const modalOverlay =
          document.getElementsByClassName('modal show')[0];
        modalOverlay.click();
      },
    },
    formats: {
      currency: 'USD',
      date: 'mmm dd yyyy',
    },
    handleSortDataChange: () => {},
    highlight: false,
    hover: true,
    lazyLoadOptions: {
      loadMoreText: 'labels.common.empty',
      pageSize: 20,
    },
    loadingText: 'labels.common.loadingText',
    // mode: 'lazy',
    noSearchResults: {
      additional: {
        function1: () => {},
        function2: () => {},
        linkText1:
          'labels.dataTable.noSearchResults.additional.linkText1',
        linkText2:
          'labels.dataTable.noSearchResults.additional.linkText2',
        text1: 'labels.dataTable.noSearchResults.additional.text1',
        text2: 'labels.dataTable.noSearchResults.additional.text2',
        textOrder:
          'labels.dataTable.noSearchResults.additional.textOrder',
      },
      content:
        'labels.findClinicModal.clinicsTable.noSearchResults.content',
      format: 'labels.dataTable.noSearchResults.format',
      isClinicPopup: true,
      pictogramName: '404Error2',
      pictogramSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
    },
    resultsHeadingFormat:
      'labels.findClinicModal.clinicsTable.resultsHeadingFormat',
    resultsLimit: {
      additional: {
        function1: () => {},
        linkText1:
          'labels.dataTable.resultsLimit.additional.linkText1',
        text1:
          'labels.findClinicModal.clinicsTable.resultsLimit.additional.text1',
        text2:
          'labels.findClinicModal.clinicsTable.resultsLimit.additional.text2',
        textOrder:
          'labels.findClinicModal.clinicsTable.resultsLimit.additional.textOrder',
      },
      content: 'labels.findClinicModal.maxRestulsView.title',
      maxResults: 20,
      pictogramName: '404Error2',
      pictogramSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
    },
  },
});
