/* eslint-disable dot-notation */

export const getFooterConfig = (labels) => {
  return {
    links: {
      targetItem: {
        children: [
          {
            label: 'contactUs',
            type: {
              linkType: 'external',
              url: 'https://www.cochlear.com/au/en/connect/contact-us',
            },
            analytics: 'view_contactus_footer',
          },
          {
            label: 'privacy',
            type: {
              linkType: 'external',
              url: 'https://www.cochlear.com/corporate/privacy/en',
            },
            analytics: 'view_privacy_footer',
          },
          {
            label: 'termsOfUse',
            type: {
              linkType: 'external',
              url: 'http://www.cochlear.com/connected-care/terms',
            },
            analytics: 'view_terms_footer',
          },
        ],
      },
    },
    caseTypes: [
      {
        label: labels['portalIssue'],
        value: 'Portal issue',
        supported: false,
      },
      {
        label: labels['deviceAppSupport'],
        value: 'Device or app support',
        supported: true,
      },
      {
        label: labels['orderStatus'],
        value: 'Order status',
        supported: true,
      },
      {
        label: labels['accountLoginSupport'],
        value: 'Account or login support',
        supported: true,
      },
      {
        label: labels['surgicalSupport'],
        value: 'Surgical support',
        supported: false,
      },
      {
        label: labels['researchSupport'],
        value: 'Research support',
        supported: false,
      },
      {
        label: labels['remoteCheck'],
        value: 'Remote check',
        supported: false,
      },
      {
        label: labels['somethingElse'],
        value: 'Something else',
        supported: true,
      },
    ],
    channel: 'DPX',
  };
};
