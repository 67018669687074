import React from 'react';
import { Text } from '@cochlear-design-system/foundation';
import { getDefaultLanguage } from '../../utils/patientCarerHelper';
import { getDobFormatted } from '../../utils/dateTimeFns';
import { getLanguageList } from '../../data/langaugeList';

const DetailsView = ({
  values,
  labels,
  type = 'recipient',
  showLanguage = false,
  showContactDetails = false,
}) => {
  const name =
    type === 'recipient'
      ? `${values.firstName} ${values.middleName || ''} ${
          values.lastName
        }`
      : `${values.firstNameCarer} ${values.middleNameCarer || ''} ${
          values.lastNameCarer
        }`;
  const dob =
    type === 'recipient'
      ? getDobFormatted(values.day, values.month, values.year)
      : getDobFormatted(
          values.dayCarer,
          values.monthCarer,
          values.yearCarer,
        );
  const language = showLanguage
    ? getLanguageList().find(
        (l) => l.value === values.preferredLanguage,
      ) || getDefaultLanguage
    : '';

  return (
    <>
      <div className="mt-3 ccl__flex">
        <Text
          content={labels['labels.common.name']}
          type="body-text-bold"
          className="label-spacing"
        />
        <Text content={name} />
      </div>
      <div className="mt-2 ccl__flex">
        <Text
          content={labels['labels.common.dateOfBirth']}
          type="body-text-bold"
          className="label-spacing"
        />
        <Text content={dob || '--'} />
      </div>
      {showContactDetails && (
        <>
          <div className="mt-2 ccl__flex">
            <Text
              content={labels['labels.common.email']}
              type="body-text-bold"
              className="label-spacing"
            />
            <Text content={values.email || '--'} />
          </div>
          <div className="mt-2 ccl__flex">
            <Text
              content={labels['labels.common.phone']}
              type="body-text-bold"
              className="label-spacing"
            />
            <Text content={values.phoneNumber || '--'} />
          </div>
        </>
      )}
      {showLanguage && (
        <div className="mt-2 ccl__flex">
          <Text
            content={labels['labels.common.language']}
            type="body-text-bold"
            className="label-spacing"
          />
          <Text content={language?.label} />
        </div>
      )}
    </>
  );
};

export default DetailsView;
