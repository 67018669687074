import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import cookieService from './cookieService';
import {
  routes,
  providerListEndpoint,
  PROVIDER_DETAILS_COOKIE,
  cpdTtl,
} from '../config';
import providerDetails from '../actions/providerDetailsAction';
import getHeaders from '../utils/getHeaders';
import { setIdpParam } from '../utils/authprovider';
import store from '../store';
import createPatientAPIErrorModal from '../actions/createPatientAPIErrorAction';
import { logger } from '../logger';
import { regionMap } from '../data/regionMap';
import localStorageService from './localStorageService';

const log = logger();

const getProviderDetails = () => {
  const dispatch = useDispatch();

  // Get the providers details from state
  const pDetails = useSelector((state) => state.providerDetails);
  if (pDetails) return pDetails;

  // If not in state, get from cookie
  const cookieContent = cookieService.getCookie(
    PROVIDER_DETAILS_COOKIE,
  );
  if (cookieContent) {
    const providerDetailsData = cookieContent;

    // store in state
    dispatch(providerDetails(providerDetailsData));
    return providerDetailsData;
  }

  return null;
};

const getCachedProviders = () => {
  // get item from local storage with honourExpiry set to true
  // i.e the local storage item will be deleted if it's expired
  return localStorageService.getItem(PROVIDER_DETAILS_COOKIE, true);
};

const searchClinics = async (countryCode, tradingName) => {
  try {
    store.dispatch(createPatientAPIErrorModal(false));
    const response = await axios.get(
      `${providerListEndpoint}/search?countryCode=${countryCode}&tradingName=${tradingName}`,
      getHeaders(),
    );

    return response.data;
  } catch (err) {
    log.error('Error searching clinics', err);
    store.dispatch(createPatientAPIErrorModal(true));
    return null;
  }
};

const getProviderListBackend = async (
  professionalId,
  professionalCountry,
) => {
  try {
    const response = await axios.get(
      `${providerListEndpoint}?professionalCountry=${professionalCountry}&professionalId=${professionalId}`,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error getting provider list', err);
    return null;
  }
};

const getSearchResult = async (
  providerId,
  searchParams,
  page,
  pageSize,
) => {
  const requestBody = {
    query: {
      name: searchParams.name,
      dateOfBirth: searchParams.dateOfBirth,
      serialNumber: searchParams.deviceSerial,
    },
  };

  try {
    const response = await axios.post(
      `${providerListEndpoint}/${providerId}/patients/search?page=${page}&limit=${pageSize}`,
      requestBody,
      getHeaders(),
    );
    return response;
  } catch (err) {
    log.error('Error getting search result', err);
    return err.response;
  }
};

const locatePatient = async (params) => {
  const requestBody = params;

  try {
    const response = await axios.post(
      `${providerListEndpoint}/${params.relatedProfessionalProviderId}/patients/advanced-search`,
      requestBody,
      getHeaders(),
    );
    return response.data;
  } catch (err) {
    log.error('Error locating patient', err);
    return null;
  }
};

const checkClinics = async (professionalId, professionalCountry) => {
  let next = null;
  let hasClinic = true;
  const providerId = null;

  const providerList = await getProviderListBackend(
    professionalId,
    professionalCountry,
  );

  if (!providerList) {
    next = routes.dpx.error;

    return {
      next,
      providerList,
      hasClinic,
      providerId,
    };
  }

  if (providerList.providers.length === 0) {
    hasClinic = false;
    next = setIdpParam(routes.mcp.home);

    return {
      next,
      providerList,
      hasClinic,
      providerId,
    };
  }

  // check if country redirect flag is disabled
  const flagDisabled =
    providerList.providers[0]?.redirectFlag === 'DISABLED';

  if (!flagDisabled) {
    // filter clinics with no partyId (only if flag is on)
    const providersFiltered = providerList.providers.filter(
      (provider) => {
        return provider.partyId;
      },
    );

    providerList.providers = providersFiltered;
  }

  const providerCount = providerList.providers.length;

  switch (providerCount) {
    case 0:
      // no providers
      hasClinic = false;
      next = setIdpParam(routes.mcp.home);
      break;
    /* 
      case 1:
      // only one provider
      providerId = providerList.providers[0].id;
       app = providerList.providers[0].redirectFlag;
      next =
        app === 'MCP'
          ? setIdpParam(
              `${routes.mcp.clinic}${providerList.providers[0].partyId}`,
            )
          : routes.dpx.selectClinics;
      break;
      */
    default:
      // multiple providers
      next = routes.dpx.selectClinics;
      break;
  }

  return {
    next,
    providerList,
    hasClinic,
    providerId,
  };
};

const getCurrentProvider = () => {
  const pDetails = getProviderDetails();
  const chosenProvider = pDetails.providerId;
  const currentProvider = pDetails.providerList.providers.filter(
    (provider) => provider.id === chosenProvider,
  )[0];
  return currentProvider || {};
};

const getCurrentPartyId = () => {
  return getCurrentProvider().partyId;
};

const getCurrentCId = () => {
  return getCurrentProvider().id;
};

const storeProviderDetails = (providerId, providerList) => {
  const providerDetailsData = {
    hasProviderError: false,
    hasClinic: true,
    providerId,
    providerList,
  };
  localStorageService.setItem(
    PROVIDER_DETAILS_COOKIE,
    providerDetailsData,
    cpdTtl,
  );
  return providerDetailsData;
};

const updateProviderDetails = (key, value) => {
  localStorageService.updateItem(PROVIDER_DETAILS_COOKIE, {
    [key]: value,
  });
};

const getProviderRegion = (providerCountryCode) => {
  const region = regionMap.find(
    (r) => r.countryCode === providerCountryCode,
  );
  return region?.region;
};

const providerService = {
  getProviderDetails,
  getProviderListBackend,
  getSearchResult,
  checkClinics,
  getCurrentPartyId,
  getCurrentCId,
  getCurrentProvider,
  locatePatient,
  searchClinics,
  storeProviderDetails,
  updateProviderDetails,
  getProviderRegion,
  getCachedProviders,
};

export default providerService;
