import React from 'react';
import { Alert, Text } from '@cochlear-design-system/foundation';
import { getCustomerSupportMessage } from '../../../utils/messageHelper';

const AlertComponent = ({ step, labels }) => {
  const alertConfig = {
    c2a: {
      variant: 'warning',
      heading:
        labels[
          'labels.createPatient.matchingRecordsRecipient.heading'
        ],
      message:
        labels[
          'labels.createPatient.matchingRecordsRecipientMessage'
        ],
    },
    c4a: {
      variant: 'warning',
      heading:
        labels['labels.createPatient.matchingRecordsCarer.heading'],
      message:
        labels['labels.createPatient.matchingRecordsCarerMessage'],
    },
    c4b: {
      variant: 'warning',
      heading:
        labels['labels.createPatient.matchingRecordsCarer.heading'],
      message:
        labels['labels.createPatient.matchingRecordsCarerMessagePII'],
    },
    c3b: {
      variant: 'error',
      heading: labels['labels.createPatient.duplicateEmailTitle'],
      messageLink:
        labels['labels.createPatient.duplicateEmailMessageLink'],
      message: labels['labels.createPatient.duplicateEmailMessage'],
    },
    c3c: {
      variant: 'error',
      heading: '',
      message:
        labels[
          'labels.createPatient.matchingInformation.errorMessage'
        ],
    },
  };

  if (Object.prototype.hasOwnProperty.call(alertConfig, step)) {
    const { variant, heading, message, messageLink } =
      alertConfig[step];

    return step === 'c3b' ? (
      <Alert variant={variant} heading={heading}>
        <Text
          content={getCustomerSupportMessage(message, messageLink)}
          type="div"
        />
      </Alert>
    ) : (
      <section className="ccl-c-form-section mt-4">
        <Alert variant={variant} heading={heading}>
          {message}
        </Alert>
      </section>
    );
  }

  return null;
};

export default AlertComponent;
