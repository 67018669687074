import React from 'react';
import {
  TextInput,
  CheckboxInput,
} from '@cochlear-design-system/foundation';

const OptionalEmail = ({
  isChecked,
  emailAddress,
  handleChange,
  handleBlur,
  labels,
  touched,
  errors,
  toggleEmailProvided,
}) => {
  return (
    <>
      <TextInput
        disabled={isChecked}
        className="ccl-l-advanced-search-patient-tab__fields__email"
        name="email"
        value={emailAddress}
        onChange={handleChange}
        onBlur={handleBlur}
        label={labels['labels.common.emailAddress']}
        promptText={
          labels['labels.createPatient.emailInputPlaceholder']
        }
        hint={labels['labels.createPatient.emailMessage']}
        error={touched.email && !!errors.email}
        errorMsg={touched.email && errors.email}
      />
      <div className="mt-4">
        <CheckboxInput
          label={labels['labels.createPatient.noEmailCheckboxLabel']}
          name="noEmail"
          onChange={toggleEmailProvided}
          checked={isChecked}
        />
      </div>
    </>
  );
};

export default OptionalEmail;
