import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Modal,
  FormButton,
  Button,
  TextArea,
  SelectInput,
  Text,
  Alert,
} from '@cochlear-design-system/foundation';
import {
  DPX_BOUND_COOKIE,
  DPX_OPTOUT_COOKIE,
  routes,
} from '../../config';
import { Error as ErrorComponent } from '../../ui/Error/Error.js';
import createProfessionalCasesService from '../../services/createCases/createProfessionalCasesService.js';
import userService from '../../services/userService';
import providerService from '../../services/providerService';
import cookieService from '../../services/cookieService.js';

const CustomerContactModal = ({
  openModal,
  modalType,
  labels,
  res,
  onCloseClicked,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [professionalCase, setProfessionalCase] = useState(undefined);
  const [message, setMessage] = useState(labels.message);
  const [modalTitle, setModalTitle] = useState(
    labels.contactCustomerService,
  );
  const [modalSubmitBtn, setModalSubmitBtn] = useState(
    labels.sendEnquiry,
  );

  const webReasonDefaultValue =
    modalType === 'returnOldPortal' ? labels.returnOldPortal : '';

  const { isDistributor, id: providerId } =
    providerService.getCurrentProvider();

  const {
    firstName,
    lastName,
    username,
    countryCode,
    professionalId,
  } = userService.getUserDetails();

  const validationSchema = Yup.object().shape({
    webReason: Yup.string().required(labels.caseTypeRequiredError),
    description: Yup.string()
      .max(1000, labels.messageLimitError)
      .required(labels.messageRequiredError),
  });

  const initialValues = {
    webReason: '',
    description: '',
  };

  const organisation = isDistributor
    ? { distributor: { cochlearId: providerId } }
    : { provider: { cochlearId: providerId } };

  const initPayloadData = {
    professionalId,
    caseType: 'Customer Interaction',
    subject: 'DPX Contact us',
    countryCode,
    organisation,
    email: username,
    metadata: {
      modifiedBy: username,
      channel: res.channel,
    },
  };

  const handleFormSubmit = async (resetForm, submittedValues) => {
    const { webReason, description } = submittedValues;

    const isPortalIssue = webReason === 'Portal issue';
    const isMCPReturnRequest =
      webReason === 'Return to Legacy Portal';

    const logs = isPortalIssue
      ? sessionStorage.getItem('logStore') ?? null
      : null;

    const payload = {
      isPortalIssue,
      isMCPReturnRequest,
      webReason: res.caseTypes.filter(
        (type) => type.value === webReason,
      )[0].supported
        ? webReason
        : 'Something else',
      description: res.caseTypes.filter(
        (type) => type.value === webReason,
      )[0].supported
        ? description
        : `${webReason}\n${description}`,
      ...initPayloadData,
      logs,
    };
    const caseResult =
      await createProfessionalCasesService.createProfessionalCases(
        providerId,
        professionalId,
        payload,
      );

    if (webReason === labels.returnOldPortal) {
      cookieService.setCookie(DPX_OPTOUT_COOKIE, false, 60 * 8, true);
      cookieService.setCookie(DPX_BOUND_COOKIE, false, 60 * 8, true);
      window.location.href = routes.mcp.home;
    } else {
      setIsSubmitted(true);
      setProfessionalCase(caseResult);
      resetForm();
    }
  };

  const {
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit: async (submittedValues) =>
      handleFormSubmit(resetForm, submittedValues),
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const swapUILabels = (reason) => {
    if (reason === labels.returnOldPortal) {
      setMessage(labels.messageForOldPortal);
      setModalTitle(labels.returnOldPortal);
      setModalSubmitBtn(labels.returnOldPortalBtn);
    } else {
      setMessage(labels.message);
      setModalTitle(labels.contactCustomerService);
      setModalSubmitBtn(labels.sendEnquiry);
    }
  };

  useEffect(() => {
    resetForm();

    if (openModal) {
      setIsSubmitted(false);

      if (modalType === 'returnOldPortal') {
        setFieldValue('webReason', labels.returnOldPortal);
        swapUILabels(labels.returnOldPortal);
      } else {
        swapUILabels(null);
      }
    }
  }, [openModal]);

  const tryAgain = () => {
    setIsSubmitted(false);
  };

  const handleWebReasonChanged = (name, value) => {
    setFieldValue(name, value);
    swapUILabels(value);
  };

  const handleCloseClicked = () => {
    resetForm();
    onCloseClicked?.();
  };

  const RenderState = {
    submitSuccess: 0,
    submitFailed: 1,
    notSubmit: 2,
  };

  let renderState = RenderState.notSubmit;
  if (isSubmitted && professionalCase?.success) {
    renderState = RenderState.submitSuccess;
  } else if (isSubmitted && !professionalCase) {
    renderState = RenderState.submitFailed;
  }

  return (
    <div className="contact-customer-service-container">
      <Modal
        show={openModal}
        onHide={handleCloseClicked}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
        variant="primary"
      >
        <Modal.Header
          closeButton
          data-analytics="cancel_header_customer_case"
        >
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderState === RenderState.submitSuccess && (
            <Alert
              heading={labels.messageHasBeenSent}
              show
              variant="success"
            >
              <Text
                content={labels.messageSentSuccessOne}
                type="body-text"
              />
              <br />
              <Text
                content={labels.messageSentSuccessTwo.replace(
                  '[CASE_NUMBER]',
                  `${professionalCase.data.id}`,
                )}
                type="body-text"
              />
            </Alert>
          )}
          {renderState === RenderState.submitFailed && (
            <ErrorComponent
              config={{
                buttons: [
                  {
                    action: 'link-dpx-ccs',
                    icon: '',
                    link: '',
                    name: 'dpxccs',
                    text: `${labels.tryAgain}`,
                    useCallback: true,
                    variant: 'brand-primary',
                  },
                ],
                codeLabel: `${labels.errorMessageCodeLabel}`,
                handleCallback: () => {
                  tryAgain();
                },
                imgAlt: 'labels.errorMessage.imgAlt',
                imgSrc:
                  'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
                imgWidth: 116,
                text: 'labels.errorMessage.text',
                title: 'labels.errorMessage.title',
              }}
              data={{}}
              labels={{
                'labels.errorMessage.codeLabel': `${labels.errorMessageCodeLabel}`,
                'labels.errorMessage.imgAlt': `${labels.errorMessageImgAlt}`,
                'labels.errorMessage.text': `${labels.errorMessageBody}`,
                'labels.errorMessage.title': `${labels.errorMessageTitle}`,
              }}
            />
          )}
          {renderState === RenderState.notSubmit && (
            <div className="ccs-form-wrapper">
              <form onSubmit={handleSubmit} onChange={handleChange}>
                <div className="ccs-form-webReason-wrapper">
                  <SelectInput
                    dataList={res.caseTypes}
                    onChange={handleWebReasonChanged}
                    onBlur={handleBlur}
                    label={labels.reasonForContacting}
                    name="webReason"
                    defaultValue={webReasonDefaultValue}
                    promptText={labels.Select}
                    error={!!errors.webReason}
                    errorMsg={errors.webReason}
                    disabled={modalType === 'returnOldPortal'}
                  />
                </div>
                <div className="ccs-form-description-wrapper">
                  <TextArea
                    isFluid
                    label={message}
                    name="description"
                    stateManaged={false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.description && !!errors.description
                    }
                    errorMsg={errors.description}
                  />
                </div>
              </form>
              <div className="ccs-form-from-wrapper">
                <Text content={labels.from} type="body-text-bold" />
                <Text
                  content={`${firstName} ${lastName}`}
                  type="body-text"
                />
                <Text content={`${username}`} type="body-text" />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="ccs-modal-footer">
          {renderState !== RenderState.notSubmit && (
            <>
              <Button
                onClick={handleCloseClicked}
                text={labels.close}
                variant="secondary"
                data-analytics="close_customer_case"
              />
            </>
          )}
          {renderState === RenderState.notSubmit && (
            <>
              <FormButton
                onClick={handleSubmit}
                type="save"
                text={modalSubmitBtn}
                disabled={!dirty || !isValid || isSubmitting}
                progress={isSubmitting ? 2 : undefined}
                data-analytics="send_customer_case"
              />
              <Button
                onClick={handleCloseClicked}
                text={labels.cancel}
                variant="tertiary"
                data-analytics="cancel_customer_case"
              />
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerContactModal;
