export const countryCodeandLanguage = [
  { country: 'United Kingdom', language: 'en', code: 'GB' },
  {
    country: 'United Kingdom (Northern Ireland)',
    language: 'en',
    code: 'XI',
  },
  { country: 'Germany', language: 'de', code: 'DE' },
  { country: 'United States', language: 'en', code: 'US' },
  { country: 'Canada', language: 'en', code: 'CA' },
  { country: 'Denmark', language: 'da', code: 'DK' },
  { country: 'Spain', language: 'es', code: 'ES' },
  { country: 'Finland', language: 'fi', code: 'FI' },
  { country: 'Poland', language: 'pl', code: 'PL' },
  { country: 'Italy', language: 'it', code: 'IT' },
  { country: 'Portugal', language: 'pt', code: 'PT' },
  { country: 'Saudi Arabia', language: 'ar', code: 'SA' },
  { country: 'Sweden', language: 'sv', code: 'SE' },
  { country: 'Belgium', language: 'nl', code: 'BE' },
  { country: 'Netherlands', language: 'nl', code: 'NL' },
  { country: 'Norway', language: 'no', code: 'NO' },
  { country: 'Switzerland', language: 'de', code: 'CH' },
  { country: 'France', language: 'fr', code: 'FR' },
  { country: 'Austria', language: 'de', code: 'AT' },
  { country: 'Hungary', language: 'hu', code: 'HU' },
  { country: 'Czech Republic', language: 'cs', code: 'CZ' },
  { country: 'Malta', language: 'en', code: 'MT' },
  { country: 'Slovenia', language: 'sl', code: 'SI' },
  { country: 'Slovakia', language: 'sk', code: 'SK' },
  { country: 'Bulgaria', language: 'bg', code: 'BG' },
  { country: 'Croatia', language: 'hr', code: 'HR' },
  { country: 'Cyprus', language: 'el', code: 'CY' },
  { country: 'Estonia', language: 'et', code: 'EE' },
  { country: 'Greece', language: 'el', code: 'GR' },
  { country: 'Latvia', language: 'lv', code: 'LV' },
  { country: 'Lithuania', language: 'lt', code: 'LT' },
  { country: 'Serbia', language: 'sr', code: 'RS' },
  { country: 'Ukraine', language: 'uk', code: 'UA' },
  { country: 'Uzbekistan', language: 'uz', code: 'UZ' },
  { country: 'Romania', language: 'ro', code: 'RO' },
  { country: 'UAE', language: 'ar', code: 'AE' },
  { country: 'Kuwait', language: 'ar', code: 'KW' },
  { country: 'Qatar', language: 'ar', code: 'QA' },
  { country: 'Oman', language: 'ar', code: 'OM' },
  { country: 'Bahrain', language: 'ar', code: 'BH' },
  { country: 'Mauritania', language: 'ar', code: 'MR' },
  { country: 'Armenia', language: 'ru', code: 'AM' },
  { country: 'Belarus', language: 'ru', code: 'BY' },
  { country: 'Kazakhstan', language: 'kk', code: 'KZ' },
  { country: 'Kosovo, Republic of (t)', language: 'sr', code: 'XK' },
  { country: 'Russian Federation', language: 'ru', code: 'RU' },
  {
    country: 'Macedonia, The Former Yugoslav Republic of',
    language: 'sr',
    code: 'MK',
  },
  { country: 'Libya', language: 'ar', code: 'LY' },
  { country: 'Egypt', language: 'ar', code: 'EG' },
  { country: 'Morocco', language: 'ar', code: 'MA' },
  { country: 'Algeria', language: 'ar', code: 'DZ' },
  { country: 'Iraq', language: 'ar', code: 'IQ' },
  { country: 'Albania', language: 'sr', code: 'AL' },
  { country: 'Bosnia and Herzegovina', language: 'sr', code: 'BA' },
  { country: 'Kyrgyzstan', language: 'ru', code: 'KG' },
  { country: 'Israel', language: 'en', code: 'IL' },
  { country: 'Turkey', language: 'tr', code: 'TR' },
  { country: 'Iceland', language: 'en', code: 'IS' },
  { country: 'Luxembourg', language: 'fr', code: 'LU' },
  { country: 'Tunisia', language: 'ar', code: 'TN' },
  { country: 'Lebanon', language: 'ar', code: 'LB' },
  { country: 'Jordan', language: 'ar', code: 'JO' },
  { country: 'Kenya', language: 'en', code: 'KE' },
  { country: 'Nigeria', language: 'en', code: 'NG' },
  { country: 'Sudan', language: 'ar', code: 'SD' },
  { country: 'Syrian Arab Republic', language: 'ar', code: 'SY' },
  {
    country: 'Iran (Islamic Republic of)',
    language: 'fa',
    code: 'IR',
  },
  { country: 'Tajikistan', language: 'ru', code: 'TJ' },
  { country: 'Turkmenistan', language: 'ru', code: 'TM' },
  { country: 'Namibia', language: 'en', code: 'NA' },
];
