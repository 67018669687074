import React from 'react';
import {
  Modal,
  Text,
  Button,
  Alert,
  FormButton,
} from '@cochlear-design-system/foundation';
import { getDobFormatted } from '../../../utils/dateTimeFns';

const AgreementModal = ({
  labels,
  onSubmit: onSubmitProp,
  onClose,
  visible,
  isAgreementSubmitting,
  agreementConfig,
  carerInformation,
}) => (
  <Modal dialogClassName="agreement-modal" show={visible}>
    <Modal.Header
      closeButton
      onHide={onClose}
      data-analytics="cancel_header_select_existing_carer_modal"
    >
      <Modal.Title>{agreementConfig.agreementTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert variant="warning" heading={agreementConfig.alertTitle}>
        {agreementConfig.alertText}
      </Alert>
      <div className="agreement-modal__content">
        {agreementConfig.userType === 'recipient' ? (
          <Text content={agreementConfig.agreementBodyText} isHTML />
        ) : (
          agreementConfig.userType === 'carer' &&
          carerInformation && (
            <>
              <div className="mt-3 ccl__flex">
                <Text
                  content={labels['labels.common.name']}
                  type="body-text-bold"
                  className="label-spacing"
                />
                <Text
                  content={`${carerInformation.firstName} ${carerInformation.lastName}`}
                />
              </div>
              <div className="mt-2 ccl__flex">
                <Text
                  content={labels['labels.common.dateOfBirth']}
                  type="body-text-bold"
                  className="label-spacing"
                />
                <Text
                  content={
                    carerInformation.dob
                      ? getDobFormatted(
                          carerInformation.day,
                          carerInformation.month,
                          carerInformation.year,
                        )
                      : '--'
                  }
                />
              </div>
              <div className="mt-2 ccl__flex">
                <Text
                  content={labels['labels.common.email']}
                  type="body-text-bold"
                  className="label-spacing"
                />
                <Text content={`${carerInformation.email || '--'}`} />
              </div>
            </>
          )
        )}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="tertiary"
        onClick={onClose}
        text={agreementConfig.agreementCloseText}
        data-analytics="cancel_select_existing_carer_modal"
      />
      <FormButton
        variant="brand-primary"
        onClick={onSubmitProp}
        text={agreementConfig.agreementAccceptText}
        type="save"
        progress={isAgreementSubmitting ? 2 : undefined}
        data-analytics="confirm_select_existing_carer_modal"
      />
    </Modal.Footer>
  </Modal>
);

export default AgreementModal;
