import React from 'react';
import {
  TextInput,
  DateInput,
} from '@cochlear-design-system/foundation';

const PersonalDetails = ({
  values,
  handleChange,
  handleBlur,
  labels,
  touched,
  errors,
  dateFieldsConfig,
  dobErrorMessage,
  dateHint,
  type,
}) => {
  const isCarer = type === 'carer';

  return (
    <>
      <TextInput
        className="ccl-l-create-patient__fields__name"
        name={isCarer ? 'firstNameCarer' : 'firstName'}
        value={isCarer ? values?.firstNameCarer : values?.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={labels['labels.common.firstName']}
        promptText=""
        error={
          isCarer
            ? touched?.firstNameCarer && !!errors?.firstNameCarer
            : touched?.firstName && !!errors?.firstName
        }
        errorMsg={
          isCarer
            ? touched?.firstNameCarer && errors?.firstNameCarer
            : touched?.firstName && errors?.firstName
        }
      />

      <TextInput
        className="ccl-l-create-patient__fields__name"
        name={isCarer ? 'middleNameCarer' : 'middleName'}
        value={isCarer ? values?.middleNameCarer : values?.middleName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={labels['labels.common.middleName']}
        hint={labels['labels.common.optional']}
        promptText=""
        error={
          isCarer
            ? touched?.middleNameCarer && !!errors?.middleNameCarer
            : touched?.middleName && !!errors?.middleName
        }
        errorMsg={
          isCarer
            ? touched?.middleNameCarer && errors?.middleNameCarer
            : touched?.middleName && errors?.middleName
        }
      />

      <TextInput
        className="ccl-l-create-patient__fields__name"
        name={isCarer ? 'lastNameCarer' : 'lastName'}
        value={isCarer ? values?.lastNameCarer : values?.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        label={labels['labels.common.lastName']}
        promptText=""
        error={
          isCarer
            ? touched?.lastNameCarer && !!errors?.lastNameCarer
            : touched?.lastName && !!errors?.lastName
        }
        errorMsg={
          isCarer
            ? touched?.lastNameCarer && errors?.lastNameCarer
            : touched?.lastName && errors?.lastName
        }
      />

      <DateInput
        className="ccl-l-advanced-search-patient-tab__fields__dob"
        name={isCarer ? 'dateOfBirthCarer' : 'dateOfBirth'}
        isStrict
        label={`${labels['labels.common.dateOfBirth']}  ${
          isCarer ? labels['labels.common.optional'] : ''
        }`}
        hint={labels[dateHint]}
        inputMode="numeric"
        onChange={handleChange}
        onBlur={handleBlur}
        fields={dateFieldsConfig}
        error={!!dobErrorMessage}
        errorMsg={dobErrorMessage}
      />
    </>
  );
};

export default PersonalDetails;
