import { routes, appEnv } from '../../config';
import featureFlagService from '../../services/featureFlagService';
import { replaceTokens } from '../../utils/stringFns';
import { setIdpParam } from '../../utils/authprovider';

export const getConfig = () => {
  return {
    views: [
      {
        mask: {
          value: 'dd mmm yyyy',
        },
        id: {
          value: 'patientDetails',
        },
        title: {
          targetItem: {
            key: {
              key: 'labels.patientRecord.views.patientDetails.title',
            },
          },
        },
        fallbackTitle: {
          targetItem: {
            key: {
              key: 'labels.patientRecord.views.patientDetails.fallbackTitle',
            },
          },
        },
        type: {
          value: 'Form',
        },
        mode: {
          value: 'readWrite',
        },
        data: {
          value: 'patientDetailsView',
        },
        showAddIcon: {
          value: '',
        },
        showEditIcon: {
          value: '',
        },
        read: [
          {
            name: {
              value: 'read',
            },
            analytics: 'edit_view_patient',
            clkErrorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.patientDetails.read.clkErrorLabel',
                },
              },
            },
            errorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.patientDetails.read.errorLabel',
                },
              },
            },
            fieldLayout: {
              value: 'horizontal',
            },
            elements: [
              {
                id: 'dateOfBirth',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.patientDetails.read.elements.dateOfBirth',
                    },
                  },
                },
                value: {
                  // Note: do NOT fix and revert back to dateOfBirth -> adaptive form does some automatic date formatting we don't want
                  value:
                    '{{#dateOfBirthFormatted}}{{dateOfBirthFormatted}}{{/dateOfBirthFormatted}}{{^dateOfBirthFormatted}}--{{/dateOfBirthFormatted}}',
                },
              },
              {
                id: 'phone',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.patientDetails.read.elements.phone',
                    },
                  },
                },
                value: {
                  value:
                    '{{#phoneNumber}}{{phoneNumber}}{{/phoneNumber}}{{^phoneNumber}}--{{/phoneNumber}}',
                },
              },
              {
                id: 'email',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.patientDetails.read.elements.email',
                    },
                  },
                },
                value: {
                  value:
                    "{{#email}}<a href='mailto:{{email}}' title='{{email}}' data-analytics='mailto_patient_view_patient'>{{email}}</a>{{/email}}{{^email}}--{{/email}}",
                },
              },
              {
                id: 'address',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.patientDetails.read.elements.address',
                    },
                  },
                },
                value: {
                  value:
                    '{{#street1}}  \t{{street1}} {{street2}} <br/>  {{/street1}}  {{^street1}}  \t{{#street2}}  \t\t{{street2}} <br/>  \t{{/street2}}  {{/street1}}  {{#city}}  \t{{city}} <br /> {{/city}}  {{#state}}  \t{{state}} {{postcode}} <br /> {{/state}}  {{^state}}  \t{{#postcode}}  \t\t {{postcode}} <br /> \t{{/postcode}}  {{/state}}  {{#country}}  \t{{country}}  {{/country}}',
                },
              },
              {
                id: 'accountDetails',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.patientDetails.read.elements.accountDetails',
                    },
                  },
                },
                value: {
                  value:
                    "{{accountDetails}} {{accountLinkSeparator}} <a href='#' class='ccl__email-verified'>{{emailVerified}}</a>",
                },
              },
            ],
          },
        ],
        edit: [
          {
            edit: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.patientDetails.edit.edit',
                },
              },
            },
            editIcon: {
              value: '',
            },
            analytics: 'edit_view_patient',
          },
        ],
        create: [],
      },
      {
        mask: {
          value: 'dd mmm, yyyy',
        },
        id: {
          value: 'associatedCarersView',
        },
        title: {
          targetItem: {
            key: {
              key: 'labels.patientRecord.views.associatedCarersView.title',
            },
          },
        },
        fallbackTitle: {
          targetItem: null,
        },
        type: {
          value: 'List',
        },
        mode: {
          value: 'readWrite',
        },
        data: {
          value: 'carersData',
        },
        showGlobalAdd: {
          value: '1',
        },
        showAddIcon: {
          value: '1',
        },
        showEditIcon: {
          value: '',
        },
        read: [
          {
            name: {
              value: 'read',
            },
            analytics: 'add_carer_view_patient',
            clkErrorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedCarersView.read.clkErrorLabel',
                },
              },
            },
            errorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedCarersView.read.errorLabel',
                },
              },
            },
            fieldLayout: {
              value: 'horizontal',
            },
            elements: [
              {
                id: 'name',
                label: {
                  targetItem: null,
                },
                value: {
                  value:
                    '<a href="javascript:void(0)" id="cr_view_{{accountId}}" data-id="{{accountId}}">{{firstName}} {{lastName}}</a>',
                },
              },
              {
                id: 'phone',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.associatedCarersView.read.elements.phone',
                    },
                  },
                },
                value: {
                  value:
                    '{{#phoneNumber}}{{phoneNumber}}{{/phoneNumber}}{{^phoneNumber}}--{{/phoneNumber}}',
                },
              },
              {
                id: 'email',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.associatedCarersView.read.elements.email',
                    },
                  },
                },
                value: {
                  value:
                    "{{#email}}<a href='mailto:{{email}}' title='{{email}}' data-analytics='mailto_carer_view_patient'>{{email}}</a>{{/email}}{{^email}}--{{/email}}",
                },
              },
              {
                id: 'address',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.associatedCarersView.read.elements.address',
                    },
                  },
                },
                value: {
                  value:
                    '{{#street1}}  \t{{street1}} {{street2}} <br/>  {{/street1}}  {{^street1}}  \t{{#street2}}  \t\t{{street2}} <br/>  \t{{/street2}}  {{/street1}}  {{#city}}  \t{{city}} <br /> {{/city}}  {{#state}}  \t{{state}} {{postcode}} <br /> {{/state}}  {{^state}}  \t{{#postcode}}  \t\t {{postcode}} <br /> \t{{/postcode}}  {{/state}}  {{#country}}  \t{{country}}  {{/country}}',
                },
              },
              {
                id: 'accountDetails',
                label: {
                  targetItem: {
                    key: {
                      key: 'labels.patientRecord.views.associatedCarersView.read.elements.accountDetails',
                    },
                  },
                },
                value: {
                  value:
                    "{{accountDetails}} {{accountLinkSeparator}} <a href='#' class='ccl__email-verified'>{{emailVerified}}</a>",
                },
              },
            ],
          },
        ],
        edit: [
          {
            edit: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.carers.add',
                },
              },
            },
            editIcon: {
              value: '',
            },
            analytics: 'add_carer_view_patient',
          },
        ],
        create: [
          {
            createLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedCarersView.create.createLabel',
                },
              },
            },
            createIcon: {
              value: '',
            },
          },
        ],
      },
      {
        mask: {
          value: 'mmmm dd yyyy',
        },
        id: {
          value: 'associatedClinicsView',
        },
        title: {
          targetItem: {
            key: {
              key: 'labels.patientRecord.views.associatedClinicsView.title',
            },
          },
        },
        fallbackTitle: {
          targetItem: null,
        },
        type: {
          value: 'List',
        },
        mode: {
          value: 'readWrite',
        },
        showGlobalAdd: {
          value: '1',
        },
        data: {
          value: 'associatedClinicData',
        },
        showAddIcon: {
          value: '1',
        },
        showEditIcon: {
          value: '',
        },
        read: [
          {
            name: {
              value: 'read',
            },
            analytics: 'add_clinic_view_patient',
            clkErrorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedClinicsView.read.clkErrorLabel',
                },
              },
            },
            errorLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedClinicsView.read.errorLabel',
                },
              },
            },
            fieldLayout: {
              value: '',
            },
            elements: [
              {
                id: 'clinicName',
                label: {
                  targetItem: null,
                },
                value: {
                  value:
                    "<span class='ccl__text--body-text-bold'>{{clinicName}}</span>",
                },
              },
              {
                id: 'phone',
                label: {
                  targetItem: null,
                },
                value: {
                  value: '{{phoneNumber}}',
                },
              },
              {
                id: 'address',
                label: {
                  targetItem: null,
                },
                value: {
                  value:
                    '{{street1}} {{street2}} <br/> {{city}} {{state}} {{postcode}}<br/>{{country}}',
                },
              },
            ],
          },
        ],
        edit: [
          {
            analytics: 'add_clinic_view_patient',
          },
        ],
        create: [
          {
            createLabel: {
              targetItem: {
                key: {
                  key: 'labels.patientRecord.views.associatedClinicsView.create.createLabel',
                },
              },
            },
            createIcon: {
              value: '',
            },
          },
        ],
      },
    ],
  };
};
const getTaskbarConfig = (addEquipmentCallback) => {
  return {
    labels: {
      taskBarTitle:
        'labels.patientRecord.tabs.equipments.taskBar.title',
      summary: {
        switchText:
          'labels.patientRecord.tabs.equipments.all.switch.title',
        taskBarTitle:
          'labels.patientRecord.tabs.equipments.taskBar.summaryTitle',
      },
      allDevices: {
        switchText:
          'labels.patientRecord.tabs.equipments.summary.switch.title',
        taskBarTitle:
          'labels.patientRecord.tabs.equipments.taskBar.title',
      },
    },
    analytics: {
      summary: 'view_all_devices_view_patient',
      allDevices: 'view_summary_devices_view_patient',
    },
    button: {
      onClick: addEquipmentCallback,
      analytics: 'add_empty_devices_view_patient',
    },
  };
};

const getToolbarConfig = (
  labels,
  filterSoundProcessor,
  filterAccessories,
  filterImplants,
  resetFilters,
) => {
  return {
    resetDeviceFilters: resetFilters,
    defaultSelection: 'apps',
    items: [
      {
        type: 'breadcrumb',
        icon: '',
        sections: [
          {
            text: labels[
              `labels.patientRecord.tabs.equipments.toolBar.items.show`
            ],
            action: () => {},
          },
        ],
      },
      {
        type: 'filter-button',
        icon: 'apps',
        text: labels[
          `labels.patientRecord.tabs.equipments.toolBar.items.all`
        ],
        onHandle: resetFilters,
        analytics: 'filter_all_devices_view_patient',
      },
      {
        type: 'filter-button',
        icon: 'sound-processor',
        text: labels[
          `labels.patientRecord.tabs.equipments.toolBar.items.soundProcessors`
        ],
        onHandle: filterSoundProcessor,
        analytics: 'filter_soundprocessors_devices_view_patient',
      },
      {
        type: 'filter-button',
        icon: 'accessories',
        text: labels[
          `labels.patientRecord.tabs.equipments.toolBar.items.accessories`
        ],
        onHandle: filterAccessories,
        analytics: 'filter_accessories_devices_view_patient',
      },
      {
        type: 'filter-button',
        icon: 'implant',
        text: labels[
          `labels.patientRecord.tabs.equipments.toolBar.items.implants`
        ],
        onHandle: filterImplants,
        analytics: 'filter_implants_devices_view_patient',
      },
    ],
  };
};

const getTableConfig = (
  labels,
  patientId,
  displayModalCreateSR,
  // eslint-disable-next-line camelcase
  displayModalViewSR,
  fetchExpandedData,
  loadReportIssueModal,
  updateEarSide,
  toggleUpdateAccessoryModal,
) => {
  return {
    tableConfigRight: {
      country: 'US',
      language: 'en',
      formats: {
        date: 'mmm dd yyyy',
        currency: 'USD',
      },
      labels: {
        rowLoading:
          'labels.patientRecord.tabs.serviceRequests.view.loading',
      },
      highlight: false,
      handleSortDataChange: () => {},
      handleExpand: (rowData) => {
        fetchExpandedData(rowData);
      },
      columns: [
        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          field: 'toggle',
          format: {
            collapsed: {
              type: 'icontoggle',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 -8px; width:200%'></div>`,
            },
          },
          sortable: false,
        },

        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.right.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              <div style='display: flex; gap: 0.5rem; align-items: center '>
               <div style='width: 1.5rem; display: flex'>
                  {{#icon}}
                    {{#earLeft}}
                      <span class='ccl__icon ccl__icon--left ccl__icon--color--info ccl__icon--size--sm'></span>
                    {{/earLeft}}
                    {{^earLeft}}
                      <span class='ccl__icon ccl__icon--right ccl__icon--color--warning ccl__icon--size--sm'></span>
                    {{/earLeft}}
                  {{/icon}}
                </div>
                <div>
                  {{#current}}
                    <span class='ccl__text ccl__text--body-text-bold '>{{device.label}}</span>
                  {{/current}}
                  {{^current}}
                    <span class='ccl__text ccl__text--body-text'>{{device.label}}</span>
                  {{/current}}
                </div>
              </div>
              <div style='display: flex; gap: 0.5rem; align-items: center '>
                <div style='width: 1.5rem;'></div>
                {{#loaner}}
                  <div><em id='loaner-label'>{{device.status}}</em></div>
                {{/loaner}}
                {{^loaner}}
                  <div><em>{{device.status}}</em></div>
                {{/loaner}}
              </div>
                `,
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:150%;'><ul>
                {{#test.length}}
                  {{#test}}<li>{{.}}</li>{{/test}}
                {{/test.length}}
              <ul></div>`,
            },
          },
          sortable: false,
        },

        {
          // field: 'model',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.device.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              {{#current}}
                <div>
                  <span class='ccl__text ccl__text--interactive-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              {{^current}}
                <div>
                  <span class='ccl__text ccl__text--body-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              `,
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 25px 5px 0'>{{#expandedDataL}}{{#value}}<div style='padding:7px 12px 0 0;'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataL}}</div>
          `,
            },
          },
          sortable: false,
        },

        {
          field: 'warranty',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.header',
          format: {
            collapsed: {
              type: 'device.warranty',
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 0 5px 0'>{{#expandedDataR}}{{#value}}<div style='padding-top:7px'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataR}}</div>
            `,
            },
          },
          sortable: false,
        },

        {
          field: 'view',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          format: {
            collapsed: {
              type: 'contextMenu',
              items: [
                {
                  name: 'newServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.createServiceRequest`
                  ],
                  onclick(row) {
                    displayModalCreateSR(row?.deviceId);
                  },
                },
                {
                  name: 'viewServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.viewServiceRequest`
                  ],
                  onclick(row) {
                    const arrayTokens = [
                      {
                        key: `##___SERVICEREQUESTID___##`,
                        value: row?.serviceRequestId,
                      },
                    ];
                    displayModalViewSR(
                      setIdpParam(
                        `${replaceTokens(
                          routes.mcp.viewServiceRequestWithoutDomain,
                          arrayTokens,
                        )}${patientId}`,
                      ),
                    );
                  },
                },
                {
                  name: 'reportAnIssue',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.reportIssue`
                  ],
                  onclick: (row) => {
                    loadReportIssueModal(row);
                  },
                },
                {
                  name: 'print',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.print`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'edit',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                      ],
                    );
                  },
                },
                {
                  name: 'addDevice',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.addDevice`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'remove',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                      ],
                    );
                  },
                },
                {
                  name: 'assignEarLeft',
                  text: labels['labels.common.assignToLeftEar'],
                  onclick: (row) => {
                    updateEarSide('Left', row?.assetId);
                  },
                },
              ],
              variant: 'tinted',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px -20% 0 0;'></div>`,
            },
          },
          hiddenColumnLabel: true,
          sortable: false,
          restrictWidth: true, // restrict this cell from growing beyond the bounds of required cell content
        },
      ],
    },
    tableConfigLeft: {
      country: 'US',
      language: 'en',
      formats: {
        date: 'mmm dd yyyy',
        currency: 'USD',
      },
      labels: {
        rowLoading:
          'labels.patientRecord.tabs.serviceRequests.view.loading',
      },
      highlight: false,
      handleSortDataChange: () => {},
      handleExpand: (rowData) => {
        fetchExpandedData(rowData);
      },
      columns: [
        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          field: 'toggle',
          format: {
            collapsed: {
              type: 'icontoggle',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 -8px; width:200%'></div>`,
            },
          },
          sortable: false,
        },

        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.left.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              <div style='display: flex; gap: 0.5rem; align-items: center '>
               <div style='width: 1.5rem; display: flex'>
                  {{#icon}}
                    {{#earLeft}}
                      <span class='ccl__icon ccl__icon--left ccl__icon--color--info ccl__icon--size--sm'></span>
                    {{/earLeft}}
                    {{^earLeft}}
                      <span class='ccl__icon ccl__icon--right ccl__icon--color--warning ccl__icon--size--sm'></span>
                    {{/earLeft}}
                  {{/icon}}
                </div>
                <div>
                  {{#current}}
                    <span class='ccl__text ccl__text--body-text-bold '>{{device.label}}</span>
                  {{/current}}
                  {{^current}}
                    <span class='ccl__text ccl__text--body-text'>{{device.label}}</span>
                  {{/current}}
                </div>
              </div>
              <div style='display: flex; gap: 0.5rem; align-items: center '>
                <div style='width: 1.5rem;'></div>
                {{#loaner}}
                  <div><em id='loaner-label'>{{device.status}}</em></div>
                {{/loaner}}
                {{^loaner}}
                  <div><em>{{device.status}}</em></div>
                {{/loaner}}
              </div>
                `,
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:150%;'><ul>
                {{#test.length}}
                  {{#test}}<li>{{.}}</li>{{/test}}
                {{/test.length}}
              <ul></div>`,
            },
          },
          sortable: false,
        },

        {
          // field: 'model',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.device.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              {{#current}}
                <div>
                  <span class='ccl__text ccl__text--interactive-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              {{^current}}
                <div>
                  <span class='ccl__text ccl__text--body-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              `,
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 25px 5px 0'>{{#expandedDataL}}{{#value}}<div style='padding:7px 12px 0 0;'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataL}}</div>
          `,
            },
          },
          sortable: false,
        },

        {
          field: 'warranty',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.header',
          format: {
            collapsed: {
              type: 'device.warranty',
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 0 5px 0'>{{#expandedDataR}}{{#value}}<div style='padding-top:7px'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataR}}</div>
            `,
            },
          },
          sortable: false,
        },

        {
          field: 'view',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          format: {
            collapsed: {
              type: 'contextMenu',
              items: [
                {
                  name: 'newServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.createServiceRequest`
                  ],
                  onclick(row) {
                    displayModalCreateSR(row?.deviceId);
                  },
                },
                {
                  name: 'viewServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.viewServiceRequest`
                  ],
                  onclick(row) {
                    const arrayTokens = [
                      {
                        key: `##___SERVICEREQUESTID___##`,
                        value: row?.serviceRequestId,
                      },
                    ];
                    displayModalViewSR(
                      setIdpParam(
                        `${replaceTokens(
                          routes.mcp.viewServiceRequestWithoutDomain,
                          arrayTokens,
                        )}${patientId}`,
                      ),
                    );
                  },
                },
                {
                  name: 'reportAnIssue',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.reportIssue`
                  ],
                  onclick: (row) => {
                    loadReportIssueModal(row);
                  },
                },
                {
                  name: 'print',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.print`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'edit',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                      ],
                    );
                  },
                },
                {
                  name: 'addDevice',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.addDevice`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'remove',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                      ],
                    );
                  },
                },
                {
                  name: 'assignEarRight',
                  text: labels['labels.common.assignToRightEar'],
                  onclick: (row) => {
                    updateEarSide('Right', row?.assetId);
                  },
                },
              ],
              variant: 'tinted',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px -20% 0 0;'></div>`,
            },
          },
          hiddenColumnLabel: true,
          sortable: false,
          restrictWidth: true, // restrict this cell from growing beyond the bounds of required cell content
        },
      ],
    },
    tableConfigUnknown: {
      country: 'US',
      language: 'en',
      formats: {
        date: 'mmm dd yyyy',
        currency: 'USD',
      },
      labels: {
        rowLoading:
          'labels.patientRecord.tabs.serviceRequests.view.loading',
      },
      highlight: false,
      handleSortDataChange: () => {},
      handleExpand: (rowData) => {
        fetchExpandedData(rowData);
      },
      columns: [
        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          field: 'toggle',
          format: {
            collapsed: {
              type: 'icontoggle',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 -8px; width:200%'></div>`,
            },
          },
          sortable: false,
        },

        {
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.unknown.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              <div style='display: flex; gap: 0.5rem; align-items: center '>
               <div style='width: 1.5rem; display: flex'>
                  {{#icon}}
                    {{#earLeft}}
                      <span class='ccl__icon ccl__icon--left ccl__icon--color--info ccl__icon--size--sm'></span>
                    {{/earLeft}}
                    {{^earLeft}}
                      <span class='ccl__icon ccl__icon--right ccl__icon--color--warning ccl__icon--size--sm'></span>
                    {{/earLeft}}
                  {{/icon}}
                </div>
                <div>
                  {{#current}}
                    <span class='ccl__text ccl__text--body-text-bold '>{{device.label}}</span>
                  {{/current}}
                  {{^current}}
                    <span class='ccl__text ccl__text--body-text'>{{device.label}}</span>
                  {{/current}}
                </div>
              </div>
              <div style='display: flex; gap: 0.5rem; align-items: center '>
                <div style='width: 1.5rem;'></div>
                {{#loaner}}
                  <div><em id='loaner-label'>{{device.status}}</em></div>
                {{/loaner}}
                {{^loaner}}
                  <div><em>{{device.status}}</em></div>
                {{/loaner}}
              </div>
                `,
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:150%;'><ul>
                {{#test.length}}
                  {{#test}}<li>{{.}}</li>{{/test}}
                {{/test.length}}
              <ul></div>`,
            },
          },
          sortable: false,
        },

        {
          // field: 'model',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.device.header',
          format: {
            collapsed: {
              type: 'composite',
              template: `
              {{#current}}
                <div>
                  <span class='ccl__text ccl__text--interactive-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              {{^current}}
                <div>
                  <span class='ccl__text ccl__text--body-text'>{{model.name}}</span>
                </div>
                <div>
                  {{model.info}}
                </div>
              {{/current}}
              `,
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 25px 5px 0'>{{#expandedDataL}}{{#value}}<div style='padding:7px 12px 0 0;'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataL}}</div>
          `,
            },
          },
          sortable: false,
        },

        {
          field: 'warranty',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.header',
          format: {
            collapsed: {
              type: 'device.warranty',
            },
            expanded: {
              type: 'composite',
              template: `
              <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 0 5px 0'>{{#expandedDataR}}{{#value}}<div style='padding-top:7px'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataR}}</div>
            `,
            },
          },
          sortable: false,
        },

        {
          field: 'view',
          display:
            'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
          format: {
            collapsed: {
              type: 'contextMenu',
              items: [
                {
                  name: 'newServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.createServiceRequest`
                  ],
                  onclick(row) {
                    displayModalCreateSR(row?.deviceId);
                  },
                },
                {
                  name: 'viewServiceRequest',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.viewServiceRequest`
                  ],
                  onclick(row) {
                    const arrayTokens = [
                      {
                        key: `##___SERVICEREQUESTID___##`,
                        value: row?.serviceRequestId,
                      },
                    ];
                    displayModalViewSR(
                      setIdpParam(
                        `${replaceTokens(
                          routes.mcp.viewServiceRequestWithoutDomain,
                          arrayTokens,
                        )}${patientId}`,
                      ),
                    );
                  },
                },
                {
                  name: 'reportAnIssue',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.reportIssue`
                  ],
                  onclick: (row) => {
                    loadReportIssueModal(row);
                  },
                },
                {
                  name: 'print',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.print`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'edit',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                      ],
                    );
                  },
                },
                {
                  name: 'addDevice',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.addDevice`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.print`
                      ],
                    );
                  },
                },
                {
                  name: 'remove',
                  text: labels[
                    `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                  ],
                  onclick: () => {
                    // eslint-disable-next-line no-alert
                    alert(
                      labels[
                        `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                      ],
                    );
                  },
                },
                {
                  name: 'assignEarLeft',
                  text: labels['labels.common.assignToLeftEar'],
                  onclick: (row) => {
                    updateEarSide('Left', row?.assetId);
                  },
                },
                {
                  name: 'assignEarRight',
                  text: labels['labels.common.assignToRightEar'],
                  onclick: (row) => {
                    updateEarSide('Right', row?.assetId);
                  },
                },
              ],
              variant: 'tinted',
            },
            expanded: {
              type: 'composite',
              template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px -20% 0 0;'></div>`,
            },
          },
          hiddenColumnLabel: true,
          sortable: false,
          restrictWidth: true, // restrict this cell from growing beyond the bounds of required cell content
        },
      ],
    },
    country: 'US',
    language: 'en',
    formats: {
      date: 'mmm dd yyyy',
      currency: 'USD',
    },
    labels: {
      rowLoading:
        'labels.patientRecord.tabs.serviceRequests.view.loading',
    },
    highlight: false,
    handleSortDataChange: () => {},
    handleExpand: (rowData) => {
      fetchExpandedData(rowData);
    },
    columns: [
      {
        display:
          'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
        field: 'toggle',
        format: {
          collapsed: {
            type: 'icontoggle',
          },
          expanded: {
            type: 'composite',
            template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 -8px; width:200%'></div>`,
          },
        },
        sortable: false,
      },

      {
        display:
          'labels.patientRecord.tabs.equipments.dataTable.columns.device.header',
        format: {
          collapsed: {
            type: 'composite',
            template: `
            <div style='display: flex; gap: 0.5rem; align-items: center '>
             <div style='width: 1.5rem; display: flex'>
                {{#icon}}
                  {{#earLeft}}
                    <span class='ccl__icon ccl__icon--left ccl__icon--color--info ccl__icon--size--sm'></span>
                  {{/earLeft}}
                  {{^earLeft}}
                    <span class='ccl__icon ccl__icon--right ccl__icon--color--warning ccl__icon--size--sm'></span>
                  {{/earLeft}}
                {{/icon}}
              </div>
              <div>
                {{#current}}
                  <span class='ccl__text ccl__text--body-text-bold '>{{device.label}}</span>
                {{/current}}
                {{^current}}
                  <span class='ccl__text ccl__text--body-text'>{{device.label}}</span>
                {{/current}}
              </div>
            </div>
            <div style='display: flex; gap: 0.5rem; align-items: center '>
              <div style='width: 1.5rem;'></div>
              {{#loaner}}
                <div><em id='loaner-label'>{{device.status}}</em></div>
              {{/loaner}}
              {{^loaner}}
                <div><em>{{device.status}}</em></div>
              {{/loaner}}
            </div>
              `,
          },
          expanded: {
            type: 'composite',
            template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:150%;'><ul>
              {{#test.length}}
                {{#test}}<li>{{.}}</li>{{/test}}
              {{/test.length}}
            <ul></div>`,
          },
        },
        sortable: false,
      },

      {
        // field: 'model',
        display:
          'labels.patientRecord.tabs.equipments.dataTable.columns.model.header',
        format: {
          collapsed: {
            type: 'composite',
            template: `
            {{#current}}
              <div>
                <span class='ccl__text ccl__text--interactive-text'>{{model.name}}</span>
              </div>
              <div>
                {{model.info}}
              </div>
            {{/current}}
            {{^current}}
              <div>
                <span class='ccl__text ccl__text--body-text'>{{model.name}}</span>
              </div>
              <div>
                {{model.info}}
              </div>
            {{/current}}
            `,
          },
          expanded: {
            type: 'composite',
            template: `
            <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 25px 5px 0'>{{#expandedDataL}}{{#value}}<div style='padding:7px 12px 0 0;'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataL}}</div>
        `,
          },
        },
        sortable: false,
      },

      {
        field: 'warranty',
        display:
          'labels.patientRecord.tabs.equipments.dataTable.columns.warranty.header',
        format: {
          collapsed: {
            type: 'device.warranty',
          },
          expanded: {
            type: 'composite',
            template: `
            <div style='border-top: 1px solid #cfcfd0; margin: -14px 0 0 0; width:115%; padding: 8px 0 5px 0'>{{#expandedDataR}}{{#value}}<div style='padding-top:7px'><p class='ccl__text ccl__text--x-small-body-text-bold' style='display:inline'>{{label}}: </p> <p class='ccl__text ccl__text--x-small-body-text' style='display:inline'>{{value}}</p></div>{{/value}}{{/expandedDataR}}</div>
          `,
          },
        },
        sortable: false,
      },

      {
        field: 'view',
        display:
          'labels.patientRecord.tabs.equipments.dataTable.columns.blank.header',
        format: {
          collapsed: {
            type: 'contextMenu',
            items: [
              {
                name: 'newServiceRequest',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.createServiceRequest`
                ],
                onclick(row) {
                  displayModalCreateSR(row?.deviceId);
                },
              },
              {
                name: 'viewServiceRequest',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.viewServiceRequest`
                ],
                onclick(row) {
                  const arrayTokens = [
                    {
                      key: `##___SERVICEREQUESTID___##`,
                      value: row?.serviceRequestId,
                    },
                  ];
                  displayModalViewSR(
                    setIdpParam(
                      `${replaceTokens(
                        routes.mcp.viewServiceRequestWithoutDomain,
                        arrayTokens,
                      )}${patientId}`,
                    ),
                  );
                },
              },
              {
                name: 'reportAnIssue',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.reportIssue`
                ],
                onclick: (row) => {
                  loadReportIssueModal(row);
                },
              },
              {
                name: 'print',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.print`
                ],
                onclick: () => {
                  // eslint-disable-next-line no-alert
                  alert(
                    labels[
                      `labels.patientRecord.tabs.equipments.dataTable.options.print`
                    ],
                  );
                },
              },
              {
                name: 'edit',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                ],
                onclick: () => {
                  // eslint-disable-next-line no-alert
                  alert(
                    labels[
                      `labels.patientRecord.tabs.equipments.dataTable.options.edit`
                    ],
                  );
                },
              },
              {
                name: 'addDevice',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.addDevice`
                ],
                onclick: () => {
                  // eslint-disable-next-line no-alert
                  alert(
                    labels[
                      `labels.patientRecord.tabs.equipments.dataTable.options.print`
                    ],
                  );
                },
              },
              {
                name: 'remove',
                text: labels[
                  `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                ],
                onclick: () => {
                  // eslint-disable-next-line no-alert
                  alert(
                    labels[
                      `labels.patientRecord.tabs.equipments.dataTable.options.remove`
                    ],
                  );
                },
              },
              {
                name: 'assignEarLeft',
                text: labels['labels.common.assignToLeftEar'],
                onclick: (row) => {
                  updateEarSide('Left', row?.assetId);
                },
              },
              {
                name: 'assignEarRight',
                text: labels['labels.common.assignToRightEar'],
                onclick: (row) => {
                  updateEarSide('Right', row?.assetId);
                },
              },
              {
                name: 'replaceAccessory',
                text: labels[
                  'labels.patientRecord.tabs.equipments.dataTable.options.replaceAccessory'
                ],
                onclick: (row) => {
                  toggleUpdateAccessoryModal(true, row);
                },
              },
            ],
            variant: 'tinted',
          },
          expanded: {
            type: 'composite',
            template: `<div style='border-top: 1px solid #cfcfd0; margin: -14px -20% 0 0;'></div>`,
          },
        },
        hiddenColumnLabel: true,
        sortable: false,
        restrictWidth: true, // restrict this cell from growing beyond the bounds of required cell content
      },
    ],
  };
};
const getEmptyEquipmentConfig = (addEquipementCallback) => {
  return {
    isEmpty: true,
    isLoading: false,
    button: {
      onClick: addEquipementCallback,
      analytics: 'add_empty_devices_view_patient',
    },
  };
};

export const getSummaryConfig = (
  labels,
  selectedRecipientCId,
  patientId,
  displayModalCreateSR,
  // eslint-disable-next-line camelcase
  displayModalViewSR,
  fetchExpandedData,
  addEquipmentCallback,
  filterSoundProcessor,
  filterAccessories,
  filterImplants,
  resetFilters,
  loadReportIssueModal,
  updateEarSide,
  toggleUpdateAccessoryModal,
) => {
  const featureFlags = featureFlagService.getFeatureFlags();
  const tableConfig = getTableConfig(
    labels,
    patientId,
    displayModalCreateSR,
    displayModalViewSR,
    fetchExpandedData,
    loadReportIssueModal,
    updateEarSide,
    toggleUpdateAccessoryModal,
  );
  const toolbarConfig = getToolbarConfig(
    labels,
    filterSoundProcessor,
    filterAccessories,
    filterImplants,
    resetFilters,
  );
  const taskBarConfig = getTaskbarConfig(addEquipmentCallback);
  const emptyEquipment = getEmptyEquipmentConfig(
    addEquipmentCallback,
  );
  const rcBridgeUrl =
    appEnv === 'LOCAL'
      ? '/rcbridge.html'
      : '/dist/dpx-portal/static/rcbridge.html';
  return {
    labels: {
      equipment: 'labels.patientRecord.tabs.equipments.title',
      serviceRequests:
        'labels.patientRecord.tabs.serviceRequests.title',
      remoteCheck: 'labels.patientRecord.tabs.remoteCheck.title',
      loadingEquipment:
        'labels.patientRecord.tabs.equipments.loading',
      addDevice: 'labels.patientRecord.tabs.equipments.addDevice',
      emptyEquipment: 'labels.patientRecord.tabs.equipments.empty',
      loading:
        'labels.patientRecord.tabs.serviceRequests.view.loading',
      serviceRequestsNotApplicable:
        'labels.patientRecord.tabs.serviceRequests.view.notApplicable',
    },
    errorConfig: {
      buttons: [],
      imgAlt: 'labels.errorMessage.imgAlt',
      imgSrc:
        'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
      imgWidth: 116,
      layout: 'horizontal',
      text: 'labels.error.text',
      title: 'labels.error.title',
    },
    notFoundErrorConfig: {
      buttons: [],
      imgAlt: 'labels.errorMessage.imgAlt',
      imgSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/66c25eb795cd471a8e7e1dc06c32408a?v=1ccadacd',
      imgWidth: 116,
      layout: 'horizontal',
      text: 'labels.error.checkUrl',
      title: 'labels.error.404',
      code: 404,
    },
    serviceRequestConfig: {
      enabled: featureFlags.serviceRequest,
      serviceRequestUrl: setIdpParam(
        `${routes.mcp.servieRequest}${selectedRecipientCId}`,
      ),
      notApplicable: false,
      hasError: false,
      isLoading: true,
      errorConfig: {
        buttons: [],
        imgAlt: 'labels.errorMessage.imgAlt',
        imgSrc:
          'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/9bfd65ed69994fa49be3ff1339ea9674?v=cc7c6ec3',
        imgWidth: 116,
        layout: 'horizontal',
        text: 'labels.patientRecord.tabs.serviceRequests.error.text',
        title:
          'labels.patientRecord.tabs.serviceRequests.error.title',
      },
    },
    remoteCheckConfig: {
      enabled: featureFlags.remoteCheck,
      remoteCheckUrl: rcBridgeUrl,
      id: 'rcbridge',
    },

    tableConfig,
    toolbarConfig,
    taskBarConfig,
    emptyEquipment,
  };
};

export const getPatientDeceasedConfig = (
  headingDetails,
  patientTitle,
  labels,
  redirectToContactUs,
) => {
  return {
    ...headingDetails,
    title: {
      ...headingDetails.title,
      content: patientTitle,
    },
    buttonConfig: null,
    alertConfig: {
      ...headingDetails.alertConfig,
      visible: true,
      dismissible: false,
      type: 'info',
      title: `${labels['labels.patientRecord.heading.alert.title.deceased']}`,
      message: `${labels['labels.patientRecord.heading.alert.message.deceased']}`,
      actions: [
        {
          text: `${labels['labels.patientRecord.heading.alert.button.deceased']}`,
          onClick: redirectToContactUs,
        },
      ],
    },
    errorConfig: {
      isDeceased: true,
      errorView: {
        layout: 'horizontal',
        imgAlt: 'labels.patientRecord.deceased.errorMessage.imgAlt',
        imgSrc:
          'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/CDS_Pictogram_Password?v=e6c2d773',
        imgWidth: '116',
        title: 'labels.patientRecord.deceased.errorMessage.title',
        text: 'labels.patientRecord.deceased.errorMessage.text',
        buttons: [
          {
            name: 'dpxContactUs',
            variant: 'brand-primary',
            text: `${labels['labels.patientRecord.deceased.errorMessage.button']}`,
            icon: '',
            link: '',
            action: 'link-dpx-login',
            useCallback: true,
          },
        ],
        handleCallback: () => redirectToContactUs(),
      },
      labels,
    },
  };
};
