import {
  isValidDate,
  isValidPastDate as isValidPastDateHelper,
  formatDate,
} from './dateTimeFns';
import { defaultBrowserLanguage } from '../config';
import {
  supportedLanguagesInSalesforce,
  getLanguageList,
} from '../data/langaugeList';

export const isValidPastDate = ({ year, month, day }) => {
  return isValidPastDateHelper(day, month, year);
};

export const isValidPastDateCarer = ({
  yearCarer,
  monthCarer,
  dayCarer,
}) => {
  if (!yearCarer && !monthCarer && !dayCarer) {
    return true;
  }
  return isValidPastDateHelper(dayCarer, monthCarer, yearCarer);
};

export const getBaseDateFieldsConfig = () => {
  return {
    d: {
      maxLength: 2,
      name: 'day',
      size: 2,
    },
    m: {
      maxLength: 2,
      name: 'month',
      size: 2,
    },
    y: {
      minLength: 4,
      maxLength: 4,
      name: 'year',
      size: 4,
    },
  };
};

export const getBaseDateFieldsConfigCarer = () => {
  return {
    d: {
      maxLength: 2,
      name: 'dayCarer',
      size: 2,
    },
    m: {
      maxLength: 2,
      name: 'monthCarer',
      size: 2,
    },
    y: {
      minLength: 4,
      maxLength: 4,
      name: 'yearCarer',
      size: 4,
    },
  };
};

export const getPreferredLanguage = () => {
  let currentLanguage =
    navigator?.language ||
    localStorage.getItem('browserLang') ||
    defaultBrowserLanguage;

  const isLanguageSupported = (lang) =>
    supportedLanguagesInSalesforce.some(
      (supportedLang) => supportedLang.value === lang,
    ) &&
    getLanguageList().some(
      (supportedLang) => supportedLang.value === lang,
    );

  // Check if the full language code is supported
  if (!isLanguageSupported(currentLanguage)) {
    // Slice the first two letters and check again
    currentLanguage = currentLanguage.slice(0, 2);
    if (!isLanguageSupported(currentLanguage)) {
      // Return the default language if still not available
      currentLanguage = defaultBrowserLanguage;
    }
  }

  return currentLanguage;
};

export const getDefaultLanguage = { value: 'en', label: 'English' };

export const checkMinor = (dob, ageOfMaturity) => {
  if (!isValidDate(dob)) return false;

  const today = new Date();
  const birthDate = new Date(dob);

  if (birthDate > today) {
    return false;
  }
  birthDate.setFullYear(birthDate.getFullYear() + ageOfMaturity);
  if (birthDate <= today) {
    return false;
  }
  return true;
};

export const getAgeOfMaturity = () => {
  const countryConfigStr = localStorage.getItem('countryConfig');
  let ageOfMaturity = 18;
  if (countryConfigStr) {
    const countryConfig = JSON.parse(countryConfigStr);
    if (
      countryConfig &&
      typeof countryConfig === 'object' &&
      'ageOfMaturity' in countryConfig
    ) {
      ageOfMaturity = countryConfig.ageOfMaturity;
    }
  }
  return ageOfMaturity;
};

export const buildMatchingRecordSet = (records) => {
  return records.map((patientRecord) => {
    return {
      name2: {
        item: `${patientRecord.firstName} ${patientRecord.lastName}`,
        id: patientRecord.cochlearId,
      },
      name: `${patientRecord.firstName} ${patientRecord.lastName}`,
      dob: patientRecord.dateOfBirth,
      dobFormatted: formatDate(patientRecord.dateOfBirth),
      email: patientRecord.email,
      cochlearId: patientRecord.cochlearId,
      isRelatedToProvider: patientRecord.isRelatedToProvider,
      firstName: patientRecord.firstName,
      lastName: patientRecord.lastName,
      source: patientRecord.source,
      persona: patientRecord.persona,
    };
  });
};

export const validatePhoneNumber = ({
  callingCode,
  number,
  country,
}) => {
  const usRegex = /^\+?[1]\d{7, 12}$/;
  const intlRegex = /^\+?[2-9]\d{7, 15}$/;
  const fullNumber = `${+callingCode}${+number}`;
  return country === 'US'
    ? usRegex.test(fullNumber)
    : intlRegex.test(fullNumber);
};

export const longLangaugeCodeRegex = /^[a-z]{2}-[A-Z]{2}$/;
