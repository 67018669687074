import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  SelectInput,
  Spinner,
  Text,
  Alert,
} from '@cochlear-design-system/foundation';
import { DataTable } from '@cochlear-design-system/features.dataTable';
import { Error } from '../../ui/Error/Error';
import { routes } from '../../config';
import { dataTableConfig } from './accessoryRegistrationConfig';

import providerService from '../../services/providerService';
import userService from '../../services/userService';
import { fetchMatchingAccessories } from '../../services/deviceRegistration/fetchAccessoryService';

const AccessoryRegistration = ({
  labels,
  tableDetails,
  error,
  errorApi,
  noExistingDevices,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useState(location)[0];
  const { hasExistingDevice } = state;

  const accessories = JSON.parse(
    localStorage.getItem('accessoryItems'),
  );

  const families = Object.keys(accessories).map((family) => {
    return {
      label: family,
      value: family,
    };
  });

  const [familyModels, setFamilyModels] = useState([]);
  const [modelAccessories, setModelAccessories] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedAccessory, setSelectedAccessory] = useState(null);
  const [matchingAccessories, setMatchingAccessories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [apiError, setApiError] = useState(false);

  const currentProvider = providerService.getCurrentProvider();

  const currentUser = userService.getUserDetails();

  const { countryCode } = currentProvider.address || 'US';

  const redirectToAccessoryReview = (data) => {
    if (data) {
      const lang = currentUser.languageCode.split('_')[0];
      history.replace({
        pathname: `/${countryCode}/${lang}/${routes.dpx.accessoryRegistrationReview}`,
        state: {
          patientDetails: { ...state },
          selectedDevice: { ...data },
          providerDetails: { ...currentProvider },
          userDetails: { ...currentUser, lang },
        },
      });
    }
  };

  const accessorySearch = async (value) => {
    if (!hasExistingDevice) {
      return;
    }
    setIsLoading(true);
    const { apiResponse, accessoryList } =
      await fetchMatchingAccessories(countryCode, {
        productFamily: selectedFamily,
        productModel: selectedModel,
        productType: value,
      });
    setIsLoading(false);
    setSearchComplete(true);
    if (apiResponse !== '200') {
      setApiError(true);
      setMatchingAccessories([]);
      return;
    }
    setMatchingAccessories(accessoryList);
  };

  const resetSelect = (models = false) => {
    setSearchComplete(false);
    setApiError(false);
    if (models) {
      setSelectedModel(null);
    }
    setSelectedAccessory(null);
    setMatchingAccessories([]);
  };

  const selectFamily = (value) => {
    setSelectedFamily(value);
    resetSelect(true);
    setFamilyModels(
      accessories[value]
        ? Object.keys(accessories[value]).map((model) => {
            return {
              label: model,
              value: model,
            };
          })
        : [],
    );
  };

  const selectModel = (value) => {
    resetSelect(false);
    setSelectedModel(value);
    if (selectedFamily && value) {
      setModelAccessories(
        accessories[selectedFamily][value].map((accessory) => {
          return {
            label: accessory,
            value: accessory,
          };
        }),
      );
    }
  };

  const selectAccessory = async (value) => {
    resetSelect(false);
    setSelectedAccessory(value);
    accessorySearch(value);
  };

  return (
    <div
      className="accessory-registraton"
      style={{ padding: '50px 25px' }}
    >
      {hasExistingDevice && (
        <Alert show variant="brand">
          {labels['labels.deviceRegistration.tabs.accessory.content']}
        </Alert>
      )}
      {!hasExistingDevice && (
        <Alert show>
          {
            labels[
              'labels.deviceRegistration.tabs.device.alert.noExistingDevices'
            ]
          }
        </Alert>
      )}
      <SelectInput
        className="accessory-select"
        id="product_family"
        label={
          labels['labels.accessoryRegistration.productSelect.label']
        }
        defaultValue={selectedFamily}
        promptText={
          labels['labels.accessoryRegistration.productSelect.prompt']
        }
        dataList={families}
        onChange={(name, value) => {
          selectFamily(value);
        }}
        disabled={!hasExistingDevice}
      />
      <SelectInput
        className="accessory-select"
        id="product_model"
        label={
          labels['labels.accessoryRegistration.modelSelect.label']
        }
        defaultValue={selectedModel}
        promptText={
          labels['labels.accessoryRegistration.modelSelect.prompt']
        }
        dataList={familyModels}
        onChange={(name, value) => {
          selectModel(value);
        }}
        disabled={!selectedFamily}
      />
      {selectedModel && (
        <SelectInput
          className="accessory-select"
          id="product_accessory"
          label={
            labels[
              'labels.accessoryRegistration.accessorySelect.label'
            ]
          }
          defaultValue={selectedAccessory}
          promptText={
            labels[
              'labels.accessoryRegistration.accessorySelect.prompt'
            ]
          }
          dataList={modelAccessories}
          onChange={(name, value) => {
            selectAccessory(value);
          }}
          disabled={!selectedModel}
        />
      )}
      <div>
        {!hasExistingDevice && selectedAccessory && (
          <div className="ccl__data-table--device-registration ccl__data-table--dpx">
            <Error
              config={noExistingDevices.config}
              labels={error.labels}
              data={error.data}
            />
          </div>
        )}
        {isLoading && (
          <div className="ccl__spinner">
            <Spinner animation="border" />
            <Text
              content={
                labels['labels.deviceRegistration.loadingAccessories']
              }
            />
          </div>
        )}
        {searchComplete === true &&
          matchingAccessories.length === 0 &&
          apiError === true && (
            <div className="ccl__data-table--device-registration ccl__data-table--dpx api-error">
              <Error
                config={errorApi.config}
                labels={errorApi.labels}
                data={errorApi.data}
              />
            </div>
          )}
        {searchComplete === true &&
          apiError === false &&
          matchingAccessories.length === 0 && (
            <div className="ccl__data-table--device-registration ccl__data-table--dpx">
              <Error
                config={error.config}
                labels={error.labels}
                data={error.data}
              />
            </div>
          )}
        {searchComplete && matchingAccessories.length > 0 && (
          <DataTable
            config={dataTableConfig(redirectToAccessoryReview)}
            data={matchingAccessories}
            labels={tableDetails.labels}
          />
        )}
      </div>
    </div>
  );
};

export default AccessoryRegistration;
