import React from 'react';
import { Button } from '@cochlear-design-system/foundation';

export const getCustomerSupportMessage = (message, messageLink) => {
  const parts = message?.split(/<([^>]+)>/);
  return (
    <span>
      {parts?.map((part, index) => {
        if (index % 2 === 1) {
          return (
            <Button
              link="/"
              size="medium"
              text={part}
              variant="tertiary"
              className="notification-link"
              onClick={(event) => {
                event.preventDefault();
                window.open(messageLink, '_blank');
              }}
            />
          );
        }
        return part;
      })}
    </span>
  );
};
