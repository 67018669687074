import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userService from '../../services/userService';
import providerService from '../../services/providerService';
import { routes, orderFormsURLs } from '../../config.js';
import { cochlearIdToUuid } from '../../utils/mcpcid';
import { replaceTokens } from '../../utils/stringFns';
import featureFlagService from '../../services/featureFlagService';
import headerCancelModal from '../../actions/headerCancelModalAction';
import { getHeaderConfig } from './headerNewConfig';

export const dataMapper = (
  fields,
  callback,
  lang,
  country,
  countryConfig,
  onClinicSelected,
  labels,
  providerCountryCode,
  providerRegion,
) => {
  const headerConfig = getHeaderConfig(
    providerCountryCode,
    providerRegion,
    labels,
  );

  const featureFlags = featureFlagService.getFeatureFlags();
  const pDetails = providerService.getProviderDetails();
  const uDetails = userService.getUserDetails();
  const currentPage = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hideNavOnChange, setHideNavOnChange] = useState(false);
  const initials =
    uDetails.firstName && uDetails.lastName
      ? `${uDetails.firstName[0]}${uDetails.lastName[0]}`
      : '';

  const fullName =
    uDetails.firstName && uDetails.lastName
      ? `${uDetails.firstName} ${uDetails.lastName}`
      : '';

  let selected = '';
  const menuIDprefix = 'menu-';

  const hideHeaderMenus =
    currentPage.pathname.match(
      /(create-patient|device-registration|onboarding-qr-code|patients\/edit|add-carer|accessory-registration-review)/,
    ) !== null;
  const isCreatePatientRecipient =
    currentPage.pathname.match(/(create-patient-recipient)/) !== null;
  const isCreatePatientCarer =
    currentPage.pathname.match(/(create-patient-carer)/) !== null;
  const isOnboardingQrCode =
    currentPage.pathname.match(/(onboarding-qr-code)/) !== null;
  // TODO: make page specific
  const analytics = 'cancel_header';

  // detect selected menu
  headerConfig.topNavMenus.forEach((menu) => {
    // Resources and Training has whitespaces for quicker menu integration with Remote Check
    const strippedMenu =
      menu.name === 'Resources and Training'
        ? menu.name
        : menu.name.replace(/\s+/g, '');
    menu.items.forEach((item) => {
      if (
        item.type !== 'external' &&
        item.url &&
        currentPage.pathname.endsWith(item.url)
      )
        selected = `${menuIDprefix}${strippedMenu}`;
    });
  });

  const getRemoteCheckUrl = () => {
    return `${routes.clinicianPortal.remoteCheck}${cochlearIdToUuid(
      pDetails.providerId,
    )}`;
  };

  const getOrderFormsUrl = () => {
    return orderFormsURLs[providerCountryCode];
  };

  const arrTokens = [
    {
      key: '{{##___REMOTE_CHECK_URL___##}}',
      value: getRemoteCheckUrl(),
    },
    {
      key: '{{##___ORDER_FORMS_URL___##}}',
      value: getOrderFormsUrl(),
    },
  ];

  const menuRules = [
    {
      menuitem: 'Dashboard',
      enabled: featureFlags.dashboardMenu,
    },
    {
      menuitem: 'home',
      enabled: featureFlags.dashboardMenu,
    },
    {
      menuitem: 'Create patient',
      enabled: featureFlags.patientsMenu,
    },
    {
      menuitem: 'create-patient',
      enabled: featureFlags.createPatient,
    },
    { menuitem: 'Clinic admin', enabled: featureFlags.clinicAdmin },
    { menuitem: 'clinic-admin', enabled: featureFlags.clinicAdmin },
    {
      menuitem: 'clinician-management',
      enabled: featureFlags.cliniciansSubMenu,
    },
    {
      menuitem: 'clinic-equipment',
      enabled: true,
    },
    {
      menuitem: 'clinic-contacts',
      enabled: featureFlags.clinicContactsSubMenu,
    },
    { menuitem: 'Remote Check', enabled: featureFlags.remoteCheck },
    { menuitem: 'remote-check', enabled: featureFlags.remoteCheck },
    {
      menuitem: 'Orders',
      enabled: ['AU', 'NZ', 'US'].includes(providerCountryCode),
    },
    {
      menuitem: 'order-forms',
      enabled: ['AU', 'NZ', 'US'].includes(providerCountryCode),
    },
    {
      menuitem: 'order-forms-link',
      enabled: ['AU', 'NZ', 'US'].includes(providerCountryCode),
    },
    {
      menuitem: 'Resources and Training',
      enabled: true,
    },
    {
      menuitem: 'resources-training',
      enabled: true,
    },
    {
      menuitem: 'cochlear-learning-centre',
      enabled: providerRegion === 'CAM',
    },
    {
      menuitem: 'csp-support-toolkit',
      enabled: true,
    },
    {
      menuitem: 'csp-virtual-software',
      enabled: true,
    },
    {
      menuitem: 'resources',
      enabled: providerRegion !== 'EMEA' && providerRegion !== 'APAC',
    },
    {
      menuitem: 'cpn-resources',
      enabled: providerRegion === 'CAM',
    },
    { menuitem: 'Help centre', enabled: false },
    { menuitem: 'help-centre', enabled: false },
    {
      menuitem: 'contact-us',
      enabled: true,
    },
    {
      menuitem: 'return-to-legacy-portal',
      enabled: true,
    },
  ];

  const isMenuFeatureEnabled = (menu) => {
    const menuInList = menuRules.filter(
      (item) => menu === item.menuitem && item.enabled,
    )[0];
    return menuInList; // Return null (false) if menu is not in the mapping list or feature is disabled
  };

  const getCloseLinkLabel = () => {
    if (currentPage.pathname.match(/(patients\/edit)/) !== null) {
      return labels.closeLink;
    }
    return labels.cancelLink;
  };

  const handleCloseLinkClick = () => {
    if (
      isCreatePatientRecipient ||
      isCreatePatientCarer ||
      isOnboardingQrCode
    ) {
      history.push(
        `/${country}/${lang}/${routes.dpx.createPatientLanding}`,
      );
    } else {
      dispatch(headerCancelModal(true));
    }
  };

  return {
    data: {
      button: {
        initials,
      },
      menuHeader: {
        content1: fullName,
      },
      clinicSelector: {
        items: pDetails.providerList.providers.map((provider) => ({
          id: provider.id,
          content: provider.name,
          analytics: 'select_provider_header',
        })),
        selected:
          pDetails.providerList.providers.filter(
            (providerItem) => providerItem.id === pDetails.providerId,
          )[0]?.id || pDetails.providerList.providers?.[0]?.id,
      },
    },
    config: {
      showLogo: true,
      logoSrc:
        'https://mss-p-007-delivery.stylelabs.cloud/api/public/content/d7d76035265f4eb6b4817ca927285c5d?v=3ef6f186',
      logoAlt: labels.logoAlt,
      showSeparator: true,
      showAppName: true,
      showClinicSelector:
        !hideHeaderMenus &&
        pDetails.providerList.providers.length > 0,
      AppName: 'appName',
      showMyProfile: !hideHeaderMenus,
      closeLink: getCloseLinkLabel(),
      cancelLink: labels.cancelLink,
      closeLinkClick: handleCloseLinkClick,
      showCloseLink: hideHeaderMenus,
      showCancelLink: hideHeaderMenus,
      hideTopNav: hideHeaderMenus || hideNavOnChange,
      analytics,
      clinicSelector: {
        onSelectItem: async function onSelectItem(item) {
          setHideNavOnChange(true);
          onClinicSelected(item, uDetails?.professionalId);
        },
      },
      topNavMenus: {
        selected,
        menus: headerConfig.topNavMenus
          .filter((topNavMenu) =>
            isMenuFeatureEnabled(topNavMenu.name),
          )
          .map((topNavSubMenu) => {
            // don't strip whitespace for Resources and Training for quicker Remote Check menu integration
            const strippedMenu =
              topNavSubMenu.name === 'Resources and Training'
                ? topNavSubMenu.name
                : topNavSubMenu.name.replace(/\s+/g, '');
            const menuId = `${menuIDprefix}${strippedMenu}`;
            return {
              id: menuId,
              onChange: (selItem) => callback(selItem),
              items: topNavSubMenu.items
                .filter((subMenu) =>
                  isMenuFeatureEnabled(subMenu.name),
                )
                .map((item) => ({
                  type:
                    item.type === 'external'
                      ? 'External'
                      : 'SimpleText',
                  text: labels[item.labelKey],
                  id: `${menuId}_${item.name}`,
                  url: item.url.startsWith('{{##')
                    ? replaceTokens(item.url, arrTokens)
                    : item.url,
                })),
            };
          }),
      },
      profileMenu: {
        showHeader: true,
        header: {
          showPictogram: false,
          pictogramSrc:
            'https://sit01-cochlear.cs102.force.com/Professional/resource/1641854542000/DPX_Portal_Static_Resources/src/assets/images/EmailSent.svg?orgId=00D1j0000008arI',
          pictogramAlt: labels.pictogramAlt,
        },
        links: headerConfig.links.targetItem.children.map((link) => ({
          path:
            link.type.linkType === 'external'
              ? link.type.url
              : `/${country}/${lang}/${link.url.value}`,
          text: labels[link.name],
          icon: link.icon.value,
        })),
        showWhatsNew: false,
        whatsNew: {
          text: labels.whatsNew,
          onClick: function onClick() {},
        },
      },
    },
    caseTypes: headerConfig.caseTypes,
    channel: headerConfig.channel,
  };
};
